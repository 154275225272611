/**
 * Video player states.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Components.VideoPlayer
 */
export const STATE = Object.freeze({
    PLAYING: 'PLAYING',
    STOPPED: 'STOPPED',
});
