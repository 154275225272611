import PropTypes from 'prop-types';
import SlidingPaneImpl from './sliding-pane.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A pane which appears from either side of the screen.
 * If the pane slides in from left or right, it will cover the whole height of the screen.
 * If the pane slides in from top or bottom, it will cover the whole width of the screen.
 *
 * @class SlidingPane
 * @name component
 * @memberof Mojito.Presentation.Components.SlidingPane
 */

/**
 * SlidingPane prop types.
 *
 * @property {node} [children] - The content that should be displayed in the pane.
 * @property {boolean} [isOpen = false] - If the pane is open, ie slided into view, or not.
 * @property {Function} [onClickOutside = ()=>{}] - Callback to invoke when clicking outside the pane.
 * @property {Function} [onCloseClick = ()=>{}] - Callback to invoke when clicking on close button.
 * @property {Function} [onClosed = ()=>{}] - Callback to invoke when sliding pane has been closed.
 * @property {Function} [onOpened = ()=>{}] - Callback to invoke when sliding pane has been opened.
 *
 * @memberof Mojito.Presentation.Components.SlidingPane.component
 */
const propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onClickOutside: PropTypes.func,
    onCloseClick: PropTypes.func,
    onClosed: PropTypes.func,
    onOpened: PropTypes.func,
};

const defaultProps = {
    isOpen: false,
    onClickOutside: noop,
    onCloseClick: noop,
    onClosed: noop,
    onOpened: noop,
};

export default UIView('SlidingPane', SlidingPaneImpl, propTypes, defaultProps);
