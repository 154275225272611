import PropTypes from 'prop-types';
import LegInfoRowImpl from './leg-info-row.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View displaying a leg information.
 *
 * @class LegInfoRow
 * @memberof Mojito.Presentation.Components
 */

/**
 * LegInfoRow prop types.
 *
 * @property {object} [eventItem] - The event item.
 * @property {object} leg - The leg.
 * @property {boolean} isCashedOut - Boolean indicating the bet has been cashed out.
 *
 * @memberof Mojito.Presentation.Components.LegInfoRow
 */
const propTypes = {
    eventItem: PropTypes.object,
    leg: PropTypes.object.isRequired,
    isCashedOut: PropTypes.bool.isRequired,
};

export default UIView('LegInfoRow', LegInfoRowImpl, propTypes);
