import MojitoServices from 'mojito/services';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EventCardsCarouselUtils from 'modules/event-cards-carousel/utils.js';
import { without, pickBy } from 'mojito/utils';

const { convertToCard } = EventCardsCarouselUtils;
const { makeSelectEventsState } = MojitoServices.SportsContent.Events.selectors;
const { types: ServicesTypes } = MojitoServices.Common;
const { UNAVAILABLE } = ServicesTypes.CONTENT_STATE;
const byDisplayOrder = (c1, c2) => c1.displayOrder - c2.displayOrder;

/**
 * Contains custom hooks for event card carousel functionality.
 *
 * @class EventCardsCarouselHooks
 * @name hooks
 * @memberof Mojito.Modules.EventCardsCarousel
 */

/**
 * Converts promotions to cards, sort by display order and filter out cards which have events with an UNAVAILABLE state.
 *
 * @function useCardsPromotions
 *
 * @param {Array<Mojito.Services.Promotions.types.Promotion>} promotions - Promotions list.
 *
 * @returns {Array<object>} List of cards.
 * @memberof Mojito.Modules.EventCardsCarousel.hooks
 */
export const useCardsPromotions = promotions => {
    const cards = useMemo(
        () => (promotions ? promotions.map(convertToCard).sort(byDisplayOrder) : []),
        [promotions]
    );
    const eventIds = useMemo(
        () => cards.map(card => card.content.eventId).filter(Boolean),
        [cards]
    );

    const selectEventsState = useMemo(makeSelectEventsState, []);
    const eventsState = useSelector(state => selectEventsState(eventIds, state));

    // Sometimes promotions can still have event card for outdated event. It will happen if event is over but CmsJob hasn't rerun yet.
    // Here we will detect such event cards to ignore them while rendering.
    return useMemo(() => {
        const unavailableEventIds = Object.keys(
            pickBy(eventsState, eventState => eventState === UNAVAILABLE)
        );
        const unavailableCards = cards.filter(card =>
            unavailableEventIds.includes(card.content.eventId)
        );
        return without(cards, ...unavailableCards);
    }, [eventsState, cards]);
};
