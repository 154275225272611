import ExecutionMode from 'core/base/execution-mode';
import { isEmpty, isNil, memoize, merge } from 'mojito/utils';
import MojitoNGen from 'mojito/ngen';
import { configRegistry } from './config-registry.js';
import CacheKeyGenerator from 'core/base/cache/cache-key-generator.js';
import configFactoryDefaultConfig from 'core/generated/configs/_config-factory.js';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const EMPTY_CONTEXT_CHAIN = [''];
const CONTEXT_PREFIX = '_';
const IMPL_META = { isImplConfig: true };
const CLASS_NAME = 'ConfigFactory';
const log = MojitoNGen.logger.get(CLASS_NAME);

export const MIXIN = '$mixin';
export const META = '$meta';
export const RESERVED_PROPS = [MIXIN, META];

/**
 * Config object ready to be used inside mojito component.
 * This object is produced by {@link Mojito.Core.Services.Config.ConfigFactory#getConfig|getConfig} function.
 *
 * @typedef ConfigObject
 *
 * @memberof Mojito.Core.Services.Config
 */

/**
 * Config factory constructor.
 *
 * @class ConfigFactory
 * @param {Mojito.Core.Services.Config.ConfigRegistry} configRegistry - Config registry instance.
 * @classdesc A factory for creating configuration objects.
 * @memberof Mojito.Core.Services.Config
 */
export default class ConfigFactory {
    constructor(configRegistry) {
        this.configs = {};
        this.configRegistry = configRegistry;
        this.cacheKeyGenerator = new CacheKeyGenerator();
        this.resolveContextVariations = memoize(this.resolveContextVariations);
        this.getContextualPrimitives = memoize(this.getContextualPrimitives);
        this.configRegistry.add(configFactoryDefaultConfig);
    }

    /**
     * Prepares a configuration object for a specific context.
     * Calculation of a config object for a context is relatively quick, and config objects are cached for efficiency.
     *
     * @param {string} name - Config name.
     * @param {string} context - The context for which to generate the configuration object, in the format "_mobile_inplay".
     * @param {object} override - Config override object, typically, arrives from component's parent.
     *
     * @returns {object} Config object for the context ready to be used in a view.
     *
     * @function Mojito.Core.Services.Config.ConfigFactory#getConfig
     */
    getConfig(name, context, override) {
        const parentOverrides = override ? [override] : undefined;
        return this.resolveConfig(name, context, parentOverrides);
    }

    /**
     * Get custom implementation config for component.
     *
     * @param {string} name - Component name.
     * @param {string} context - The context for which to generate the configuration object, in the format "_mobile_inplay".
     *
     * @returns {object|undefined} Config object for the context.
     *
     * @function Mojito.Core.Services.Config.ConfigFactory#getImplementationConfig
     */
    getImplementationConfig(name, context) {
        const implConfig = this.configRegistry.getImplementationConfig(name);
        if (!implConfig) {
            return;
        }
        const executionContext = this.ensureExecutionContext(undefined, name, IMPL_META);
        return this.traverseConfig(implConfig, context, EMPTY_ARRAY, executionContext);
    }

    resolveConfig(name, context, parentOverrides, executionContext) {
        const configTemplate = this.configRegistry.getConfigDefaults(name);
        const customOverride = this.configRegistry.getConfigOverrides(name);
        const meta = this.configRegistry.getConfigMeta(name);
        const overridesChain = this.resolveOverrides(parentOverrides, customOverride);
        executionContext = this.ensureExecutionContext(executionContext, name, meta);
        const cachePath = context ? `${name}${context}` : name;
        const cacheKey = this.cacheKeyGenerator.generate(cachePath, overridesChain);
        const cachedResult = this.configRegistry.fromCache(name, cacheKey);
        if (cachedResult) {
            return cachedResult;
        }
        const result = this.traverseConfig(
            configTemplate,
            context,
            overridesChain,
            executionContext
        );
        this.defineConfigMeta(result, name, cacheKey);
        this.configRegistry.toCache(name, cacheKey, result);
        return result;
    }

    traverseConfig(config = {}, context, overridesChain, executionContext) {
        const self = this;
        const mixins = config[MIXIN];
        const result = mixins ? this.processMixins(mixins, context, overridesChain) : {};

        const props = Object.keys(config);
        for (let i = 0; i < props.length; i++) {
            let prop = props[i];
            if (this.isConfigNode(config[prop])) {
                const privateKey = `_${prop}`;
                Object.defineProperty(result, privateKey, { writable: true });
                Object.defineProperty(result, prop, {
                    enumerable: true,
                    get: function () {
                        if (this[privateKey]) {
                            return this[privateKey];
                        }
                        if (isVoidedProp(prop, overridesChain)) {
                            return (this[privateKey] = undefined);
                        }
                        const nestedOverrides = overridesChain.map(override => override?.[prop]);
                        const nestedExecutionContext = self.concatPath(executionContext, prop);
                        const { configSet, configValues } = config[prop];
                        if (configSet) {
                            self.ensureTemplateInRegistry(configSet);
                            const componentOverrides = !isEmpty(configValues)
                                ? [...nestedOverrides, configValues]
                                : nestedOverrides;
                            this[privateKey] = self.resolveConfig(
                                configSet.name,
                                context,
                                componentOverrides,
                                nestedExecutionContext
                            );
                        } else {
                            this[privateKey] = self.traverseConfig(
                                config[prop],
                                context,
                                nestedOverrides,
                                nestedExecutionContext
                            );
                        }
                        return this[privateKey];
                    },
                    set: function (value) {
                        this[privateKey] = value;
                    },
                });
            } else if (!isReservedProp(prop)) {
                prop = isContextualProp(prop) ? this.escapeContextPrefix(prop) : prop;
                result[prop] = this.resolvePropValue(config, prop, context, overridesChain);
            }
        }

        this.processExtensions(result, context, overridesChain, executionContext);
        this.processUnknownProps(result, overridesChain, executionContext);
        return result;
    }

    processMixins(mixins, context, overridesChain) {
        let result = {};
        for (let i = 0; i < mixins.length; i++) {
            const mixinNode = mixins[i].configSet;
            this.ensureTemplateInRegistry(mixinNode);
            const mixin = this.expandMixin(mixinNode.name);
            const nestedMixins = mixin[MIXIN];
            const nestedMixinProps = nestedMixins
                ? this.processMixins(nestedMixins, context, overridesChain)
                : undefined;
            result = nestedMixinProps ? { ...result, ...nestedMixinProps } : result;
            for (const prop in mixin) {
                if (!isReservedProp(prop) && !this.isConfigNode(mixin[prop])) {
                    const value = this.resolvePropValue(mixin, prop, context, overridesChain);
                    // Do not populate styles with unset values in release mode
                    if (value !== undefined || isDebugMode()) {
                        result[prop] = value;
                    }
                } else if (!isReservedProp(prop)) {
                    log.error(
                        `Mixin ${mixinNode.name} contains nested object ${JSON.stringify(mixin[prop], 0, 2)}. Mixins only support primitive props.`
                    );
                }
            }
            this.processExtensions(result, context, overridesChain, mixinNode);
        }
        return result;
    }

    expandMixin(mixinName) {
        const cachedResult = this.configRegistry.fromCache(mixinName, MIXIN);
        if (cachedResult) {
            return cachedResult;
        }
        const result = {};
        const mixinTemplate = this.configRegistry.getConfigDefaults(mixinName);
        const mixinOverride = this.configRegistry.getConfigOverrides(mixinName);
        // eslint-disable-next-line guard-for-in
        for (const prop in mixinTemplate) {
            result[prop] = mixinOverride?.hasOwnProperty(prop)
                ? mixinOverride[prop]
                : mixinTemplate[prop];
        }
        this.configRegistry.toCache(mixinName, MIXIN, result);
        return result;
    }

    resolveUnknownProps(config, overridesChain) {
        if (!overridesChain?.length) {
            return EMPTY_ARRAY;
        }
        const unhandledProps = [];
        overridesChain.filter(Boolean).forEach(override => {
            const unhandledOverrideProps = Object.keys(override).filter(key => {
                if (isReservedProp(key)) {
                    return false;
                }
                const pureProp = this.escapeContextPrefix(key);
                return !Object.hasOwn(config, pureProp);
            });
            unhandledProps.push(...unhandledOverrideProps);
        });
        return unhandledProps;
    }

    ensureTemplateInRegistry(node) {
        if (!this.configRegistry.hasConfigDefaults(node.name)) {
            this.configRegistry.add(node);
        }
    }

    processExtensions(config, context, overridesChain, executionContext) {
        const settings = this.getSettings();
        if (!executionContext?.meta?.extendable && settings?.strict) {
            return;
        }
        const overrides = overridesChain.filter(Boolean).reverse();
        const extension = merge({}, ...overrides);
        Object.keys(extension).forEach(prop => {
            if (!isReservedProp(prop) && !Object.hasOwn(config, prop)) {
                prop = isContextualProp(prop) ? this.escapeContextPrefix(prop) : prop;
                config[prop] = this.resolvePropValue(extension, prop, context);
            }
        });
    }

    processUnknownProps(config, overridesChain, executionContext) {
        const { rootType, path, meta } = executionContext || EMPTY_OBJECT;
        const { extendable, isImplConfig } = meta || EMPTY_OBJECT;
        if (isDebugMode() && !extendable && !isImplConfig) {
            const unknownProps = this.resolveUnknownProps(config, overridesChain);
            if (unknownProps.length) {
                const nestedPathLog = path ? ` under nested path: ${path}` : '';
                const lastPathSegment = this.getLastPathSegment(path) || rootType;
                log.error(
                    `${rootType} component config has unsupported properties: [ ${unknownProps.join(', ')} ]${nestedPathLog} \nApplied config values: ${this.prepareToPrint(config, lastPathSegment)}`
                );
            }
        }
    }

    getContextualPrimitives(obj) {
        const result = {};
        for (const prop in obj) {
            if (isContextualProp(prop) && !isGetter(prop, obj)) {
                result[prop] = obj[prop];
            }
        }
        return result;
    }

    resolvePropValue(obj, prop, context, overridesChain) {
        const contextChain = this.resolveContextVariations(context);
        for (let i = 0; i < contextChain.length; i++) {
            const contextPart = contextChain[i];
            const propName = contextPart ? `${contextPart}_${prop}` : prop;
            const contextualSelfPrimitives = contextPart
                ? this.getContextualPrimitives(obj)
                : undefined;
            const overrides = this.resolveOverrides(overridesChain, contextualSelfPrimitives);
            const overrideDescriptor = this.resolveOverriddenProp(overrides, propName);
            if (overrideDescriptor?.hasOwnProperty('value')) {
                return overrideDescriptor.value;
            }
        }
        return obj[prop];
    }

    prepareToPrint(config, type) {
        const objToPrint = {};
        Object.keys(config).forEach(prop => {
            objToPrint[prop] = this.isConfigNode(config[prop]) ? '{...}' : config[prop];
        });
        return JSON.stringify({ [type]: objToPrint }, 0, 2);
    }

    getLastPathSegment(path) {
        return path?.split('.').pop();
    }

    escapeContextPrefix(prop) {
        return prop.replace(/_\w+_/, '');
    }

    isConfigNode(node) {
        return node !== null && typeof node === 'object' && !Array.isArray(node);
    }

    resolveContextVariations(contextPath) {
        if (!contextPath) {
            return EMPTY_CONTEXT_CHAIN;
        }
        const contextChain = contextPath.split(CONTEXT_PREFIX).filter(Boolean);
        const result = contextChain.map((context, index) => {
            return contextChain.slice(index).reduce((result, part) => {
                result += part ? `_${part}` : part;
                return result;
            }, '');
        });
        result.push('');
        return result;
    }

    resolveOverriddenProp(overridesChain, prop) {
        for (let i = 0; i < overridesChain.length; i++) {
            const override = overridesChain[i];
            if (override?.hasOwnProperty(prop)) {
                return { value: override[prop] };
            }
        }
        return undefined;
    }

    resolveOverrides(overridesSet, override) {
        if (overridesSet?.length && override) {
            return [...overridesSet, override];
        } else if (!overridesSet?.length && override) {
            return [override];
        } else if (overridesSet?.length && !override) {
            return overridesSet;
        }
        return EMPTY_ARRAY;
    }

    concatPath(executionContext, prop) {
        const currentPath = executionContext.path;
        const path = currentPath ? `${currentPath}.${prop}` : prop;
        return { ...executionContext, path };
    }

    ensureExecutionContext(context, name, meta) {
        const result = context || { path: '', rootType: name };
        result.meta = meta;
        return result;
    }

    defineConfigMeta(obj, name, id) {
        if (obj.hasOwnProperty(META)) {
            log.error(
                `${name} component config contains ${META} property. ${META} is reserved property name and will be ignored.`
            );
            delete obj[META];
        }
        obj[META] = { name, isConfigObject: true, id };
    }

    getSettings() {
        if (this.settings) {
            return this.settings;
        }
        const defaultSettings = this.configRegistry.getConfigDefaults(CLASS_NAME) || EMPTY_OBJECT;
        const overrides = this.configRegistry.getConfigOverrides(CLASS_NAME) || EMPTY_OBJECT;
        this.settings = { ...defaultSettings, ...overrides };
        return this.settings;
    }
}

const isDebugMode = () => ExecutionMode.isDebugMode();
const isGetter = (prop, obj) => !!Object.getOwnPropertyDescriptor(obj, prop)['get'];
const isContextualProp = prop => prop.indexOf(CONTEXT_PREFIX) === 0;
const isReservedProp = prop => RESERVED_PROPS.includes(prop);
const isVoidedProp = (prop, overridesChain) => {
    let firstOverride;
    for (let i = 0; i < overridesChain.length; i++) {
        if (overridesChain[i]) {
            firstOverride = overridesChain[i];
            break;
        }
    }
    return firstOverride ? firstOverride.hasOwnProperty(prop) && isNil(firstOverride[prop]) : false;
};

export const configFactory = new ConfigFactory(configRegistry);
