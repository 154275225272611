import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import QuickNavigationButtonImpl from './quick-navigation-button.jsx';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Quick navigation button component.
 * Typically, used inside page header to trigger fast content navigation flow.
 *
 * @class QuickNavigationButton
 * @memberof Mojito.Presentation.Components
 */

/**
 * QuickNavigationButton prop types.
 *
 * @property {string} [title] - Title label. By default will be rendered on top.
 * @property {string} [subTitle] - Sub title label. By default will be rendered below title.
 * @property {boolean} [disabled = false] - True if component should be disabled. The indicator icon will be hidden and component not clickable.
 * @property {Function} [onClick = ()=>{}] - Callback triggered on button click.
 *
 * @memberof Mojito.Presentation.Components.QuickNavigationButton
 */
const propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

const defaultProps = {
    disabled: false,
    onClick: noop,
};

export default UIView('QuickNavigationButton', QuickNavigationButtonImpl, propTypes, defaultProps);
