import {PALETTE_IDS} from '#core/application/modules/ids.js';

const COLOR = {
    SCRIM_LIGHT: 'rgba(0, 0, 0, 0.6)',
    SCRIM_DARK: 'rgba(0, 0, 0, 0.8)',
    SHADOW_SHORT: 'rgba(0, 0, 0, 0.3)',
    SHADOW_LONG: 'rgba(0, 0, 0, 0.5)',

    // Colors from brandbook
    BRAND_BLACK1: '#232323',
    BRAND_BLACK2: '#373737',
    BRAND_BLACK3: '#000000',
    BRAND_WHITE: '#ffffff',
    BRAND_YELLOW: '#cca773',
    DIVIDER: '#4B4B4B',
    TRANSPARENT: 'transparent',

    // Custom colors
    BRAND_GREY: '#B2B2B2',
    BORDER_GREY1: '#27282B',
    BORDER_GREY2: '#4B4B4B',
};

const FONT = {
    FAMILY: 'lato, sans-serif',
    FAMILY_SECOND: 'classico, sans-serif',

    WEIGHT_NORMAL: 400,
    WEIGHT_SEMI_BOLD: 500,
    WEIGHT_BOLD: 600,

    SIZE_XXSMALL: 12,
    SIZE_XSMALL: 12,
    SIZE_SMALL: 13,
    SIZE_MEDIUM: 14,
    SIZE_LARGE: 16,
    SIZE_XLARGE: 18,
};

const TILE_FONT = Object.assign({}, FONT);
delete TILE_FONT.FAMILY_SECOND;

export const THEME = {
    [PALETTE_IDS.BASIC]: {
        FONT_FAMILY: 'lato, sans-serif',

        DEFAULT_PAGE_BG: COLOR.BRAND_BLACK1,
        DEFAULT_CONTAINER_BG: COLOR.BRAND_BLACK1,
        DEFAULT_HEADER_BG: COLOR.BRAND_BLACK1,
        DEFAULT_ODDS_BG: COLOR.BRAND_BLACK2,
        DEFAULT_ODDS_SELECTED_BG: COLOR.BRAND_YELLOW,
        DEFAULT_PROMO_CARD_BG: COLOR.BRAND_BLACK1,
        DEFAULT_SHEET_BG: COLOR.BRAND_BLACK2,

        DEFAULT_POPOVER_BG: COLOR.BRAND_BLACK2,
        DEFAULT_SELECTION_BG: COLOR.BRAND_BLACK2,
        DEFAULT_STYLIZED_BG: COLOR.BRAND_BLACK1,
        DEFAULT_LEAGUE_HEADER_BG: COLOR.BRAND_BLACK1,

        DEFAULT_PRIMARY_TEXT: `${COLOR.BRAND_WHITE}|${COLOR.BRAND_BLACK1}`,
        DEFAULT_SECONDARY_TEXT: COLOR.BRAND_GREY,

        DEFAULT_LIVE_COLOR: COLOR.BRAND_YELLOW,
        DEFAULT_PRIMARY_BUTTON_COLOR: COLOR.BRAND_YELLOW,
        DEFAULT_GENERIC_ACCENT_COLOR: COLOR.BRAND_YELLOW,
        DEFAULT_CONTROLS_BORDER_COLOR: COLOR.BRAND_GREY,

        DEFAULT_DIVIDER: COLOR.DIVIDER,
    },
    [PALETTE_IDS.FONT]: TILE_FONT,
    [PALETTE_IDS.INFO_NOTIFICATION]: {
        INFO_TEXT: COLOR.BRAND_BLACK1,
    },
    [PALETTE_IDS.ODDS_BUTTON]: {
        ODDS_BORDER_COLOR: COLOR.BORDER_GREY2,
        ODDS_INFO_TEXT: COLOR.BRAND_GREY,
        ODDS_SELECTED_INFO_TEXT: COLOR.BRAND_BLACK1,
        ODDS_PRICE_TEXT: COLOR.BRAND_WHITE,
        ODDS_SELECTED_PRICE_TEXT: COLOR.BRAND_BLACK1,
    },
    [PALETTE_IDS.EVENT_CARDS]: {
        CARD_BORDER_COLOR: COLOR.BORDER_GREY2,
    },
    [PALETTE_IDS.POPOVER]: {
        POPOVER_BORDER_COLOR: COLOR.BORDER_GREY1,
    },
    [PALETTE_IDS.SWITCH]: {
        SWITCH_BUTTON_BG: `${COLOR.BRAND_WHITE}|${COLOR.BRAND_BLACK1}`,
    },
    [PALETTE_IDS.APPLICATION_HEADER]: {
        APPLICATION_HEADER_BG: COLOR.BRAND_BLACK3,
        APPLICATION_HEADER_NAV_BAR_SETTINGS: {
            HEADER_SETTINGS_POPOVER_BORDER_COLOR: COLOR.BORDER_GREY1,
        },
    },
    [PALETTE_IDS.BAD_ROUTE_PAGE]: {
        BAD_ROUTE_PAGE_TITLE_TEXT: {
            color: COLOR.BRAND_YELLOW,
            _desktop_fontSize: '300px',
            fontWeight: FONT.WEIGHT_NORMAL,
            fontFamily: FONT.FAMILY_SECOND,
        },
        BAD_ROUTE_PAGE_INFO_TEXT: {
            fontSize: '33px',
            _desktop_marginTop: '-20px',
        },
    },
    [PALETTE_IDS.COUPONS]: {
        COUPONS_TITLE_TEXT: COLOR.BRAND_YELLOW,
    },
    [PALETTE_IDS.HOME_PAGE]: {
        HOME_PAGE_TOP_SPORTS_ROW: {
            PIVOT_ICON_COLOR: COLOR.BRAND_YELLOW,
        },
    },
    [PALETTE_IDS.MOBILE_NAVBAR]: {
        ICON_COLOR: COLOR.BRAND_YELLOW,
    },
    [PALETTE_IDS.APPLICATION_HEADER]: {
        APPLICATION_HEADER_LOGIN_BUTTON: {
            SECONDARY_BUTTON_BORDER_COLOR: COLOR.BRAND_YELLOW,
            SECONDARY_BUTTON_BORDER_RADIUS: 60,
            SECONDARY_BUTTON_BORDER_WIDTH: 2,
        },
    },
    [PALETTE_IDS.STAKE_INPUT]: {
        STAKE_INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND_YELLOW,
    },
    [PALETTE_IDS.BETSLIP]: {
        BETSLIP_BANKERS_BET_BUTTON: {
            TOGGLE_BUTTON_SELECTED_BG: COLOR.BRAND_YELLOW,
        },

        // Odds boosts
        BETSLIP_ODDS_BOOSTS_COMMON_COLOR: COLOR.BRAND_GREY,
    },
    [PALETTE_IDS.AZ_MENU]: {
        MOBILE_AZ_SECTION_HEADER_TEXT: {
            textTransform: 'none',
            color: COLOR.BRAND_WHITE,
        },
    },
    [PALETTE_IDS.BET_BONUS_LABEL]: {
        BET_BONUS_LABEL_TEXT: COLOR.BRAND_YELLOW,
    },
    [PALETTE_IDS.QUICK_BETSLIP]: {
        QUICK_BETSLIP_CONTAINER_BG: COLOR.BRAND_BLACK1,
    },
};
