import { Repository, SocketEnablerClient, Search, ExecutionEngine } from '@pbs/ngen-assets';

/**
 * The Mojito NGen layer bundles NGen modules used by Mojito.
 *
 * @namespace Mojito.NGen
 * @memberof Mojito
 */

/**
 * Logger to be used for system-wide logging.
 *
 * @memberof Mojito.NGen
 */
export { logger } from '@pbs/ngen-assets';

/**
 * Repository for retrieval of data from frontend server.
 *
 * @memberof Mojito.NGen
 */
export const repository = new Repository();

/**
 * Socket enabler client to be used in conjunction with the repository.
 *
 * @memberof Mojito.NGen
 */
export const socketEnablerClient = new SocketEnablerClient();

/**
 * Search API.
 *
 * @memberof Mojito.NGen
 */
export const search = new Search();

/**
 * Provide access to configuring and monitoring of WebSocket requests queue.
 *
 * @memberof Mojito.NGen
 */
export const executionEngine = new ExecutionEngine();

/**
 * Provide access to NGEN endpoint configuration which is necessary to build Websocket endpoint connection.
 *
 * @type {{SocketEnablerClient: SocketEnablerClient, Repository: Repository, ExecutionEngine: ExecutionEngine, Search: Search}}
 *
 * @memberof Mojito.NGen
 */
export const endpointConfig = { SocketEnablerClient, Repository, ExecutionEngine, Search };
