import PropTypes from 'prop-types';
import TabbedPaneTabImpl from './tab.jsx';
import MojitoCore from 'mojito/core';

/**
 * Helper component for TabbedPane. It does not actually do anything besides providing a container
 * and interface for each logical tab of a TabbedPane.
 *
 * @class TabbedPaneTab
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for TabbedPaneTab.
 *
 * @property {node} buttonContent - Content to be displayed in the tab button.
 * @property {boolean} disabled - Specifies if the tab button should be disabled. If true, the button is disabled.
 * @property {string} [class] - Class name to be applied to the tab.
 * @property {string} selectableKey - Key identifying the currently selected Tab.
 *
 * @memberof Mojito.Presentation.Components.TabbedPaneTab
 */
const propTypes = {
    buttonContent: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    class: PropTypes.string,
    children: PropTypes.node.isRequired,
    selectableKey: PropTypes.string.isRequired,
};

export default MojitoCore.Presentation.UIView('TabbedPaneTab', TabbedPaneTabImpl, propTypes);
