import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const { DataProvider } = MojitoCore.Services;
const { repositoryCollectionFactory } = MojitoCore.Base;

const COLLECTION_NAME = 'container';
/**
 *
 * Class providing a link to the container collection, which is a list of sports/sport classes/types.
 * See container collection in mongo to find out available storable documents. Typically, each item in the collection
 * represents the group of content nodes that belongs to it according to certain criteria. Can be used to present hierarchy of content nodes.
 *
 * @class ContainerProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class ContainerProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(id, setter) {
        const subscription = super.subscribeToEntity(id, setter, COLLECTION_NAME);
        return handleSubscription(subscription, id, COLLECTION_NAME);
    }
}

export default new ContainerProvider();
