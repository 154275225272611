/**
 * The Mojito utils layer. Makes JavaScript easier by providing useful functions to work with arrays, numbers, objects, strings, etc.
 * <br>.
 *
 * To access the Mojito util function use: <code>import { capitalize } from 'mojito/utils';</code>.
 *
 * @namespace Utils
 * @memberof Mojito
 */

/**
 * Creates an object with the same values as object and keys generated by running each own
 * enumerable string keyed property of object through iteratee.
 * The iteratee is invoked with two arguments: (value, key).
 *
 * @function mapKeys
 *
 * @param {object} obj - The object to iterate over.
 * @param {Function} [iteratee = value => value] - The function invoked per iteration. By default, returns value.
 *
 * @returns {object} New mapped object.
 * @memberof Mojito.Utils
 */
export function mapKeys(obj, iteratee = value => value) {
    if (!obj) {
        return obj;
    }
    const mappedEntries = Object.entries(obj).map(([key, value]) => [iteratee(value, key), value]);
    return Object.fromEntries(mappedEntries);
}

/**
 * Creates an object by grouping elements from a collection based on an iteratee function.
 *
 * @function keyBy
 *
 * @param {obj} collection - The collection to iterate over.
 * @param {Function} [iteratee=value => value] - The iteratee function that determines the key for each element.
 *
 * @returns {obj} An object where keys are generated by the iteratee and values are the corresponding elements.
 * @memberof Mojito.Utils
 */
export function keyBy(collection, iteratee = value => value) {
    if (!Array.isArray(collection) && typeof collection !== 'object') {
        return {};
    }

    if (Array.isArray(collection)) {
        return keyByFromArray(collection, iteratee);
    }
    return keyByFromObject(collection, iteratee);
}

function keyByFromArray(collection, iteratee) {
    return collection.reduce((acc, current) => {
        const key = typeof iteratee === 'function' ? iteratee(current) : current[iteratee];
        acc[key] = current;
        return acc;
    }, {});
}

function keyByFromObject(collection, iteratee) {
    const result = {};
    for (const key in collection) {
        if (collection.hasOwnProperty(key)) {
            const item = collection[key];
            const newKey = typeof iteratee === 'function' ? iteratee(item) : item;
            result[newKey] = item;
        }
    }
    return result;
}

/**
 * Checks if the provided value is null or undefined.
 *
 * @function isNil
 *
 * @param {any} value - The value to check.
 *
 * @returns {boolean} Returns true if the value is null or undefined, else false.
 * @memberof Mojito.Utils
 */
export function isNil(value) {
    return value === null || value === undefined;
}

/**
 * Checks if a value is an object (excluding arrays).
 *
 * @function isObject
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} True if the value is an object, false otherwise.
 * @memberof Mojito.Utils
 */
export function isObject(value) {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
}

/**
 * Checks if a value is a function.
 *
 * @function isFunction
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} True if the value is a function, false otherwise.
 * @memberof Mojito.Utils
 */
export function isFunction(value) {
    return typeof value === 'function';
}

/**
 * Removes all given values from array using strict equality for comparisons.
 * Note: this function modifies input array.
 *
 * @function pull
 *
 * @param {Array} arr - The array to modify.
 * @param {*} values - The values to remove.
 *
 * @memberof Mojito.Utils
 */
export function pull(arr, ...values) {
    if (!arr) {
        return;
    }
    values.forEach(value => {
        const index = arr.indexOf(value);
        index >= 0 && arr.splice(index, 1);
    });
}

/**
 * Creates an array excluding all given values using strict equality for comparisons.
 * Note: this function does not modify input array.
 *
 * @function without
 *
 * @param {Array} arr - The array to inspect.
 * @param {...*} values - The values to exclude.
 *
 * @returns {object} Returns the new array of filtered values.
 * @memberof Mojito.Utils
 */
export function without(arr, ...values) {
    if (!arr) {
        return arr;
    }
    return arr.filter(value => !values.includes(value));
}

/**
 * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
 *
 * @function get
 *
 * @param {object} obj - The object to query.
 * @param {string|Array} path - The path of the property to get.
 * @param {*} defaultValue - The value returned for undefined resolved values.
 *
 * @returns {object} Returns the resolved value.
 * @memberof Mojito.Utils
 */
export function get(obj, path, defaultValue) {
    if (!obj) {
        return obj;
    }
    const props = Array.isArray(path) ? path : path.split('.');
    const prop = props.shift();
    const isLastNode = props.length === 0;
    if (isLastNode) {
        return obj[prop] || defaultValue;
    }
    return get(obj[prop], props, defaultValue);
}

/**
 * Sets the value at path of object. If a portion of path doesn't exist, it's created.
 *
 * @function set
 *
 * @param {object} obj - The object to modify.
 * @param {string|Array} path - The path of the property to set.
 * @param {*} value - The value to set.
 *
 * @returns {object} Returns object.
 * @memberof Mojito.Utils
 */
export function set(obj, path, value) {
    if (!obj) {
        return obj;
    }
    const props = Array.isArray(path) ? path : path.split('.');
    const prop = props.shift();
    const isLastNode = props.length === 0;
    if (isLastNode) {
        obj[prop] = value;
        return obj;
    }
    const shouldCreateEmpty =
        !obj.hasOwnProperty(prop) || obj[prop] === undefined || obj[prop] === null;
    if (shouldCreateEmpty) {
        obj[prop] = {};
    }
    obj[prop] = set(obj[prop], props, value);
    return obj;
}

/**
 * Creates an array of unique values, from all given arrays using iteratee which is invoked for each element
 * of each array to generate the criterion by which uniqueness is computed.
 * Result values are chosen from the first array in which the value occurs.
 * The iteratee is invoked with one argument: (value).
 *
 * @function unionBy
 *
 * @param {...*} args - The arrays to inspect. Last argument is iteratee function.
 *
 * @returns {object} Returns object.
 * @memberof Mojito.Utils
 */
export function unionBy(...args) {
    let iteratee = args[args.length - 1];
    const hasIteratee = typeof iteratee === 'function';
    iteratee = hasIteratee ? iteratee : value => value;
    const lastIndex = hasIteratee ? args.length - 1 : args.length;
    return args.slice(0, lastIndex).reduce((unionArr, arr) => {
        arr.forEach(value => {
            const itemExists = unionArr.find(item => iteratee(value) === iteratee(item));
            if (!itemExists) {
                unionArr.push(value);
            }
        });
        return unionArr;
    }, []);
}

/**
 * Checks if value is a plain object, that is, an object created by the Object constructor or one with a [[Prototype]] of null.
 *
 * @function isPlainObject
 *
 * @param {*} obj - The value to check.
 *
 * @returns {boolean} Returns true if value is a plain object, else false.
 * @memberof Mojito.Utils
 */
export function isPlainObject(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }
    const proto = Object.getPrototypeOf(obj);
    if (proto === null) {
        return true;
    }
    let baseProto = proto;
    while (Object.getPrototypeOf(baseProto) !== null) {
        baseProto = Object.getPrototypeOf(baseProto);
    }
    return proto === baseProto;
}

/**
 * Casts value as an array if it's not one.
 *
 * @function castArray
 *
 * @param {*} value - The value to inspect.
 *
 * @returns {Array} Returns the cast array.
 * @memberof Mojito.Utils
 */
export function castArray(value) {
    if (!value) {
        return value;
    }
    return Array.isArray(value) ? value : [value];
}

/**
 * Computes the sum of the values in array. It accepts iteratee which is invoked for each element in array
 * to generate the value to be summed. The iteratee is invoked with one argument: (value).
 *
 * @function sumBy
 *
 * @param {*} arr - The array to iterate over.
 * @param {*} [iteratee = value => value] - The iteratee invoked per element.
 *
 * @returns {Array} Returns the sum.
 * @memberof Mojito.Utils
 */
export function sumBy(arr, iteratee = value => value) {
    return arr.reduce((sum, value) => sum + iteratee(value), 0);
}

/**
 * Converts the first character of string to upper case and the remaining to lower case.
 *
 * @function capitalize
 *
 * @param {string} str - String to capitalize.
 *
 * @returns {string} Capitalized string.
 * @memberof Mojito.Utils
 */
export function capitalize(str) {
    if (!str) {
        return str;
    }
    return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

/**
 * Converts the first character of string to upper case.
 *
 * @function upperFirst
 *
 * @param {string} str - The string to convert.
 *
 * @returns {string} Returns the converted string.
 * @memberof Mojito.Utils
 */
export function upperFirst(str) {
    if (!str) {
        return str;
    }
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

/**
 * Binds methods of an object to the object itself, overwriting the existing method.
 *
 * @function bindAll
 *
 * @param {object} obj - The object to bind and assign the bound methods to.
 * @param {...string|Array<string>} methodNames - The object method names to bind.
 *
 * @returns {object} Returns object.
 * @memberof Mojito.Utils
 */
export function bindAll(obj, ...methodNames) {
    if (!obj) {
        return obj;
    }
    const methodsToBind = Array.isArray(methodNames[0]) ? methodNames[0] : methodNames;
    const isFunction = fun => typeof fun === 'function';
    methodsToBind
        .filter(prop => isFunction(obj[prop]))
        .forEach(prop => {
            obj[prop] = obj[prop].bind(obj);
        });
    return obj;
}

/**
 * Creates an array of numbers (positive or negative) progressing from 0 up to, but not including, end with step 1.
 * <br><br>
 * Example: value 5 results to array [0, 1, 2, 3, 4].
 *
 * @function range
 *
 * @param {number} end - The end of the range.
 *
 * @returns {Array} The range of numbers.
 * @memberof Mojito.Utils
 */
export function range(end) {
    if (!end || isNaN(Number(end))) {
        return [];
    }
    const arr = [...Array(Math.abs(end)).keys()];
    return end < 0 ? arr.map(item => -Number(item)) : arr;
}

/**
 * Checks if the given value is a function.
 *
 * @function isNumber
 *
 * @param {any} value - The value to check.
 *
 * @returns {boolean} Returns true if the value is a function, else false.
 * @memberof Mojito.Utils
 */
export function isNumber(value) {
    return typeof value === 'number';
}

/**
 * Creates an object composed of the own and inherited enumerable paths of 'obj' that are not omitted.
 *
 * @function omit
 *
 * @param {object} obj - The source object.
 * @param {Array} keys - The property paths to omit.
 *
 * @returns {object} Returns the new object.
 * @memberof Mojito.Utils
 */
export function omit(obj, ...keys) {
    const keysToRemove = new Set(keys.flat());

    // Create a new object with entries that are not in keysToRemove
    const result = {};
    for (const [k, v] of Object.entries(obj)) {
        if (!keysToRemove.has(k)) {
            result[k] = v;
        }
    }

    return result;
}

/**
 * Creates an array that contains all the values from the first array that are not present in the second array.
 *
 * @function difference
 *
 * @param {Array} array1 - The first Array.
 * @param {Array} array2 - The second Array.
 *
 * @returns {Array} Returns a new array that contains all the values from `array1` that are not in `array2`.
 * @memberof Mojito.Utils
 */
export function difference(array1, array2) {
    return array1.filter(value => !array2.includes(value));
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 *
 * @function isEqual
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 *
 * @returns {boolean} Returns true if the values are equivalent, else false.
 * @memberof Mojito.Utils
 */
const cached = new WeakMap();
export function isEqual(a, b) {
    if (a === null || b === null) {
        return a === b;
    }

    if (typeof a !== 'object' || typeof b !== 'object') {
        return a === b;
    }

    const dataTypeA = detectDataType(a);
    const dataTypeB = detectDataType(b);
    if (dataTypeA !== dataTypeB) {
        return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) {
        return false;
    }

    const symbolsA = Object.getOwnPropertySymbols(a);
    const symbolsB = Object.getOwnPropertySymbols(b);
    if (symbolsA.length !== symbolsB.length) {
        return false;
    }

    if (cached.get(a)?.has(b)) {
        return true;
    }
    if (cached.get(b)?.has(a)) {
        return true;
    }

    cache(a, b, cached);

    const propertyNamesA = [...keysA, ...symbolsA];

    for (const propertyNameA of propertyNamesA) {
        if (!b.hasOwnProperty(propertyNameA)) {
            return false;
        }

        const propertyValueA = a[propertyNameA];
        const propertyValueB = b[propertyNameA];

        if (!isEqual(propertyValueA, propertyValueB)) {
            return false;
        }
    }

    return true;
}

/**
 * Detects the data type of the input value.
 * If the value is an array, it returns 'array'.
 * Otherwise, it assumes the value is an object and returns 'object'.
 *
 * @function detectDataType
 *
 * @param {*} value - The input value to determine the data type.
 *
 * @returns {string} The detected data type ('array' or 'object').
 * @memberof Mojito.Utils
 */
function detectDataType(value) {
    if (Array.isArray(value)) {
        return 'array';
    }
    return 'object';
}

/**
 * Caches associations between two values in a Map of Sets.
 * Given two values 'a' and 'b', it ensures that 'a' is associated with 'b'
 * and 'b' is associated with 'a' in the provided 'cached' Map.
 *
 * @function cache
 *
 * @param {*} a - The first value to cache.
 * @param {*} b - The second value to cache.
 * @param {Map} cached - The Map used for caching associations.
 */
function cache(a, b, cached) {
    const associate = value => {
        let variableToSet = cached.get(value);
        if (!variableToSet) {
            variableToSet = new Set();
            cached.set(value, variableToSet);
        }
        variableToSet.add(value);
    };
    associate(a);
    associate(b);
}

/**
 * Splits an array into chunks of a specified chunked.
 *
 * @function chunk
 *
 * @param {Array} value - The array to be chunked.
 * @param {number} size - The size of each chunk.
 *
 * @returns {Array} Returns a new array with chunks of the original array.
 * @memberof Mojito.Utils
 */
export function chunk(value, size) {
    return value.reduce((arr, item, idx) => {
        return idx % size === 0
            ? [...arr, [item]]
            : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
    }, []);
}

/**
 * Function to test if variable is a string.
 *
 * @function isString
 *
 * @param {*} value - The value to be curried.
 *
 * @returns {Function} Returns true if it is a string, else false.
 * @memberof Mojito.Utils
 */
export function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

/**
 * This is a no-op function (no operation).
 * It does nothing and can be used as a placeholder or default function.
 */
export function noop() {
    // It is intentionally empty.
}

/**
 * Checks if a value is a boolean.
 *
 * @function isBoolean
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} Returns true if the value is a boolean, otherwise false.
 * @memberof Mojito.Utils
 */
export function isBoolean(value) {
    return typeof value === 'boolean';
}

/**
 * Checks if a value is considered "empty".
 *
 * @function isEmpty
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} Returns true if the value is empty, false otherwise.
 * @memberof Mojito.Utils
 */
export function isEmpty(value) {
    if (value === null || value === undefined) {
        return true;
    }
    if (
        isArrayLike(value) &&
        (isArray(value) || typeof value === 'string' || typeof value.splice === 'function')
    ) {
        return !value.length;
    }
    for (const key in value) {
        if (value.hasOwnProperty.call(value, key)) {
            return false;
        }
    }
    return true;
}

/**
 * Checks if `value` is array-like. A value is considered array-like if it's
 * not a function and has a `value.length` that's an integer greater than or
 * equal to `0` and less than or equal to `Number.MAX_SAFE_INTEGER`.
 *
 * @function isArrayLike
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} Returns true if `value` is array-like, else false.
 * @memberof Mojito.Utils
 */
export function isArrayLike(value) {
    return value !== null && isLength(value.length) && !isFunction(value);
}

/**
 * Checks if a reasonable number 0 <= value <= Number.MAX_SAFE_INTEGER.
 *
 * @function isLength
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} True if `value` is a valid length, else false.
 * @memberof Mojito.Utils
 */
export function isLength(value) {
    return (
        typeof value === 'number' &&
        value > -1 &&
        value % 1 === 0 &&
        value <= Number.MAX_SAFE_INTEGER
    );
}

/**
 * Checks if `value` is classified as an `Array` object.
 *
 * @function isArray
 *
 * @param {*} value - The value to check.
 *
 * @returns {boolean} True if `value` is an array, else false.
 * @memberof Mojito.Utils
 */
export function isArray(value) {
    return Array.isArray(value);
}

/**
 * Inverts the keys and values of an object.
 * For example, if the input object is { a: 'apple', b: 'banana' },
 * the output will be { apple: 'a', banana: 'b' }.
 *
 * @function invert
 *
 * @param {object} obj - The input object to invert.
 *
 * @returns {object} The inverted object.
 * @memberof Mojito.Utils
 */
export function invert(obj) {
    return Object.keys(obj).reduce((res, k) => {
        res[obj[k]] = k;
        return res;
    }, {});
}

/**
 * Filters the elements in `array1` that are not present in `array2` based on the specified `key`.
 *
 * @function differenceBy
 *
 * @param {Array} array1 - The first array.
 * @param {Array} array2 - The second array.
 * @param {string} key - The property key to compare elements.
 *
 * @returns {Array} The filtered array.
 * @memberof Mojito.Utils
 */
export function differenceBy(array1, array2, key) {
    return array1.filter(a => !array2.some(b => b[key] === a[key]));
}

/**
 * Selects specific keys from a dictionary-like object.
 *
 * @function pick
 *
 * @param {object} obj - The input dictionary-like object.
 * @param {...string[]} keys - Variable-length argument list of keys to select.
 *
 * @returns {object} A new object containing only the selected keys and their corresponding values.
 * @memberof Mojito.Utils
 */
export function pick(obj, ...keys) {
    if (!obj || typeof obj !== 'object') {
        return {}; // Return an empty object if the input is not valid
    }

    const selectedKeys = new Set(keys.flat());
    return Object.fromEntries(Object.entries(obj).filter(([k]) => selectedKeys.has(k)));
}

/**
 * Filters the properties of an object based on the specified predicate.
 *
 * @function pickBy
 *
 * @param {object} object - The input object.
 * @param {Function} [predicate = value => value] - The predicate function that determines which properties to include.
 *
 * @returns {object} A new object with properties included based on the predicate.
 * @memberof Mojito.Utils
 */
export function pickBy(object, predicate = value => value) {
    return Object.fromEntries(Object.entries(object).filter(([, value]) => predicate(value)));
}

/**
 * Filters the properties of an object based on the specified predicate.
 *
 * @function omitBy
 *
 * @param {object} object - The input object.
 * @param {Function} predicate - The predicate function that determines which properties to omit.
 *
 * @returns {object} A new object with properties omitted based on the predicate.
 * @memberof Mojito.Utils
 */
export function omitBy(object, predicate) {
    if (object === null || object === undefined || typeof object !== 'object') {
        return {};
    }
    const result = {};
    predicate = predicate || (v => !v);
    const propNames = Object.keys(object);
    for (let i = 0; i < propNames.length; i++) {
        const prop = propNames[i];
        if (!predicate(object[prop], prop)) {
            result[prop] = object[prop];
        }
    }
    return result;
}

/**
 * Creates a debounced function that delays invoking the original function until after `wait` milliseconds
 * have elapsed since the last time the debounced function was invoked.
 *
 * @param {Function} callback - The function to debounce.
 * @param {number} [timeout = 500] - The number of milliseconds to delay.
 * @param {object} [options = {}] - Additional options for debouncing.
 * @param {boolean} [options.leading=false] - Whether to trigger the callback on the leading edge.
 * @param {boolean} [options.trailing=true] - Whether to trigger the callback on the trailing edge.
 *
 * @returns {Function} The debounced function.
 * @memberof Mojito.Utils
 */
export function debounce(callback, timeout = 500, options = {}) {
    let timer;
    const debouncedCallback = (...args) => {
        clearTimeout(timer);
        if (options.leading && !timer) {
            callback(...args);
        }
        timer = setTimeout(() => {
            if (!options.leading || options.trailing) {
                callback(...args);
            }
            timer = null;
        }, timeout);
    };
    debouncedCallback.cancel = () => {
        clearTimeout(timer);
        timer = null;
    };
    return debouncedCallback;
}

/**
 * This function curries the provided function.
 *
 * @function curry
 *
 * @param {func} fn - The function to curry.
 *
 * @returns {func} A new curried function.
 * @memberof Mojito.Utils
 */
export function curry(fn) {
    return function curried(...args) {
        if (args.length < fn.length) {
            return curried.bind(null, ...args);
        }
        return fn.apply(null, args);
    };
}

/**
 * This function negates the result of the provided function.
 *
 * @function add
 *
 * @param {number} a - The first number.
 * @param {number} b - The second number.
 * @param {number} c - The third number.
 *
 * @returns {number} The sum of the three input numbers.
 * @memberof Mojito.Utils
 */
export function add(a, b, c) {
    return a + b + c;
}

/**
 * Filters an array to include only unique elements based on the iteratee function.
 *
 * @function uniqBy
 *
 * @param {Array} arr - The input array.
 * @param {func} iteratee - The iteratee function.
 *
 * @returns {Array} An array containing only unique elements based on the iteratee function.
 * @memberof Mojito.Utils
 */
export const uniqBy = (arr, iteratee) => {
    if (typeof iteratee === 'string') {
        const prop = iteratee;
        iteratee = item => item[prop];
    }

    return arr.filter((x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y)));
};

/**
 * This function negates the result of the provided function.
 *
 * @function negate
 *
 * @param {func} func - The function to negate.
 *
 * @returns {func} A new function that negates the result of the original function.
 * @memberof Mojito.Utils
 */
export const negate =
    func =>
    (...args) =>
        !func(...args);

/**
 * Computes the exclusive OR (XOR) of multiple arrays.
 * Removes duplicate elements and returns a new list with unique values.
 *
 * @function xor
 *
 * @param {...Array} arrays - Variable-length argument list of arrays.
 *
 * @returns {Array} A list containing unique values that appear in exactly one of the input arrays.
 * @memberof Mojito.Utils
 */
export function xor(...arrays) {
    const result = new Set();
    const seen = new Set(); // To track seen elements

    for (const arr of arrays) {
        for (const item of arr) {
            if (seen.has(item)) {
                // If already seen, remove from result
                result.delete(item);
            } else {
                // Otherwise, add to result and mark as seen
                result.add(item);
                seen.add(item);
            }
        }
    }

    return Array.from(result);
}

/**
 * Maps each element of the input array to a new value using the provided iteratee function.
 *
 * @function arrayMap
 *
 * @param {Array} array - The input array.
 * @param {Function} iteratee - A function that takes an element, its index, and the entire array as arguments.
 *
 * @returns {Array} A new array with the mapped values.
 * @memberof Mojito.Utils
 */
function arrayMap(array, iteratee) {
    return array.map(iteratee);
}

/**
 * Finds the index of the first occurrence of the specified value in the array.
 *
 * @function baseIndexOf
 *
 * @param {Array} array - The input array.
 * @param {any} value - The value to search for.
 * @param {number} fromIndex - The starting index for the search.
 *
 * @returns {number} The index of the first occurrence of the value, or -1 if not found.
 * @memberof Mojito.Utils
 */
function baseIndexOf(array, value, fromIndex) {
    return array.indexOf(value, fromIndex);
}

/**
 * Finds the index of the first occurrence of the specified value in the array using a custom comparator function.
 *
 * @function baseIndexOfWith
 *
 * @param {Array} array - The input array.
 * @param {any} value - The value to search for.
 * @param {number} fromIndex - The starting index for the search.
 * @param {Function} comparator - A function that compares elements and returns true if they match.
 *
 * @returns {number} The index of the first occurrence of the value, or -1 if not found.
 * @memberof Mojito.Utils
 */
function baseIndexOfWith(array, value, fromIndex, comparator) {
    let index = fromIndex - 1;
    const length = array.length;

    while (++index < length) {
        if (comparator(array[index], value)) {
            return index;
        }
    }
    return -1;
}

/**
 * Returns a new function that applies the given unary function to its argument.
 *
 * @function baseUnary
 *
 * @param {Function} func - The unary function to apply.
 *
 * @returns {Function} A new function that takes an argument and applies func to it.
 * @memberof Mojito.Utils
 */
function baseUnary(func) {
    return function (value) {
        return func(value);
    };
}

/** Used for built-in method references. */
const arrayProto = Array.prototype;

/** Built-in value references. */
const splice = arrayProto.splice;

/**
 * Removes all occurrences of specified values from the input array.
 *
 * @function basePullAll
 *
 * @param {Array} array - The input array.
 * @param {Array} values - An array of values to remove.
 * @param {Function} [iteratee] - A function to transform each value before comparison.
 * @param {Function} comparator - (Optional) A custom comparator function for value comparison.
 *
 * @returns {Array} The modified input array after removing the specified values.
 * @memberof Mojito.Utils
 */
function basePullAll(array, values, iteratee, comparator) {
    const indexOf = comparator ? baseIndexOfWith : baseIndexOf;
    let index = -1;
    const length = values.length;
    let seen = array;

    if (array === values) {
        values = copyArray(values);
    }
    if (iteratee) {
        seen = arrayMap(array, baseUnary(iteratee));
    }
    while (++index < length) {
        let fromIndex = 0;
        const value = values[index],
            computed = iteratee ? iteratee(value) : value;

        while ((fromIndex = indexOf(seen, computed, fromIndex, comparator)) > -1) {
            if (seen !== array) {
                splice.call(seen, fromIndex, 1);
            }
            splice.call(array, fromIndex, 1);
        }
    }
    return array;
}

/**
 * Creates a shallow copy of the input array.
 *
 * @function copyArray
 *
 * @param {Array} source - The source array to copy.
 * @param {Array} array - The return value with copy the array.
 *
 * @returns {Array} A new array with the same elements as the source array.
 * @memberof Mojito.Utils
 */
function copyArray(source, array) {
    let index = -1;
    const length = source.length;

    array || (array = Array(length));
    while (++index < length) {
        array[index] = source[index];
    }
    return array;
}

/**
 * Removes all provided values from an array.
 *
 * @function pullAll
 *
 * @param {Array} array - The array to modify.
 * @param {Array} values - The values to remove.
 *
 * @returns {Array} The modified array with specified values removed.
 * @memberof Mojito.Utils
 */
export function pullAll(array, values) {
    return array && array.length && values && values.length ? basePullAll(array, values) : array;
}

/**
 * Groups elements of an array based on a specified key function.
 *
 * @function groupBy
 *
 * @param {Array} arr - The input array to be grouped.
 * @param {Function} groupByKeyFn - A function that computes the grouping key for each element.
 *
 * @returns {object} A dictionary where keys are the computed grouping keys and values are arrays of elements belonging to each group.
 * @memberof Mojito.Utils
 */
export function groupBy(arr, groupByKeyFn) {
    return arr.reduce((acc, c) => {
        const key = groupByKeyFn(c);
        acc[key] = acc[key] || [];
        acc[key].push(c);
        return acc;
    }, {});
}

/**
 * Creates a new array containing unique values from the input arrays.
 *
 * @function uniq
 *
 * @param {...Array} arrays - Arrays to extract unique values from.
 *
 * @returns {Array} An array with unique values.
 * @memberof Mojito.Utils
 */
export function uniq(...arrays) {
    // Initialize an empty Set to store unique values
    const uniqueValues = new Set();

    // Iterate over each array
    for (const arr of arrays) {
        // Add each element to the Set (duplicates will be automatically removed)
        arr.forEach(element => uniqueValues.add(element));
    }

    // Convert the Set back to an array and return
    return Array.from(uniqueValues);
}

/**
 * Returns the first element of an array.
 *
 * @function first
 *
 * @param {Array} arr - The array to retrieve the first element from.
 *
 * @returns {any} The first element of the array, or undefined if the array is empty.
 * @memberof Mojito.Utils
 */
export function first(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
        return undefined;
    }
    return arr[0];
}

/**
 * Maps the values of an object using an iteratee function.
 *
 * @function mapValues
 *
 * @param {object} object - The object to iterate over.
 * @param {Function} iteratee - The function to apply to each value.
 *
 * @returns {object} A new object with mapped values.
 * @memberof Mojito.Utils
 */
export function mapValues(object, iteratee) {
    object = Object(object);
    const result = {};

    Object.keys(object).forEach(key => {
        result[key] = iteratee(object[key], key, object);
    });
    return result;
}

/**
 * Creates a new function that can be called only once.
 * Upon the first invocation, it executes the original function and caches the result.
 * Subsequent invocations return the cached result without re-executing the function.
 *
 * @function once
 *
 * @param {Function} fn - The original function to be called once.
 *
 * @returns {Function} A new function with memoization support.
 * @memberof Mojito.Utils
 */
export const once = fn => {
    let called = false;
    let result;
    return (...args) => {
        if (!called) {
            result = fn(...args);
            called = true;
        }
        return result;
    };
};

/**
 * Creates a memoized version of a function.
 *
 * @function memoize
 *
 * @param {Function} func - The original function to be memoized.
 * @param {Function} [resolver] - A function that computes the cache key based on the function arguments.
 *
 * @returns {undefined} A memoized version of the original function.
 * @memberof Mojito.Utils
 */
export function memoize(func, resolver) {
    const cache = new Map();
    if (typeof func === 'undefined') {
        return;
    }

    return function (...args) {
        const key = resolver ? resolver(...args) : args[0];

        if (cache.has(key)) {
            return cache.get(key);
        }
        const result = func(...args);
        cache.set(key, result);
        return result;
    };
}

/**
 * Converts the given value to a number.
 * If the value is undefined, returns undefined.
 *
 * @function toNumber
 * @param {any} value - The value to convert to a number.
 *
 * @returns {number|undefined} The converted value as a number, or undefined if the input is undefined.
 * @memberof Mojito.Utils
 */
export function toNumber(value) {
    if (typeof value === 'undefined') {
        return;
    }
    return Number(value); // Converts the value to a number
}

/**
 * Create a new function that partially applies the first argument.
 *
 * @function partial
 *
 * @param {Function} func - The original function to be partially applied.
 * @param {...*} partials - Additional arguments to be partially applied.
 *
 * @returns {Function|undefined} A new function with partially applied arguments.
 * @memberof Mojito.Utils
 */
export function partial(func, ...partials) {
    return function (...args) {
        return func.call(this, ...partials, ...args);
    };
}

/**
 * Merge properties from multiple objects into a single object.
 *
 * @function merge
 *
 * @param {object} target - The target object to merge properties into.
 * @param {...object} sources - One or more source objects to merge.
 *
 * @returns {object} The merged object.
 * @memberof Mojito.Utils
 */
export function merge(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    if (!isObject(target) || !sources.every(isObject)) {
        return {};
    }
    const source = sources.shift();
    if (typeof source === 'undefined' || source === null) {
        return target;
    }
    for (const key in source) {
        if (isPlainObject(source[key]) && !Array.isArray(source[key])) {
            if (!target[key]) {
                target[key] = {};
            }
            merge(target[key], source[key]);
        } else {
            // Check if the property is writable before assigning
            assignProperty(target, key, source[key]);
        }
    }
    return merge(target, ...sources);
}

function assignProperty(target, key, value) {
    const descriptor = Object.getOwnPropertyDescriptor(target, key);
    if (!descriptor || descriptor.writable || descriptor.set) {
        target[key] = value;
    }
}

/**
 * Throttles the execution of the input function by waiting for a specified amount of time
 * between consecutive invocations. It can be used to limit the rate at which a function is called.
 *
 * @function throttle
 *
 * @param {Function} func - The function to be throttled.
 * @param {number} wait - The time (in milliseconds) to wait between consecutive invocations.
 * @param {object} options - An optional dictionary with the following keys:
 * - leading: If true, the function will be called immediately when invoked (default: true).
 * - trailing: If true, the function will be called after the wait time has passed (default: true).
 *
 * @returns {func} The throttled function.
 * @memberof Mojito.Utils
 */
export function throttle(func, wait, options) {
    let leading = true;
    let trailing = true;

    if (typeof func !== 'function') {
        throw new TypeError('Expected a function');
    }
    if (isObject(options)) {
        leading = 'leading' in options ? !!options.leading : leading;
        trailing = 'trailing' in options ? !!options.trailing : trailing;
    }
    return debounce(func, wait, {
        leading,
        trailing,
        maxWait: wait,
    });
}

/**
 * Creates a kebab-case string from the input string.
 *
 * @function kebabCase
 *
 * @param {string} string - The input string to convert.
 *
 * @returns {string|null} The kebabCase representation of the input string.
 * @memberof Mojito.Utils
 */
export function kebabCase(string) {
    if (string === undefined) {
        return null;
    }
    return string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}
