import PropTypes from 'prop-types';
import MarketGroupSelectorImpl from './market-group-selector.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * @class MarketGroupSelector
 * @memberof Mojito.Presentation.Components
 */

/**
 * @typedef MarketGroup
 *
 * @param {string} id - Market group id.
 * @param {string} name - Market group name.
 * @param {string} l10nKey - Localization key will be used to resolve static translation string if provided. Has precedence over name.
 *
 * @memberof Mojito.Presentation.Components.MarketGroupSelector
 */

/**
 * MarketGroupSelector prop types.
 *
 * @property {Array<Mojito.Presentation.Components.MarketGroupSelector.MarketGroup>} marketGroups - Array of market groups.
 * @property {Function} onMarketGroupChange - Callback function that is triggered when a market group is selected.
 * @property {string} [selectedMarketGroupId] - Id of the currently selected market group.
 *
 * @memberof Mojito.Presentation.Components.MarketGroupSelector
 */
const propTypes = {
    marketGroups: PropTypes.array.isRequired,
    onMarketGroupChange: PropTypes.func.isRequired,
    selectedMarketGroupId: PropTypes.string,
};

export default UIView('MarketGroupSelector', MarketGroupSelectorImpl, propTypes);
