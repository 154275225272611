import { range } from 'mojito/utils';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SkeletonPart from 'presentation/components/skeleton-part/index.jsx';

export default function MarketGroupSelectorSkeleton(props) {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.container}>
            <FlexPane config={config.marketSwitcherContainer}>
                {range(config.numberOfMarketSwitchers).map(index => (
                    <SkeletonPart key={index} config={config.itemPlaceholder} />
                ))}
            </FlexPane>
        </FlexPane>
    );
}
