/**
 * RaceExplanationPane types.
 *
 * @class RaceExplanationPane
 * @name types
 * @memberof Mojito.Presentation.Components.RaceExplanationPane
 */
export default class RaceExplanationPaneTypes {}

/**
 * Race explanation pane items.
 *
 * @readonly
 * @enum ITEM_TYPES
 * @memberof Mojito.Presentation.Components.RaceExplanationPane.types
 */
RaceExplanationPaneTypes.ITEM_TYPES = {
    RESULT: 'RESULT',
    RACE_OFF: 'RACE_OFF',
    BEST_ODDS: 'BEST_ODDS',
    PRICED: 'PRICED',
};
