/**
 * Statscore tracker states.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Components.Statscore
 */
export const STATE = {
    INITIALIZING: 'INITIALIZING',
    INITIALIZED: 'INITIALIZED',
    UNAVAILABLE: 'UNAVAILABLE',
};
