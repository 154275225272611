import MojitoCore from 'mojito/core';
import ExpandableView from 'presentation/components/expandableview/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SelectableButton from 'presentation/components/selectable-button/index.jsx';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class OddSelector extends UIViewImplementation {
    constructor(component) {
        super(component);
    }

    getSelectedOddsKey() {
        const { oddsFormats, selectedOddsFormat } = this.props;
        return Object.keys(oddsFormats).find(key => oddsFormats[key] === selectedOddsFormat);
    }

    buildOddsList() {
        const { oddsFormats, onOddsFormatChanged } = this.props;

        const getOnOddsItemClick = oddsItemKey => () =>
            onOddsFormatChanged(oddsFormats[oddsItemKey]);

        const selectedOdds = this.getSelectedOddsKey();

        return Object.keys(oddsFormats).map(odds => {
            return (
                <SelectableButton
                    class={`ta-${odds}`}
                    onClick={getOnOddsItemClick(odds)}
                    key={odds}
                    selected={odds === selectedOdds}
                    config={this.config.oddsItem}
                >
                    {this.resolveString(`$ODDS_SELECTOR.${odds}`)}
                </SelectableButton>
            );
        });
    }

    render() {
        const oddsItems = this.buildOddsList();

        if (oddsItems.length === 0) {
            return null;
        }

        return (
            <ExpandableView
                headerText={this.resolveString(`$ODDS_SELECTOR.${this.getSelectedOddsKey()}`)}
                config={this.config.expandable}
                class="ta-OddsSelector"
            >
                <FlexPane config={this.config.oddsList} class="ta-oddsList">
                    {oddsItems}
                </FlexPane>
            </ExpandableView>
        );
    }
}
