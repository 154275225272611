import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectOddsFormat } from 'services/user-settings/selectors.js';
import { actions as betslipActions } from 'services/betslip/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import dataRetriever from 'services/bets/data-retriever.js';
import {
    selectDataHash,
    selectIsPending,
    selectIsRequested,
    selectBets,
    selectBet,
    selectCashoutableBets,
    selectCashoutStatus,
} from './open-bets-selectors.js';
import ServicesTypes from 'services/common/types.js';
import BetsTypes from 'services/bets/types.js';
import { noop, pick } from 'mojito/utils';
import Utils from 'services/bets/utils.js';

const { isShallowEqual } = MojitoCore.Base.objUtils;
const { CONTENT_STATE } = ServicesTypes;
const reduxInstance = MojitoCore.Services.redux;
const log = MojitoCore.logger.get('openBetsSlice');
const { CASHOUT_STATUS } = BetsTypes;
const confirmationRequiredStatuses = new Set([CASHOUT_STATUS.IDLE, CASHOUT_STATUS.FAILED]);

/**
 * The name of the open bets store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Bets
 */
const STORE_KEY = 'openBetsStore';

const betsInitState = {
    bets: [],
    totalNumberOfBets: 0,
    loadingStatus: CONTENT_STATE.UNKNOWN,
    dataHash: undefined,
};

const cashoutInitState = {
    cashoutInfos: {},
    lockedAmounts: {},
    cashoutStatuses: {},
    isCashoutOffersRequested: false,
    allowCashout: false,
    cashoutRulesStatuses: {},
};

/**
 * Defines the state of the open bets store.
 *
 * @typedef OpenBetsState
 * @type {object}
 * @property {Array} bets - Bets.
 * @property {number} totalNumberOfBets - Total number of bets.
 * @property {Mojito.Services.Common.types.CONTENT_STATE} loadingStatus - Loading state for open bets.
 * @property {boolean} isRequested - True if open bets were requested (even without user is being logged in).
 * It means that after login success we need to fetch open bets immediately instead of open bets count.
 * @property {string} dataHash - Hash object for open bet response.
 *
 * @memberof Mojito.Services.Bets
 */
const initialState = {
    ...betsInitState,
    ...cashoutInitState,
    isRequested: false,
};

const reducers = {
    reset() {
        return { ...initialState };
    },
    resetBestState(state) {
        return { ...state, ...betsInitState };
    },
    resetRequested(state) {
        state.isRequested = false;
    },
    startFetching(state) {
        state.loadingStatus = CONTENT_STATE.PENDING;
    },
    requestOpenBets(state) {
        state.isRequested = true;
    },
    fetchOpenBetsSuccess(state, { payload }) {
        const { bets = [], pagination = { totalItems: 0 }, dataHash } = payload;
        if (!dataHash || state.dataHash !== dataHash) {
            state.dataHash = dataHash;
            state.bets = bets;
        }
        state.totalNumberOfBets = pagination.totalItems;
        state.loadingStatus = CONTENT_STATE.AVAILABLE;
    },
    fetchOpenBetsFailed(state) {
        state.loadingStatus = CONTENT_STATE.AVAILABLE;
    },
    fetchOpenBetCountSuccess(state, { payload }) {
        state.totalNumberOfBets = payload.pagination.totalItems;
    },
    configure(state, { payload }) {
        state.allowCashout = Boolean(payload?.allowCashout);
    },
    discardCashoutOffers(state) {
        state.cashoutInfos = {};
        state.lockedAmounts = {};
        state.cashoutStatuses = {};
        state.isCashoutOffersRequested = false;
    },
    requestCashoutOffersStarted(state, { payload: betIds }) {
        state.isCashoutOffersRequested = true;
        if (betIds) {
            state.cashoutInfos = pick(state.cashoutInfos, betIds);
        }
    },
    cashoutOffersSuccess(state, { payload: { cashoutInfos } }) {
        // Cashout infos can come in a small chunks, so we just merge them in.
        cashoutInfos.forEach(newInfo => {
            const info = state.cashoutInfos[newInfo.betId];
            if (!isShallowEqual(info, newInfo)) {
                state.cashoutInfos[newInfo.betId] = newInfo;
            }
        });
    },
    cashoutOffersFailed: noop,
    confirmCashout(state, { payload }) {
        state.cashoutStatuses[payload.betId] = CASHOUT_STATUS.CONFIRMATION;
        state.lockedAmounts[payload.betId] = payload.amount;
    },
    startCashout(state, { payload: betId }) {
        state.cashoutStatuses[betId] = CASHOUT_STATUS.PENDING;
    },
    cashoutSuccess(state, { payload: cashoutInfo }) {
        const { betId, errors } = cashoutInfo;
        const status = errors?.length ? CASHOUT_STATUS.FAILED : CASHOUT_STATUS.ACCEPTED;
        state.cashoutStatuses[betId] = status;
        if (status === CASHOUT_STATUS.ACCEPTED) {
            state.lockedAmounts[betId] = cashoutInfo.amount;
        } else {
            cashoutInfo.amount = state.cashoutInfos[betId].amount;
            cashoutInfo.availability = state.cashoutInfos[betId].availability;
        }
        state.cashoutInfos[betId] = cashoutInfo;
    },
    cashoutFailed(state, { payload: betId }) {
        state.cashoutStatuses[betId] = CASHOUT_STATUS.FAILED;
    },
    enableCashout(state) {
        state.allowCashout = true;
    },
    disableCashout(state) {
        state.allowCashout = false;
    },
    addAutoCashoutRule(state, { payload }) {
        state.cashoutRulesStatuses[payload.betId] = {
            progress: {
                status: BetsTypes.AUTO_CASHOUT_UPDATE_STATUS.PENDING,
                type: BetsTypes.AUTO_CASHOUT_UPDATE_TYPE.CREATE,
            },
        };
    },
    addAutoCashoutRuleSuccess(state, { payload }) {
        const { betId, amount } = payload;
        state.cashoutInfos[betId].autoCashout.triggerAmount = amount;
        state.cashoutRulesStatuses[betId] = {
            progress: {
                status: BetsTypes.AUTO_CASHOUT_UPDATE_STATUS.SUCCEEDED,
                type: BetsTypes.AUTO_CASHOUT_UPDATE_TYPE.CREATE,
            },
            amount,
        };
    },
    addAutoCashoutRuleFailed(state, { payload }) {
        const { betId, error } = payload;
        state.cashoutRulesStatuses[betId] = {
            progress: {
                status: BetsTypes.AUTO_CASHOUT_UPDATE_STATUS.FAILED,
                type: BetsTypes.AUTO_CASHOUT_UPDATE_TYPE.CREATE,
                message: error.messages && error.messages[0],
            },
        };
    },
    removeAutoCashoutRule(state, { payload: betId }) {
        state.cashoutInfos[betId].autoCashout.triggerAmount = 0;
        state.cashoutRulesStatuses[betId] = {
            progress: {
                status: BetsTypes.AUTO_CASHOUT_UPDATE_STATUS.PENDING,
                type: BetsTypes.AUTO_CASHOUT_UPDATE_TYPE.REMOVE,
            },
        };
    },
    removeAutoCashoutRuleSuccess(state, { payload: betId }) {
        state.cashoutRulesStatuses[betId] = {
            progress: {},
            amount: 0,
        };
    },
    removeAutoCashoutRuleFailed(state, { payload }) {
        const { betId, error } = payload;
        state.cashoutRulesStatuses[betId] = {
            progress: {
                status: BetsTypes.AUTO_CASHOUT_UPDATE_STATUS.FAILED,
                type: BetsTypes.AUTO_CASHOUT_UPDATE_TYPE.REMOVE,
                message: error.messages && error.messages[0],
            },
        };
    },
    clearAutoCashoutRuleError(state, { payload: betId }) {
        state.cashoutRulesStatuses[betId] = { progress: {} };
    },
};

const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState,
    reducers,
});

actions.fetchOpenBets = () => (dispatch, getState) => {
    dispatch(actions.requestOpenBets());
    const state = getState();
    if (isLoggedIn(state)) {
        dispatch(actions.startFetching());
        if (dataRetriever.allowOpenBetsPolling()) {
            dataRetriever.stopPollingNumberOfOpenBets();
            dataRetriever.refreshOpenBets(() => selectDataHash(getState()));
        } else if (!selectIsPending(state)) {
            const oddsFormat = selectOddsFormat(state);
            dataRetriever.fetchOpenBets(oddsFormat);
        }
    }
};

actions.fetchOpenBetCount = () => (dispatch, getState) => {
    const state = getState();
    if (isLoggedIn(state)) {
        if (dataRetriever.allowOpenBetsPolling()) {
            dataRetriever.stopPollingNumberOfOpenBets();
            dataRetriever.pollNumberOfOpenBets();
        } else if (!selectIsPending(state)) {
            dataRetriever.getNumberOfOpenBets();
        }
    }
};

actions.fetchOpenBetCountFailed = error => () => {
    log.warn('Open bet count fetch failed', error);
};

actions.discardOpenBets = () => (dispatch, getState) => {
    const state = getState();
    if (isLoggedIn(state)) {
        dispatch(actions.fetchOpenBetCount());
    }
    dispatch(actions.resetRequested());
};

actions.requestCashoutOffers = betIds => (dispatch, getState) => {
    const state = getState();
    const betInfos = selectCashoutableBets(betIds, state).map(Utils.buildBetInfo);
    if (betInfos.length > 0) {
        dataRetriever.requestCashoutOffers(betInfos);
        dispatch(actions.requestCashoutOffersStarted(betIds));
    }
};

actions.cashout =
    ({ betId, amount }) =>
    (dispatch, getState) => {
        const state = getState();
        const status = selectCashoutStatus(betId, state);
        if (confirmationRequiredStatuses.has(status)) {
            dispatch(actions.confirmCashout({ betId, amount }));
        } else {
            const bet = selectBet(betId, state);
            if (bet) {
                dispatch(actions.startCashout(betId));
                dataRetriever.cashout(Utils.buildBetInfo(bet), amount);
            } else {
                log.warn(
                    `Can not create bet info for bet with id: ${betId}. The bet is not in open bets.`
                );
            }
        }
    };

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    effect: (_, listenerApi) => {
        listenerApi.dispatch(actions.reset());
        dataRetriever.stopRefreshingOpenBets();
        dataRetriever.stopPollingNumberOfOpenBets();
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.disposeSession,
    effect: (_, listenerApi) => {
        listenerApi.dispatch(actions.resetBestState());
        dataRetriever.stopRefreshingOpenBets();
        dataRetriever.stopPollingNumberOfOpenBets();
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (_, listenerApi) => {
        const state = listenerApi.getState();
        const action = selectIsRequested(state)
            ? actions.fetchOpenBets()
            : actions.fetchOpenBetCount();
        listenerApi.dispatch(action);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.placeBetslipSuccess,
    effect: (_, listenerApi) => {
        const state = listenerApi.getState();
        if (isLoggedIn(state)) {
            listenerApi.dispatch(actions.fetchOpenBetCount());
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.fetchOpenBetsFailed,
    effect: action => {
        log.warn('Open bets fetch failed', action.payload);
    },
});

// ---------------------- cashout ---------------------//

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(
        bootstrapActions.dispose,
        authenticationActions.disposeSession,
        actions.disableCashout
    ),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.discardCashoutOffers());
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.discardCashoutOffers,
    effect: () => {
        dataRetriever.discardCashoutOffers();
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.cashoutOffersFailed,
    effect: action => {
        log.warn('cashoutOffersFailed', action.payload);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.requestCashoutOffers);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.fetchOpenBetsSuccess,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.requestCashoutOffers);
        const bets = selectBets(listenerApi.getState());
        if (bets.length === 0) {
            listenerApi.dispatch(actions.discardCashoutOffers());
        } else {
            const betIds = bets.map(({ id }) => id);
            listenerApi.dispatch(actions.requestCashoutOffers(betIds));
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.addAutoCashoutRule,
    effect: (action, listenerApi) => {
        const { betId, amount } = action.payload;
        const state = listenerApi.getState();
        const bet = selectBet(betId, state);
        if (bet) {
            dataRetriever.addAutoCashoutRule(Utils.buildBetInfo(bet), amount);
        } else {
            log.warn(
                `Can not create bet info for bet with id: ${betId}. The bet is not in open bets.`
            );
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.removeAutoCashoutRule,
    effect: (action, listenerApi) => {
        const betId = action.payload;
        const state = listenerApi.getState();
        const bet = selectBet(betId, state);
        if (bet) {
            dataRetriever.removeAutoCashoutRule(Utils.buildBetInfo(bet));
        } else {
            log.warn(
                `Can not create bet info for bet with id: ${betId}. The bet is not in open bets.`
            );
        }
    },
});

/**
 * Open bets actions.
 *
 * @class OpenBetsActions
 * @name actions
 * @memberof Mojito.Services.Bets.openBetsSlice
 */

/**
 * Reset store.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Reset store state part related to open bets.
 *
 * @function resetBestState
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Reset "open bets were requested" flag.
 *
 * @function resetRequested
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Start open bets fetching.
 *
 * @function startFetching
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Request open bets. This means user's intention to see open bets, even without being logged in.
 * So after logging in, open bets need to be shown immediately, not just open bet count as usual.
 *
 * @function requestOpenBets
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Open bets have been fetched successfully.
 *
 * @function fetchOpenBetsSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Bets.types.OpenBetsResponse} payload - Open bets response.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Open bet count have been received successfully.
 *
 * @function fetchOpenBetCountSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{pagination: Mojito.Services.Bets.types.Pagination}} payload - Pagination information.
 * @memberof Mojito.Services.Betslip.openBetsSlice.actions
 */

/**
 * Fetching of open bets has failed.
 *
 * @function fetchOpenBetsFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Core.Services.Transactions.types.Error} payload - Error.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Fetch open bets.
 *
 * @function fetchOpenBets
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Fetch open bet count.
 *
 * @function fetchOpenBetCount
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Fetching of open bet count has failed.
 *
 * @function fetchOpenBetCountFailed
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Error.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Discard open bet information. Meaning it is not needed any more.
 * If user logged in, fetch open bet count instead.
 *
 * @function discardOpenBets
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Error.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Configure store.
 *
 * @function configure
 * @param {{allowCashout: boolean}} payload - Configuration.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Discard cashout offers.
 *
 * @function discardCashoutOffers
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Request cashout offers has started.
 *
 * @function requestCashoutOffersStarted
 * @param {betIds|undefined} betIds - Bet IDs.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Cashout offers received successfully.
 *
 * @function cashoutOffersSuccess
 * @param {{cashoutInfos}} payload - Cashout offers.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Cashout offers fetching has failed.
 *
 * @function cashoutOffersFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Confirm cashout.
 *
 * @function confirmCashout
 * @param {{betId: string, amount: number}} payload - Bet ID and cashout amount.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Start cashout (after confirmation).
 *
 * @function startCashout
 * @param {string} betId - Bet ID.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Cashout has been performed successfully.
 *
 * @function cashoutSuccess
 * @param {Mojito.Services.Bets.types.CashoutInfo} cashoutInfo - Cashout info.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Cashout has failed.
 *
 * @function cashoutFailed
 * @param {string} betId - Bet ID.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Enable cashout.
 *
 * @function enableCashout
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Disable cashout.
 *
 * @function disableCashout
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Create auto cashout rule.
 *
 * @function addAutoCashoutRule
 * @param {{betId: string, amount: number}} payload - Bet ID and amount.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Auto cashout rule has been created successfully.
 *
 * @function addAutoCashoutRuleSuccess
 * @param {{betId: string, amount: number}} payload - Bet ID and amount.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Auto cashout rule creation has failed.
 *
 * @function addAutoCashoutRuleFailed
 * @param {{betId: string, error: Mojito.Core.Services.Transactions.types.Error}} payload - Bet ID and amount.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Remove auto cashout rule.
 *
 * @function removeAutoCashoutRule
 * @param {string} betId - Bet ID.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Auto cashout rule has been removed successfully.
 *
 * @function removeAutoCashoutRuleSuccess
 * @param {string} betId - Bet ID.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Auto cashout removal rule has failed.
 *
 * @function removeAutoCashoutRuleFailed
 * @param {{betId: string, message: string}} payload - Bet ID and message.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Clear auto cashout error.
 *
 * @function clearAutoCashoutRuleError
 * @param {string} betId - Bet ID.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Request cashout offers.
 *
 * @function requestCashoutOffers
 * @param {Array<string>|undefined} betIds - Bets IDs.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

/**
 * Request cashout offers.
 *
 * @function cashout
 * @param {{betId: string, amount: number}} payload - Bet ID and amount.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.openBetsSlice.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);

export { reducer, actions, STORE_KEY, initialState };
