import PropTypes from 'prop-types';
import DialogImpl from './dialog.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View that displays a dialog.
 * The Dialog should always be "rendered", even when not intended to be visible.
 * The mandatory prop `isOpen` manages the visibility and enables smooth enter/exit animations.
 *
 * @class Dialog
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Dialog` component prop types.
 *
 * @property {node|Array<node>} [children] - The content to display in the dialog.
 * @property {string} [header] - Text string to display in the dialog's header.
 * @property {node} [headerComponent] - Header component to use in the header of the dialog. Takes precedence over the header string, if provided.
 * @property {node} [footerComponent] - The content to display in the dialog's footer.
 * @property {boolean} [disabled = false] - Boolean to control if the dialog should be interactive or not.
 * @property {Function} [onClickOutside = ()=>{}] - Callback to be invoked when clicking outside the dialog.
 * @property {Function} [onCloseButtonClick] - Callback to be invoked the close button gets clicked. If not provided, the close button will not be shown.
 * @property {boolean} isOpen - Boolean to control if the dialog should be visible or not.
 *
 * @memberof Mojito.Presentation.Components.Dialog
 */
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    header: PropTypes.string,
    headerComponent: PropTypes.node,
    footerComponent: PropTypes.node,
    disabled: PropTypes.bool,
    onClickOutside: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
    disabled: false,
    onClickOutside: noop,
};

export default UIView('Dialog', DialogImpl, propTypes, defaultProps);
