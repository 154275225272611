import PropTypes from 'prop-types';
import BetradarMatchTrackerImpl from './betradar-match-tracker.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Encapsulates Sportradar's widgets in a Mojito/React component.
 *
 * @example <caption>Configure the widgetLoaderUrl parameter</caption>
 * The SIR widget loader URL normally looks likes this: 'https://widgets.sir.sportradar.com/<clientId>/widgetloader', where the <clientId> should be replaced with the Sportradar client ID.
 *
 * Configuration in 'get-views-config.js' (clientId is not a real one):
 *
 * BetradarMatchTracker: {
 *     widgetLoaderUrl: 'https://widgets.sir.sportradar.com/2a4b22a4b22a4b22a4b22a4b22a4b2e1/widgetloader'
 * }
 *
 * @example <caption>Configure the SIR global options</caption>
 * The widgetLoaderConfig configuration options can be found here: 'https://widgets.sir.sportradar.com/docs/SIR.html'.
 *
 * BetradarMatchTracker: {
 *     clientId: '2a4b22a4b22a4b22a4b22a4b22a4b2e1',
 *     widgetLoaderConfig: {
 *         theme: 'betradar',
 *         clockType: '12'
 *     }
 * }
 *
 * The 'language' option is configurable through 'languagesMapping' property in config. There is no default mapping from Mojito side.
 * If mapping was configured the language is chosen depending on the language used in Mojito.
 * If the language is not available, the language defined in the fallbackLanguage option is used.
 * List of available languages: 'https://widgets.sir.sportradar.com/docs/Languages.html' or see hardcoded list in config under "supportedLanguages" property.
 * Regarding regional languages, betradar supports only two: "en_us" and "es_la". For other regional languages we set only country code, if no mapping present.
 * E.g., "en-GB" is shortened to "en".
 *
 * @example <caption>Configure default betradar widget parameters (Live Match Tracker Plus widget, the available options can be found on 'https://widgets.sir.sportradar.com/docs/Widgets.match.LMTPlus.html')</caption>
 * This component can be customized using betradar widgets options.
 *
 * BetradarMatchTracker: {
 *     widgetConfig: {
 *         properties: {
 *             layout: 'double',
 *             momentum: 'timeline',
 *             scoreboard: 'extended'
 *         }
 *     }
 * }
 *
 * @example <caption>Configure the Live Match Tracker Plus widget 'tabs' parameter</caption>
 *
 * BetradarMatchTracker: {
 *     widgetConfig: {
 *         properties: {
 *             tabs: {
 *                 1: ['statistics']
 *             }
 *         }
 *     }
 * }
 *
 * The 1 stands for the sportId, in this case football. There are no documentation over which sports are mapped to what integer, but it can be
 * figured out by going to https://widgets.sir.sportradar.com/live-match-tracker and selecting the sport you want. Then look in the URL
 * and you can see sportId:X, where X is the integer.
 *
 * @example <caption>Configure widget parameters with function used to resolve widget configs with (headToHead widget, the available options can be found on 'https://widgets.sir.sportradar.com/docs/Widgets.headToHead.Button.html')</caption>
 *
 * BetradarMatchTracker: {
 *     widgetConfigResolver: (event, appMode) => {
 *         if(event) {
 *             return {
 *                 name: 'headToHead.standalone',
 *                 properties: {
 *                     layout: 'inline'
 *                 }
 *             }
 *         }
 *     }
 * }
 *
 * @class BetradarMatchTracker
 * @name component
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker
 */

/**
 * BetradarMatchTracker component prop types.
 *
 * @property {object} event - Event object.
 * @property {Function} [onChange => ()=>{}] - Callback, triggered when the state of the BetradarMatchTracker component changes.
 *
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker
 */
const propTypes = {
    event: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

const defaultProps = {
    onChange: noop,
};

export default UIView('BetradarMatchTracker', BetradarMatchTrackerImpl, propTypes, defaultProps);
