import PropTypes from 'prop-types';
import VideoPlayerImpl from './video-player.jsx';
import MojitoCore from 'mojito/core';
import { STATE } from './types';
import { noop } from 'mojito/utils';

/**
 * This component is responsible for playing video from a given source in a platform and streaming protocol agnostic way.
 *
 * It is a wrapper around the [Video.js](https://videojs.com/) player, which adds Mojito specific UI and error handling.
 *
 * The only supported streaming protocol at the moment is [HLS](https://en.wikipedia.org/wiki/HTTP_Live_Streaming).
 *
 * @class VideoPlayer
 * @memberof Mojito.Presentation.Components
 */

/**
 * VideoPlayer prop types.
 *
 * @property {string} [src] - URL to the media source that should be shown by the video player.
 * @property {Mojito.Presentation.Components.VideoPlayer.STATE} [targetState = STOPPED] - The state that
 * the video player should strive to achieve. Setting targetState to PLAYING for instance,
 * will cause the player to start loading content and then try to play it. The actual
 * (internal) state of the player might differ from the target state.
 * @property {boolean} [allowFullscreen = true] - True if full screen is allowed, false otherwise.
 * @property {boolean} [hasEnded] - When flag is true, the streamEndedOverlay will cover the player.
 * @property {Function} [cbClose = noop] - Callback called when the user wants to close the video player.
 * @property {Function} [cbError = noop] - Callback called on error occurring in the video player.
 * @property {Function} [cbErrorOverlayClick = noop] - Callback called when the user clicks the error overlay.
 * @property {Function} [cbFullScreenChanged = noop] - Callback called when the video player full-screen state changes.
 * @property {Function} [cbMuteChanged = noop] - Callback called when the video is muted or unmuted.
 * @property {Function} [cbVolumeChanged = noop] - Callback called when the sound volume is changed.
 * @property {Function} [cbStreamPlaying = noop] - Callback called when stream begins to play.
 *
 *
 * @memberof Mojito.Presentation.Components.VideoPlayer
 */
const propTypes = {
    src: PropTypes.string,
    targetState: PropTypes.oneOf(Object.values(STATE)),
    allowFullscreen: PropTypes.bool,
    hasEnded: PropTypes.bool,
    cbClose: PropTypes.func,
    cbError: PropTypes.func,
    cbErrorOverlayClick: PropTypes.func,
    cbFullScreenChanged: PropTypes.func,
    cbMuteChanged: PropTypes.func,
    cbVolumeChanged: PropTypes.func,
    cbStreamPlaying: PropTypes.func,
};

const defaultProps = {
    targetState: STATE.STOPPED,
    allowFullscreen: true,
    cbClose: noop,
    cbError: noop,
    cbErrorOverlayClick: noop,
    cbFullScreenChanged: noop,
    cbMuteChanged: noop,
    cbVolumeChanged: noop,
    cbStreamPlaying: noop,
};

const VideoPlayer = MojitoCore.Presentation.UIView(
    'VideoPlayer',
    VideoPlayerImpl,
    propTypes,
    defaultProps
);

VideoPlayer.Types = {
    STATE,
};

export default VideoPlayer;
