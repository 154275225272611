import MojitoNGen from 'mojito/ngen';

const repository = MojitoNGen.repository;
const log = MojitoNGen.logger.get('DataProvider');

/**
 *
 * Abstract class offering generic functions for subscribing to data.
 *
 * @class DataProvider
 * @abstract
 * @memberof Mojito.Core.Services
 */
export default class DataProvider {
    /**
     * Subscribe to the specified entity.
     *
     * @param {string} id - The entity id.
     * @param {Function} onUpdate - Function called when the requested data is updated.
     * @param {string} collectionName - Repository collection name.
     *
     * @returns {object} Subscription that must be disposed when no longer needed.
     *
     * @function Mojito.Core.Services.DataProvider#subscribeToEntity
     */
    subscribeToEntity(id, onUpdate, collectionName) {
        let subscription;
        const promise = new Promise((resolve, reject) => {
            subscription = repository.subscribe(collectionName, id, function (data) {
                onUpdate(data);
            });
            subscription.promise.then(function (initialData) {
                onUpdate(initialData);
                resolve(initialData);
            }, reject);
        });

        return {
            promise: promise,
            dispose: function () {
                subscription.dispose();
            },
        };
    }

    /**
     * Subscribe to the multiple entities.
     *
     * @param {Array<string>} ids - The entities id list.
     * @param {Function} onInit - Function called when the requested data was fetched after subscription resolved.
     * @param {Function} onUpdate - Function called when the requested data is updated.
     * @param {string} collectionName - Repository collection name.
     *
     * @returns {object} Composite subscription object that contains nested subscriptions for each item.
     *
     * @function Mojito.Core.Services.DataProvider#subscribeToEntities
     */
    subscribeToEntities(ids, onInit, onUpdate, collectionName) {
        const subscription = repository.subscribeMultiple(collectionName, ids, onUpdate);
        subscription.promise.then(onInit).catch(e => {
            log.error(`Error in subscribeMultiple for ${collectionName} collection.\n`, e);
        });
        return subscription;
    }

    /**
     * Retrieves a specific entity from the repository using the repository's get method.
     * This function should only be used when the data is expected to be already present in the repository.
     *
     * @param {string} id - The id of the entity.
     * @param {Function} setter - A function that is called when the requested data is updated.
     * @param {string} collectionName - The name of the repository collection.
     *
     * @returns {void} This function doesn't return a value.
     *
     * @function Mojito.Core.Services.DataProvider#getEntity
     */
    getEntity(id, setter, collectionName) {
        repository
            .get(collectionName, id)
            .then(data => {
                setter(data);
            })
            .catch(() => {
                log.warn('Failed to get entity: ', id, ' from collection: ', collectionName);
            });
    }
}
