import PropTypes from 'prop-types';
import LanguageSelectorImpl from './language-selector.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Language Selector view.
 *
 * @class LanguageSelector
 * @memberof Mojito.Presentation.Components
 */

/**
 * `LanguageSelector` component prop types.
 *
 * @property {Array} languages - An array of available languages, represented as objects like `{name:'xyz', languageCode:'x'}`.
 * @property {string} selectedLanguageCode - The code of the currently selected language.
 * @property {Function} onLanguageChange - Callback function that is triggered when a language is selected. The newly selected language is passed as a parameter.
 *
 * @memberof Mojito.Presentation.Components.LanguageSelector
 */
const propTypes = {
    languages: PropTypes.array.isRequired,
    selectedLanguageCode: PropTypes.string.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
};

export default UIView('LanguageSelector', LanguageSelectorImpl, propTypes);
