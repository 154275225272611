import { useCallback, useMemo } from 'react';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import BannerCard from 'modules/event-cards/banner/index.jsx';
import MatchCard from 'modules/event-cards/match/index.jsx';
import OutrightCard from 'modules/event-cards/outright/index.jsx';
import FallbackCard from 'modules/event-cards/fallback/index.jsx';
import SelectionCard from 'modules/event-cards/selection/index.jsx';
import { usePromotionsLoadDone } from 'modules/common/hooks/index.js';
import { useCardsPromotions } from './hooks';
import { usePromotionsPreload, usePromotions } from 'modules/promotions/hooks';
import EventCardsCarouselUtils from './utils.js';
import { isEmpty } from 'mojito/utils';
import EventCardsSkeleton from 'modules/event-cards/skeleton/index.jsx';
import EventCardsTypes from 'modules/event-cards/types.js';

const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const { Swiper, LoaderSuspense } = MojitoPresentation.Components;
const { useIrreversibleTrue } = MojitoPresentation.Hooks;
const { FALLBACK, MATCH, BANNER, OUTRIGHT, SELECTION } = EventCardsTypes.CARD_TYPE;
const { INPLAY, SPORT } = EventCardsTypes.FALLBACK_CONTENT_TYPE;
const { enrichWithFallbackCards } = EventCardsCarouselUtils;

const log = MojitoCore.logger.get('EventCardsCarousel');

const createFallbackCard = type => ({
    id: type,
    type: FALLBACK,
    content: { type },
});

const fallbackCards = [INPLAY, SPORT].map(createFallbackCard);

const analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('EventCards').build();

export default function EventCardsCarousel(props) {
    const {
        mojitoTools: { config, appContext, instanceId },
        pageId,
        sportId,
    } = props;

    const { emitAnalytics } = appContext.analyticsEmitter;

    const { locationId, minCardsNumber } = config;

    const onSlideChange = useCallback(
        () => emitAnalytics('eventCardsCarouselSwiped'),
        [emitAnalytics]
    );

    const cardPromotions = usePromotions(locationId, pageId);
    const cards = useCardsPromotions(cardPromotions);

    const isContentLoaded = usePromotionsLoadDone([locationId]);

    const [preloadDone, onPrepareSlidesToRender] = usePromotionsPreload(
        cardPromotions,
        instanceId,
        isContentLoaded
    );

    const cardRenderersByType = useMemo(
        () => createCardRenderer(sportId, preloadDone, config),
        [sportId, preloadDone, config]
    );

    const cardsToRender = enrichWithFallbackCards(cards, minCardsNumber, fallbackCards);

    const noContent = isContentLoaded && isEmpty(cardsToRender);

    if (noContent) {
        return null;
    }

    const skeleton = <EventCardsSkeleton config={config.eventCardsSkeleton} />;

    return (
        <LoaderSuspense
            config={config.skeletonLoader}
            isContentPending={!isContentLoaded}
            loader={skeleton}
        >
            {!isEmpty(cardsToRender) ? (
                <AnalyticsContextExtender value={analyticsContext}>
                    {
                        <Swiper
                            config={config.swiper}
                            onSlideChange={onSlideChange}
                            onPrepareSlidesToRender={onPrepareSlidesToRender}
                        >
                            {cardsToRender
                                // eslint-disable-next-line react/display-name
                                .map(card => readyToLoad => (
                                    <Card
                                        key={card.id}
                                        readyToLoad={readyToLoad}
                                        card={card}
                                        config={config}
                                        renderer={cardRenderersByType[card.type]}
                                    />
                                ))}
                        </Swiper>
                    }
                </AnalyticsContextExtender>
            ) : null}
        </LoaderSuspense>
    );
}

const createCardRenderer = (sportId, dataPreloadDone, config) => {
    const renderBannerCard = ({ id, content }) => {
        return (
            <BannerCard
                key={id}
                imgUrl={content.imgUrl}
                targetUrl={content.url}
                openInNewTab={content.openInNewTab}
            />
        );
    };

    const renderFallbackCard = ({ id, content }) => {
        return (
            <FallbackCard
                key={id}
                type={content.type}
                sportId={sportId ?? config.fallbackSportId}
            />
        );
    };

    const renderOutrightCard = ({ id, content }) => {
        return (
            <OutrightCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                shouldRequestData={dataPreloadDone}
            />
        );
    };

    const renderSelectionCard = ({ id, content }) => {
        return (
            <SelectionCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                selectionId={content.selectionId}
                marketName={content.marketName}
                selectionName={content.selectionName}
                shouldRequestData={dataPreloadDone}
            />
        );
    };

    const renderMatchCard = ({ id, content }) => {
        return (
            <MatchCard
                key={id}
                eventId={content.eventId}
                marketId={content.marketId}
                marketName={content.marketName}
                gameLine={content.gameLine}
                marketLines={content.marketLines}
                shouldRequestData={dataPreloadDone}
            />
        );
    };
    return {
        [MATCH]: renderMatchCard,
        [OUTRIGHT]: renderOutrightCard,
        [BANNER]: renderBannerCard,
        [FALLBACK]: renderFallbackCard,
        [SELECTION]: renderSelectionCard,
        ...config.cardRenderersByType,
    };
};

const Card = props => {
    const { card, readyToLoad, renderer } = props;
    // The cards are lazy loadable. Once user is swiping the cards the swiper will inform us that card is approaching the view port.
    // Swiper will send readyToLoad:true prop in this case. Once it is true we will render a card.
    // Each card is self-sufficient module. It fetches needed content and presents it.
    // Until content fetch is pending the card might show event card skeleton.
    // The aim is not to fetch content until user is intended to see the card.
    const shouldShowCard = useIrreversibleTrue(readyToLoad);
    if (!renderer) {
        log.error(`Can not find renderer for '${card.type}' event card`);
        return null;
    }
    return shouldShowCard && renderer(card);
};
