/**
 * @class PerformanceTypes
 * @name types
 * @memberof Mojito.Services.Performance
 */

/**
 * Performance metrics record.
 *
 * @typedef PerformanceRecord
 * @type {object}
 * @property {string} url - Page url.
 * @property {string} version - Mojito version.
 * @property {number} loadingTime - Time that was taken to fetch the required data and render the page (in seconds).
 * @property {string} username - User name.
 * @property {Mojito.Core.Services.SystemSettings.types.APPLICATION_MODE} mode - Application mode.
 * @property {boolean} initialLoading - True if it is a first page that user visited.
 * @property {number} wsSubscriptions - The number of subscriptions that were made to retrieve the data required to render the page.
 * @property {number} wsTransferredDataSize - The size of the data that was transferred via websockets (in kilobytes).
 * @property {number} wsLatencyAvg - Avarage value of websocket latency (in seconds).
 * @property {number} wsLatencyMax - The biggest websocket latency value (in seconds).
 * @property {number} wsLatencyMin - The smallest websocket latency value (in seconds).
 * @property {string} browserName - Browser name.
 * @property {string} browserVersion - Browser version.
 * @property {string} os - Operating system name and version.
 *
 * @memberof Mojito.Services.Performance.types
 */

/**
 * Performance service config object.
 *
 * @typedef ServiceConfig
 * @type {object}
 * @property {Array<Mojito.Services.Performance.AbstractPerformanceLogger>} loggers - Array of performance logger.
 */

/**
 * Service factory config object.
 *
 * @typedef FactoryConfig
 * @type {object}
 * @property {Mojito.Services.Performance.AbstractPerformanceService} service - Performance service instance.
 * @property {Mojito.Services.Performance.types.ServiceConfig} serviceConfig - Performance service config.
 */

/**
 * Timeline record types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Performance.types
 */
const TIMELINE_RECORD_TYPES = {
    NAVIGATION: 'navigation',
    MODULE_RENDERED: 'moduleRendered',
    WS_MESSAGE: 'wsMessage',
};

/**
 * Data aggregation types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Performance.types
 */
const DATA_TO_AGGREGATE = {
    WS_SUBSCRIPTIONS: 'wsSubscriptions',
    WS_TRANSFERRED_DATA: 'wsTransferredDataSize',
    WS_LATENCY_AVERAGE: 'wsLatencyAvg',
    WS_LATENCY_MIN: 'wsLatencyMin',
    WS_LATENCY_MAX: 'wsLatencyMax',
};

export default { TIMELINE_RECORD_TYPES, DATA_TO_AGGREGATE };
