import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('IframeViewer');
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

const replaceAll = (str = '', mapObj = {}, wrapper = '', flags = 'g') => {
    const regex = new RegExp(`${wrapper}(${Object.keys(mapObj).join('|')})${wrapper}`, flags);
    return str.replace(
        regex,
        match => mapObj[wrapper.length ? match.slice(wrapper.length, -wrapper.length) : match]
    );
};

export default class IframeViewer extends MojitoCore.Presentation.UIViewImplementation {
    shouldComponentUpdate(nextProps) {
        return !isShallowEqual(this.props, nextProps);
    }

    render() {
        const { pattern, rules } = this.config.urlConfig;
        if (!pattern) {
            log.error(
                `Please check url configuration. 'urlConfig.pattern' was missed or has invalid value`
            );
            return null;
        }

        const notMappedRules = Object.values(rules).filter(rule => !(rule in this.props.dataMap));
        if (notMappedRules.length) {
            log.error(
                `Please check url configuration. Next ${
                    notMappedRules.length === 1 ? 'rule was' : 'rules were'
                } not mapped: ${notMappedRules.join(
                    ', '
                )}. Available data object should contain all rules' values `
            );
            return null;
        }

        const mappedValues = Object.entries(rules).reduce((acc, [key, value]) => {
            if (value in this.props.dataMap) {
                acc[key] = this.props.dataMap[value];
            }
            return acc;
        }, {});
        const url = replaceAll(pattern, mappedValues, '%%');

        return (
            <iframe
                src={url}
                style={this.config.style}
                allowFullScreen={true}
                allow="autoplay;fullscreen"
                key={url}
            />
        );
    }
}
