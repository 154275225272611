import PropTypes from 'prop-types';
import ScoreboardImpl from './scoreboard.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A View that displays a scoreboard on the event page.
 *
 * @class Scoreboard
 * @name default
 * @memberof Mojito.Presentation.Components.Scoreboard
 */

/**
 * `Scoreboard` component prop types.
 *
 * @property {object} event - The event to be displayed.
 * @memberof Mojito.Presentation.Components.Scoreboard.default
 */
const propTypes = {
    event: PropTypes.object.isRequired,
};

export default UIView('Scoreboard', ScoreboardImpl, propTypes);
