import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'marketgroup';
/**
 *
 * Class offering the possibility to link to specific market group from mongo marketCollection.
 *
 * @class MarketGroupsProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MarketGroupsProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(groupId, setter) {
        const subscription = super.subscribeToEntity(groupId, setter, COLLECTION_NAME);

        return handleSubscription(subscription, groupId, COLLECTION_NAME);
    }
}

export default new MarketGroupsProvider();
