/**
 * @class SlidingPaneTypes
 * @name types
 * @memberof Mojito.Presentation.Components.SlidingPane
 */
export default class SlidingPaneTypes {}

/**
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Components.SlidingPane.types
 */
SlidingPaneTypes.SLIDING_SIDE = {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    BOTTOM: 'bottom',
};
