import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'events';

/**
 *
 * Class offering the possibility to link to specific event lists.
 *
 * @class EventProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class EventProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(eventId, onUpdate) {
        const subscription = super.subscribeToEntity(eventId, onUpdate, COLLECTION_NAME);
        return handleSubscription(subscription, eventId, COLLECTION_NAME);
    }

    subscribeToEntities(eventIds, onInit, onUpdate) {
        const subscriptionInfo = super.subscribeToEntities(
            eventIds,
            onInit,
            onUpdate,
            COLLECTION_NAME
        );
        return handleSubscription(subscriptionInfo, eventIds.join('-'), COLLECTION_NAME, false);
    }
}

export default new EventProvider();
