import { useCallback, useState } from 'react';
import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('ComponentsRouter');

export default function ComponentsRouter({ startFrom, states }) {
    const [history, setHistory] = useState([]);
    const [stepName, setStepName] = useState(startFrom || (states && states[0] && states[0].name));

    const getState = useCallback(
        name => {
            return states.find(item => item.name === name);
        },
        [states]
    );

    const to = useCallback(
        name => {
            if (getState(name)) {
                setHistory([...history, stepName]);
                setStepName(name);
            } else {
                log.warn(`No state found with name -> ${name}`);
            }
        },
        [history, stepName, getState]
    );
    const back = useCallback(() => {
        const nextHistory = [...history];
        const prevStep = nextHistory.pop();
        if (prevStep) {
            setHistory(nextHistory);
            setStepName(prevStep);
        }
    }, [history]);

    const state = getState(stepName);
    return (state && state.renderer(to, back)) || null;
}
