import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Image from 'presentation/components/image/index.jsx';

export default function QuickNavigationButton(props) {
    const {
        mojitoTools: { config },
        title,
        subTitle,
        disabled,
        onClick,
    } = props;

    return (
        <Button config={config.container} onClick={onClick} disabled={disabled}>
            <Text config={config.titleLabel}>{title}</Text>
            <FlexPane config={config.subTitleContainer}>
                <Text config={config.subTitleLabel}>{subTitle}</Text>
                {!disabled && <Image config={config.icon} />}
            </FlexPane>
        </Button>
    );
}
