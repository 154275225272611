import { isEmpty } from 'mojito/utils';
import { memo } from 'react';
import PrebuiltBets from 'modules/prebuilt-bets/index.jsx';
import { useCardsPromotions } from 'modules/event-cards-carousel/hooks';
import { usePromotions, usePromotionsPreload } from 'modules/promotions/hooks';
import { usePromotionsLoadDone } from 'modules/common/hooks/index.js';

// eslint-disable-next-line react/display-name
const PrebuiltPromotionCards = memo(props => {
    const {
        applicablePage,
        mojitoTools: { config, instanceId },
    } = props;

    const { locationId } = config;
    const cardPromotions = usePromotions(locationId, applicablePage);
    const promotionsLoadDone = usePromotionsLoadDone([locationId]);
    const [preloadDone, onPrepareCardsToRender] = usePromotionsPreload(
        cardPromotions,
        instanceId,
        promotionsLoadDone
    );
    const cards = useCardsPromotions(cardPromotions);
    const betItems = cards.map(card => ({
        eventId: card.content.eventId,
        marketId: card.content.marketId,
    }));

    const noContent = promotionsLoadDone && isEmpty(betItems);
    if (noContent) {
        return null;
    }

    return (
        <PrebuiltBets
            betItems={betItems}
            shouldRequestData={preloadDone}
            onPrepareCardsToRender={onPrepareCardsToRender}
        />
    );
});

export default PrebuiltPromotionCards;
