import PropTypes from 'prop-types';
import RenderableImpl from './renderable.jsx';
import MojitoCore from 'mojito/core';

/**
 * This component can be used as a means to circumvent the fact that React JSX unconditionally
 * makes function calls in nested component hierarchies, even if the parent components are
 * never actually rendered. It achieves this by leveraging the fact that components are only
 * mounted if their parent's render function returns a React node containing them.
 *
 * @example <caption>In the following example, Foo's render function returns null. Despite that,
 * renderSomething0 and renderSomething1 will always be called, but renderSomething2 will not.
 * Bar, Baz and Renderable will not be mounted, so Renderable's content callback function will
 * never be invoked.</caption>
 * <Foo visible={false}>
 *     <Bar />
 *     {this.renderSomething0()}
 *     <Baz>
 *         {this.renderSomething1()}
 *     </Baz>
 *     <Renderable content={renderSomething2} />
 * </Foo>
 *
 * @class Renderable
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Renderable` prop types.
 *
 * @property {Function} content - Function that returns a React node when invoked.
 * @property {*} [context] - Optional "this" context that the "content" function will be invoked with.
 * @property {Array} [args = []] - Optional arguments list that the "content" function will be invoked with.
 *
 * @memberof Mojito.Presentation.Components.Renderable
 */
const propTypes = {
    content: PropTypes.func.isRequired,
    context: PropTypes.any,
    args: PropTypes.array,
};

const defaultProps = {
    args: [],
};

export default MojitoCore.Presentation.UIView(
    'Renderable',
    RenderableImpl,
    propTypes,
    defaultProps
);
