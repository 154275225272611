import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import IframeViewerImpl from './iframe-viewer.jsx';

/**
 * This module is responsible for rendering iframe by link provided in configuration.
 * The link can contain patterns which will be replaced with corresponding values from the `dataMap` prop.
 *
 * @class IframeViewer
 * @name IframeViewer
 * @memberof Mojito.Presentation.Components
 */

/**
 * `IframeViewer` component prop types.
 *
 * @property {object} [dataMap={}] - An object containing key/value pairs that are used to replace patterns in the URL defined in urlConfig.
 * @memberof Mojito.Presentation.Components.IframeViewer
 */
const propTypes = {
    dataMap: PropTypes.object,
};

const defaultProps = {
    dataMap: {},
};

export default MojitoCore.Presentation.UIView(
    'IframeViewer',
    IframeViewerImpl,
    propTypes,
    defaultProps
);
