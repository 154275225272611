import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'metaInformation';

/**
 *
 * Class offering the possibility to link to specific Meta information.
 *
 * @class MetaInformationProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.MetaInformation
 */
class MetaInformationProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(metaInformationId, setter) {
        const subscription = super.subscribeToEntity(metaInformationId, setter, COLLECTION_NAME);

        return handleSubscription(subscription, metaInformationId, COLLECTION_NAME);
    }
}

export default new MetaInformationProvider();
