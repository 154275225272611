import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import EventTimingStatus from 'presentation/components/event-timing-status/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import MiniScoreboard from 'presentation/components/mini-scoreboard/index.jsx';
import Text from 'presentation/components/text/index.jsx';

const BetsTypes = MojitoServices.Bets.types;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const SET_BASED = 'set-based';
const PERIOD_BASED = 'period-based';

export default class LegInfoRow extends UIViewImplementation {
    mapPeriodBasedScores(event) {
        const { details } = event;
        if (details.totalScores) {
            return (
                <MiniScoreboard event={this.props.eventItem} config={this.config.miniScoreboard} />
            );
        }
    }

    mapSetBasedScores(event) {
        const { details } = event;
        if (details.detailedScores) {
            return details.detailedScores
                .map(scores => {
                    return `${scores.home}-${scores.away}`;
                })
                .join(', ');
        }
    }

    getBaseType(event) {
        const { setBased, periodBased } = this.config.scoreboard.sports;
        const { sportId } = event;
        if (setBased.find(sport => sport === sportId)) {
            return SET_BASED;
        } else if (periodBased.find(sport => sport === sportId)) {
            return PERIOD_BASED;
        }
    }

    getScores(event) {
        if (event.details) {
            const sportBaseType = this.getBaseType(event);
            if (sportBaseType === SET_BASED) {
                return this.mapSetBasedScores(event);
            } else if (sportBaseType === PERIOD_BASED) {
                return this.mapPeriodBasedScores(event);
            }
        }
    }

    renderSeparator() {
        return <Text config={this.config.separator}>{this.config.textSeparator}</Text>;
    }

    renderScores(event) {
        const scores = this.getScores(event);
        if (scores) {
            return (
                <>
                    {this.renderSeparator()}
                    <Text config={this.config.rowText}>{scores}</Text>
                </>
            );
        }
        return null;
    }

    renderTimeInfo(event) {
        return <EventTimingStatus eventItem={event} config={this.config.eventTimingStatus} />;
    }

    renderInPlayDetails() {
        const { eventItem: event } = this.props;
        if (!event) {
            return null;
        } else if (!event.details.inPlay) {
            return this.renderTimeInfo(event);
        } else if (!this.config.showLiveScores) {
            return (
                <FlexPane>
                    <Text config={this.config.rowText}>
                        {this.resolveString(`$LEG_INFO_ROW.LIVE`)}
                    </Text>
                </FlexPane>
            );
        }
        return (
            <>
                {this.renderTimeInfo(event)}
                {this.renderScores(event)}
            </>
        );
    }

    renderBetStatus(betStatus) {
        const { isCashedOut } = this.props;
        return (
            <Text config={this.config.rowText}>
                {isCashedOut
                    ? this.resolveString('$LEG_INFO_ROW.CASHED_OUT')
                    : this.resolveString(`$LEG_INFO_ROW.${betStatus}`)}
            </Text>
        );
    }

    render() {
        const { leg } = this.props;
        return (
            <FlexPane>
                {leg.status === BetsTypes.BET_STATUS.OPEN
                    ? this.renderInPlayDetails()
                    : this.renderBetStatus(leg.status)}
            </FlexPane>
        );
    }
}
