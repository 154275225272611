import MojitoCore from 'mojito/core';
import { actions as betslipActions } from './slice.js';
import { selectBetslipState } from './selectors.js';
import TaskRunner from 'services/common/task-runner/task-runner';
import RefreshBonusOffersTask from './task/refresh-bonus-offers-task';
import GetOveraskStatusTask from './task/get-overask-status-task';
import { toSelectionPayload } from './helper.js';
import { pull, noop, isNumber, debounce } from 'mojito/utils';

const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { ERROR_CODE } = TransactionsTypes;
const log = MojitoCore.logger.get('BetslipDataRetriever');
const { TaskExecutor } = MojitoCore.Services;
const { dispatch } = MojitoCore.Services.redux.store;
const dispatchSuccess = data => dispatch(betslipActions.betslipResponseSuccess(data));
const dispatchFailed = error => dispatch(betslipActions.betslipResponseFailed(error));

const voidResponder = () => {
    log.warn('betslipService instance is missing.');
    return Promise.reject();
};

const voidResponderSync = () => log.warn('betslipService instance is missing.');

const NULL_SERVICE = {
    configure: noop,
    initBetslip: voidResponder,
    addPart: voidResponder,
    updatePart: voidResponder,
    removePart: voidResponder,
    switchPartSelection: voidResponder,
    addComposite: voidResponder,
    updateComposite: voidResponder,
    removeComposite: voidResponder,
    setStake: voidResponder,
    activateStakeGroup: voidResponder,
    activateTeaserType: voidResponder,
    removeStakeGroup: voidResponder,
    executeActions: voidResponder,
    clearBetslip: voidResponder,
    placeBetslip: voidResponder,
    validateBonuses: voidResponder,
    refreshBonusOffers: voidResponder,
    addFreeBet: voidResponder,
    removeFreeBet: voidResponder,
    getOveraskStatus: voidResponder,
    updateOverask: voidResponder,
    toggleBanker: voidResponder,
    toggleFreeBet: voidResponder,
    fetchBetslipData: voidResponderSync,
    cleanBetslipData: voidResponderSync,
    storeBetslipData: voidResponderSync,
};

/**
 * @property {number} [DEFAULT_BONUS_OFFERS_REFRESH_INTERVAL= 60 * 2 * 1000] - Default interval used to refresh bonus offers available for user.
 * @property {number} [DEFAULT_REFRESH_OVERASK_STATUS_INTERVAL= 2 * 1000] - Default interval used to refresh overask status.
 * @property {number} [DEFAULT_REFRESH_OVERASK_STATUS_DELAY= 8 * 1000] - Default delay used to refresh overask status. Polling overask status start after delay to decrease amount of calls to back-end.
 *
 * @memberof Mojito.Services.Betslip.dataRetriever
 */
const DEFAULT_BONUS_OFFERS_REFRESH_INTERVAL = 60 * 2 * 1000;
const DEFAULT_REFRESH_OVERASK_STATUS_INTERVAL = 2 * 1000;
const DEFAULT_REFRESH_OVERASK_STATUS_DELAY = 8 * 1000;

const DEFAULT_CONFIG = {
    refreshBonusOffersInterval: DEFAULT_BONUS_OFFERS_REFRESH_INTERVAL,
    refreshOveraskStatusInterval: DEFAULT_REFRESH_OVERASK_STATUS_INTERVAL,
    refreshOveraskStatusDelay: DEFAULT_REFRESH_OVERASK_STATUS_DELAY,
};

/**
 * Betslip data retriever config.
 *
 * @typedef BetslipDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.Betslip.AbstractBetslipService} service - Instance of concrete betslip service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service</code> instance.
 * @property {number} [refreshBonusOffersInterval = DEFAULT_BONUS_OFFERS_REFRESH_INTERVAL] - Interval in ms that will be used to refresh bonus offers available for user.
 * If `0` then bonus refresh will be disabled.
 * Typically used to retrieve acc boost, acc insurance bonuses info if available for defined betslip state.
 * @property {number} [refreshOveraskStatusInterval = DEFAULT_REFRESH_OVERASK_STATUS_INTERVAL] - Interval in ms that will be used to poll overask status while bet is being considered by trader.
 * @memberof Mojito.Services.Betslip.dataRetriever
 */

/**
 * Function returns actual betslip state.
 * Typically used to resolve betslip state that will be passed to the service calls.
 *
 * @function betslipStateResolver
 *
 * @returns {*} Betslip state.
 * @memberof Mojito.Services.Betslip.dataRetriever
 */

/**
 * Data retriever used to delegate calls to betslip service abstraction.
 *
 * @class BetslipDataRetriever
 * @name dataRetriever
 * @memberof Mojito.Services.Betslip
 */
class BetslipDataRetriever {
    constructor() {
        this.service = NULL_SERVICE;
        this.enableBonusValidation = false;
        this.activateStakeGroupWithBonusValidation = noop;
        this.pendingStakes = {};
    }

    /**
     * Init with service implementation.
     *
     * @param {Mojito.Services.Betslip.dataRetriever.BetslipDataRetrieverConfig} [config = {}] - Betslip data retriever config.
     * @function Mojito.Services.Betslip.dataRetriever#init
     */
    init(config = {}) {
        const {
            service,
            serviceUrl,
            refreshBonusOffersInterval,
            refreshOveraskStatusInterval,
            refreshOveraskStatusDelay,
        } = config;
        this.service = service || NULL_SERVICE;
        this.service.configure({ serviceUrl });

        this.refreshBonusOffersInterval = isNaN(refreshBonusOffersInterval)
            ? DEFAULT_CONFIG.refreshBonusOffersInterval
            : refreshBonusOffersInterval;
        this.bonusOffersRunner =
            this.refreshBonusOffersInterval > 0 && new TaskRunner(this.refreshBonusOffersInterval);

        this.refreshOveraskStatusInterval = isNaN(refreshOveraskStatusInterval)
            ? DEFAULT_CONFIG.refreshOveraskStatusInterval
            : refreshOveraskStatusInterval;
        this.overaskStatusRunner = new TaskRunner(this.refreshOveraskStatusInterval);

        this.refreshOveraskStatusDelay = isNaN(refreshOveraskStatusDelay)
            ? DEFAULT_CONFIG.refreshOveraskStatusDelay
            : refreshOveraskStatusDelay;

        if (service) {
            const taskExecutor = new TaskExecutor();
            this.taskExecutor = taskExecutor;

            const activateStakeGroupTask = this.activateStakeGroup.bind(this);

            this.addComposite = taskExecutor.withQueue(this.addComposite.bind(this));
            this.updateComposite = taskExecutor.withQueue(this.updateComposite.bind(this));
            this.removeComposite = taskExecutor.withQueue(this.removeComposite.bind(this));
            this.clearBetslip = taskExecutor.withQueue(this.clearBetslip.bind(this));
            this.activateStakeGroup = taskExecutor.withQueue(activateStakeGroupTask);
            this.activateTeaserType = taskExecutor.withQueue(this.activateTeaserType.bind(this));
            this.updateOverask = taskExecutor.withQueue(this.updateOverask.bind(this));
            this.refreshBonusOffers = taskExecutor.withQueue(this.refreshBonusOffers.bind(this));

            const bonusValidationTask = this.validateBonuses.bind(this);
            this.bonusValidationTask = bonusValidationTask;
            this.validateBonuses = taskExecutor.withQueue(bonusValidationTask);
            // Debounce to 1s to not execute bonus validation too often if user interacts with betslip fast.
            this.debouncedValidateBonuses = debounce(
                () => this.validateBonuses().catch(noop),
                1000
            );

            // If place betslip initiated - validation task should be aborted if it is in the queue or executing
            const queuedPlaceBetslip = taskExecutor.withQueue(this.placeBetslip.bind(this));
            this.placeBetslip = (...args) => {
                this.debouncedValidateBonuses.cancel();
                taskExecutor.abortTask(bonusValidationTask);
                return queuedPlaceBetslip(...args);
            };

            // These methods require bonus validation if enabled.
            this.initBetslip = this.withBonusValidation(
                this.initBetslip.bind(this),
                bonusValidationTask
            );
            this.activateStakeGroupWithBonusValidation = this.withBonusValidation(
                activateStakeGroupTask,
                bonusValidationTask
            );
            this.addPart = this.withBonusValidation(this.addPart.bind(this), bonusValidationTask);
            this.updatePart = this.withBonusValidation(
                this.updatePart.bind(this),
                bonusValidationTask
            );
            this.removePart = this.withBonusValidation(
                this.removePart.bind(this),
                bonusValidationTask
            );
            this.switchPartSelection = this.withBonusValidation(
                this.switchPartSelection.bind(this),
                bonusValidationTask
            );
            this.executeActions = this.withBonusValidation(
                this.executeActions.bind(this),
                bonusValidationTask
            );
            this.removeStakeGroup = this.withBonusValidation(
                this.removeStakeGroup.bind(this),
                bonusValidationTask
            );
            this.toggleBanker = this.withBonusValidation(
                this.toggleBanker.bind(this),
                bonusValidationTask
            );
            this.toggleFreeBet = this.withBonusValidation(
                this.toggleFreeBet.bind(this),
                bonusValidationTask
            );
        }
    }

    /**
     * Turns on/off bonus validation mechanism.
     *
     * @param {boolean} value - True to enable bonus validation, false otherwise.
     * @function Mojito.Services.Betslip.dataRetriever#setBonusValidationEnabled
     */
    setBonusValidationEnabled(value) {
        this.enableBonusValidation = value;
    }

    /**
     * Init betslip.
     *
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     * @param {Mojito.Modules.Betslip.types.BETSLIP_MODE} betslipMode - Betslip mode.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on init betslip service response.
     * @function Mojito.Services.Services.dataRetriever#initBetslip
     */
    initBetslip(oddsFormat, betslipMode, state) {
        return this.service
            .initBetslip(oddsFormat, betslipMode, state)
            .then(betslip => dispatch(betslipActions.initSuccess(betslip)))
            .catch(dispatchFailed);
    }

    /**
     * Add selection part in the betslip.
     *
     * @param {Mojito.Services.Betslip.types.AddSelectionPartPayload} payload - Add part payload.
     *
     * @returns {Promise} Promise resolves on add part betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#addPart
     */
    addPart(payload) {
        const {
            source,
            selection: { betRef },
        } = payload;
        return this.service
            .addPart({ source, betRef }, selectBetslipState())
            .then(betslip => {
                dispatch(betslipActions.addPartSuccess({ ...betslip, requestPayload: payload }));
            })
            .catch(dispatchFailed);
    }

    /**
     * Remove part from betslip.
     *
     * @param {Mojito.Services.Betslip.types.RemovePartPayload} payload - Remove part payload data.
     *
     * @returns {Promise} Promise resolves on remove part betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#removePart
     */
    removePart(payload) {
        const { selectionId, bet } = payload;
        return this.service
            .removePart(selectionId, bet.id, selectBetslipState())
            .then(betslip =>
                dispatch(betslipActions.removePartSuccess({ ...betslip, requestPayload: payload }))
            )
            .catch(dispatchFailed);
    }

    /**
     * Switch betslip part selection with another one.
     * Usually taken from {@link Mojito.Services.Betslip.types.Part|Part} alternative selections.
     *
     * @param {Mojito.Services.Betslip.types.SwitchSelectionPayload} payload - Switch part selection payload data.
     *
     * @returns {Promise} Promise resolves on switch part selection betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#switchPartSelection
     */
    switchPartSelection(payload) {
        const { selectionId, betRef } = payload;
        return this.service
            .switchPartSelection(selectionId, betRef, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Update part in the betslip.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - Selections to update in betslip.
     *
     * @returns {Promise} Promise resolves on update part betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#updatePart
     */
    updatePart(selections) {
        const data = toSelectionPayload(selections);
        dispatch(betslipActions.updatePending());
        return this.service
            .updatePart(data, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Add composite in the betslip.
     *
     * @param {Mojito.Services.Betslip.types.AddCompositePayload} payload - Add betslip composite payload.
     *
     * @returns {Promise} Promise resolves on add composite betslip service response.
     *
     * @function Mojito.Services.Betslip.dataRetriever#addComposite
     */
    addComposite(payload) {
        const {
            parent: { eventId },
            legSort,
            odds,
            source,
        } = payload;

        const selections = toSelectionPayload(payload.selections);

        return this.service
            .addComposite({ eventId, legSort, odds, source, selections }, selectBetslipState())
            .then(betslip =>
                dispatch(
                    betslipActions.addCompositeSuccess({ ...betslip, requestPayload: payload })
                )
            )
            .catch(dispatchFailed);
    }

    /**
     * Remove composite from the betslip.
     *
     * @param {Mojito.Services.Betslip.types.RemoveCompositePayload} payload - Remove betslip composite payload.
     *
     * @returns {Promise} Promise resolves on remove composite betslip service response.
     *
     * @function Mojito.Services.Betslip.dataRetriever#removeComposite
     */
    removeComposite(payload) {
        const { selectionIds, bet } = payload;
        return this.service
            .removeComposite(selectionIds, bet.id, selectBetslipState())
            .then(betslip =>
                dispatch(
                    betslipActions.removeCompositeSuccess({ ...betslip, requestPayload: payload })
                )
            )
            .catch(dispatchFailed);
    }

    /**
     * Update composite in the betslip.
     *
     * @param {Mojito.Services.Betslip.types.UpdateCompositePayload} payload - Update betslip composite payload.
     *
     * @returns {Promise} Promise resolves on update composite betslip service response.
     *
     * @function Mojito.Services.Betslip.dataRetriever#updateComposite
     */
    updateComposite(payload) {
        const {
            parent: { eventId },
            betId,
            odds,
        } = payload;

        const selections = toSelectionPayload(payload.selections);

        return this.service
            .updateComposite({ betId, odds, eventId, selections }, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Set a stake for a specific bet in a betslip. Pending request can be aborted via {@link Mojito.Services.Betslip.dataRetriever#abortSetStake|abortSetStake} call.
     *
     * @param {Mojito.Services.Betslip.types.SetStakeRequest} payload - Set stake payload data.
     *
     * @returns {Promise} Promise resolves on set stake betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#setStake
     */
    setStake(payload) {
        const { betId } = payload;

        const stakeTask = () => {
            const controller = new AbortController();
            const signal = controller.signal;
            const promise = this.service
                .setStake(payload, selectBetslipState(), signal)
                .then(response => {
                    dispatch(betslipActions.betslipResponseSuccess(response));
                    pull(this.pendingStakes[betId], stakeTask);
                })
                .catch(error => {
                    if (error.type !== ERROR_CODE.ABORTED) {
                        dispatchFailed(error);
                    }
                    pull(this.pendingStakes[betId], stakeTask);
                });
            promise.abort = () => {
                pull(this.pendingStakes[betId], stakeTask);
                controller.abort();
            };
            return promise;
        };

        const pendingBetTasks = this.pendingStakes[betId] || [];
        this.pendingStakes[betId] = [...pendingBetTasks, stakeTask];

        return this.withBonusValidation(stakeTask, this.bonusValidationTask)();
    }

    /**
     * Abort set stake request. Will cancel {@link Mojito.Services.Betslip.dataRetriever#setStake|setStake} request if it is in progress.
     * It is useful to abort request if user enters new stake but the previous one is still processing by a server.
     *
     * @param {string} betId - Bet id to abort set stake for.
     *
     * @function Mojito.Services.Betslip.dataRetriever#abortSetStake
     */
    abortSetStake(betId) {
        const pendingTasks = this.pendingStakes[betId] || [];
        pendingTasks.forEach(task => this.taskExecutor.abortTask(task));
    }

    /**
     * Remove a stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     *
     * @returns {Promise} Promise resolves on remove stake group betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#removeStakeGroup
     */
    removeStakeGroup(stakeGroupName) {
        return this.service
            .removeStakeGroup(stakeGroupName, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Activate a stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     *
     * @returns {Promise} Promise resolves on activate stake group betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#activateStakeGroup
     */
    activateStakeGroup(stakeGroupName) {
        return this.service
            .activateStakeGroup(stakeGroupName, selectBetslipState())
            .then(betslip => dispatch(betslipActions.activateStakeGroupSuccess(betslip)))
            .catch(dispatchFailed);
    }

    /**
     * Activate a teaser type.
     *
     * @param {Mojito.Services.Betslip.types.TEASER_TYPE} type - Teaser type.
     *
     * @returns {Promise} Promise resolves on activate teaser type betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#activateTeaserType
     */
    activateTeaserType(type) {
        return this.service
            .activateTeaserType(type, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Execute actions.
     *
     * @param {Array<Mojito.Services.Betslip.types.Action>} actions - List of actions.
     *
     * @returns {Promise} Promise resolves on execute actions service response.
     * @function Mojito.Services.Betslip.dataRetriever#executeActions
     */
    executeActions(actions) {
        return this.service
            .executeActions(actions, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Place betslip.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {Array<Mojito.Services.Betslip.types.PRICE_CHANGE_POLICY>} priceChangePolicies - Price change policies list.
     * @param {string} currency - Currency.
     *
     * @returns {Promise} Promise resolves on place betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#placeBetslip
     */
    placeBetslip(stakeGroupName, priceChangePolicies, currency) {
        return this.service
            .placeBetslip(stakeGroupName, priceChangePolicies, currency, selectBetslipState())
            .then(response => {
                if (!response.betslip) {
                    // We need to make sure that we are staying on bet receipt when placement is successful
                    // there is possibility of additional request being queued during bet placement.
                    this.taskExecutor.abortAll();
                }
                dispatch(betslipActions.placeBetslipSuccess(response));
            })
            .catch(dispatchFailed);
    }

    /**
     * Clear betslip.
     *
     * @param {Array<Mojito.Services.Betslip.types.BETSLIP_PART>} retainParts - List of betslip parts.
     *
     * @returns {Promise} Promise resolves on clear betslip service response.
     * @function Mojito.Services.Betslip.dataRetriever#clearBetslip
     */
    clearBetslip(retainParts) {
        return this.service
            .clearBetslip(retainParts, selectBetslipState())
            .then(betslip =>
                dispatch(betslipActions.clearBetslipSuccess({ ...betslip, retainParts }))
            )
            .catch(dispatchFailed);
    }

    /**
     * Validate bonuses.
     *
     * @returns {Promise} Promise resolves on validate bonuses service response. Promise has possibility to be aborted.
     * @function Mojito.Services.Betslip.dataRetriever#validateBonuses
     */
    validateBonuses() {
        dispatch(betslipActions.validateBonusesPending());
        const controller = new AbortController();
        const signal = controller.signal;
        const promise = this.service
            .validateBonuses(selectBetslipState(), signal)
            .then(payload => {
                dispatch(betslipActions.validateBonusesSuccess(payload));
            })
            .catch(error => {
                if (error.type !== ERROR_CODE.ABORTED) {
                    dispatch(betslipActions.validateBonusesFailed());
                }
            });
        promise.abort = () => {
            controller.abort();
        };
        return promise;
    }

    /**
     * Refresh bonus offers.
     *
     * @returns {Promise} Promise resolves on refresh bonus offers service response.
     * @function Mojito.Services.Betslip.dataRetriever#refreshBonusOffers
     */
    refreshBonusOffers() {
        return this.service
            .refreshBonusOffers(selectBetslipState())
            .then(response => dispatch(betslipActions.refreshBonusOffersSuccess(response)))
            .catch(error => dispatch(betslipActions.refreshBonusOffersFailed(error)));
    }

    /**
     * Starts constantly refreshing bonus offers with interval provided in <code>refreshBonusOffersInterval</code> config property.
     * Note: if <code>refreshBonusOffersInterval</code> is set to <code>0</code>, this call has no effect.
     *
     * @function Mojito.Services.Betslip.dataRetriever#startBonusOffersRefreshBeat
     */
    startBonusOffersRefreshBeat() {
        if (this.bonusOffersRunner && !this.bonusOffersRunner.isExecuting) {
            this.bonusOffersRunner.run(new RefreshBonusOffersTask(this));
        }
    }

    /**
     * Stops refreshing bonus offers.
     * Resets execution that has been initiated by {@link Mojito.Services.Betslip.dataRetriever#startBonusOffersRefreshBeat|startBonusOffersRefreshBeat} call.
     *
     * @function Mojito.Services.Betslip.dataRetriever#stopBonusOffersRefreshBeat
     */
    stopBonusOffersRefreshBeat() {
        if (this.bonusOffersRunner) {
            this.bonusOffersRunner.reset();
        }
    }

    /**
     * True if bonus offers refresh beat is ongoing, false otherwise.
     *
     * @returns {boolean} True if bonus offers refresh is started, false otherwise.
     * @function Mojito.Services.Betslip.dataRetriever#isBonusOffersRefreshing
     */
    isBonusOffersRefreshing() {
        return !!(this.bonusOffersRunner && this.bonusOffersRunner.activeTask);
    }

    /**
     * Toggle free bet. If <code>shouldRemove</code> is set to <code>true</code>
     * then call will be delegated to <code>removeFreeBet</code> service method, otherwise <code>addFreeBet</code> will be used.
     *
     * @param {string} token - Free bet token.
     * @param {string} name - Free bet name.
     * @param {boolean} [shouldRemove = false] - True if free bet should be removed, false otherwise.
     * @returns {Promise} Promise resolves on free bet add/remove service response.
     *
     * @function Mojito.Services.Betslip.dataRetriever#toggleFreeBet
     */
    toggleFreeBet(token, name, shouldRemove = false) {
        const { addFreeBet, removeFreeBet } = this.service;
        let action = shouldRemove ? removeFreeBet : addFreeBet;
        action = action.bind(this.service);
        return action(token, selectBetslipState())
            .then(betslip =>
                dispatch(
                    betslipActions.toggleFreeBetSuccess({ ...betslip, token, name, shouldRemove })
                )
            )
            .catch(dispatchFailed);
    }

    /**
     * This method returns function that wraps input `task`
     * with additional handling required for bonuses validation.
     * The `task` function should return Promise. When promise
     * resolves we abort previously registered `validationTask` from `taskExecutor`
     * and initiate `validateBonuses` call that will be placed in the end of queue.
     *
     * @param {Function} task - Function that will be wrapped. Should return promise.
     * @param {Function} validationTask - Function that is used to validate bonuses.
     * This function is usually passed to the `this.taskExecutor.withQueue` to be executed in queue.
     *
     * @returns {Function} Function wrapped with bonus validation functionality.
     * @private
     */
    withBonusValidation(task, validationTask) {
        const queueTask = this.taskExecutor.withQueue(task);
        return (...args) => {
            if (!this.enableBonusValidation) {
                return queueTask(...args);
            }
            this.taskExecutor.abortTask(validationTask);

            // Bonus validation is considered to be pending whenever bonus aware task is getting executed.
            dispatch(betslipActions.validateBonusesPending());
            return queueTask(...args).then(() => {
                this.taskExecutor.abortTask(validationTask);
                this.debouncedValidateBonuses();
            });
        };
    }

    /**
     * Get overask status.
     *
     * @returns {Promise} Promise resolves on overask status response.
     * @function Mojito.Services.Betslip.dataRetriever#getOveraskStatus
     */
    getOveraskStatus() {
        return this.service
            .getOveraskStatus(selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }

    /**
     * Update overask.
     *
     * @param {Mojito.Services.Betslip.types.OveraskUpdatePayload} payload - Payload.
     * @returns {Promise} Promise resolves on update overask response.
     * @function Mojito.Services.Betslip.dataRetriever#updateOverask
     */
    updateOverask(payload) {
        return this.service
            .updateOverask(payload, selectBetslipState())
            .then(dispatchSuccess)
            .catch(dispatchFailed);
    }
    /**
     * Starts polling overask status with interval provided in <code>refreshOveraskStatusInterval</code> config property and delay provided in <code>refreshOveraskStatusDelay</code> config property.
     *
     * @param {number} delay - Time in ms to delay status request.
     * @param {Function} success - Callback function executed on <code>getOveraskStatus</code> success response.
     * @param {Function} fail - Callback function executed on <code>getOveraskStatus</code> fail response.
     *
     * @function Mojito.Services.Betslip.dataRetriever#requestOveraskStatus
     */
    requestOveraskStatus(delay, success, fail) {
        if (this.overaskStatusRunner && !this.overaskStatusRunner.isExecuting) {
            this.overaskStatusRunner.executeWithDelay(
                new GetOveraskStatusTask(this, success, fail),
                isNumber(delay) ? delay : this.refreshOveraskStatusDelay
            );
        }
    }

    /**
     * Stops polling overask status.
     * Resets execution that has been initiated by {@link Mojito.Services.Betslip.dataRetriever#requestOveraskStatus|requestOveraskStatus} call.
     *
     * @function Mojito.Services.Betslip.dataRetriever#discardOveraskStatus
     */
    discardOveraskStatus() {
        if (this.overaskStatusRunner) {
            this.overaskStatusRunner.reset();
        }
    }

    /**
     * Toggle banker on/off for a bet.
     *
     * @param {string} betId - Bet ID.
     *
     * @returns {Promise} Promise is resolved on toggle banker service response.
     * @function Mojito.Services.Betslip.dataRetriever#activateBankerType
     */
    toggleBanker(betId) {
        return this.service
            .toggleBanker(betId, selectBetslipState())
            .then(betslipData =>
                dispatch(betslipActions.toggleBankerSuccess({ ...betslipData, betId }))
            )
            .catch(dispatchFailed);
    }

    /**
     * Stores betslip data in a service.
     *
     * @param {object} data - Betslip data.
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} dataType - The type of the data, will be used as a key.
     *
     * @returns {Promise} Promise resolves on store betslip data.
     * @function Mojito.Services.Betslip.dataRetriever#storeBetslipData
     */
    storeBetslipData(data, dataType) {
        return this.service.storeBetslipData(data, dataType);
    }

    /**
     * Removes betslip data from service storage.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} dataType - The type of the data to be removed.
     *
     * @returns {Promise} Promise resolves on clear betslip data.
     * @function Mojito.Services.Betslip.service#cleanBetslipData
     */
    cleanBetslipData(dataType) {
        return this.service.cleanBetslipData(dataType);
    }

    /**
     * Fetch betslip data from a service.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} dataType - The type of the data to be fetched.
     *
     * @returns {object} Stored betslip data from service.
     * @function Mojito.Services.Betslip.service#fetchBetslipData
     */
    fetchBetslipData(dataType) {
        return this.service.fetchBetslipData(dataType);
    }
}

export default new BetslipDataRetriever();
