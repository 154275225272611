import ButtonBar from 'presentation/components/button-bar/index.jsx';
import MenuRow from 'presentation/components/menu-row/index.jsx';

const MarketGroupSelector = props => {
    const {
        marketGroups,
        selectedMarketGroupId,
        onMarketGroupChange,
        mojitoTools: { config, stringResolver, appContext },
    } = props;

    const {
        analyticsEmitter: { emitAnalytics },
    } = appContext;

    const onSHowHideToggleClicked = isShown => {
        emitAnalytics('marketGroupsShowHideToggleClicked', isShown);
    };

    const items = marketGroups.map(marketGroup => {
        const newFeatures = config.newFeatureMarketGroupIds || [];
        const label = stringResolver.resolveString(marketGroup.l10nKey, true) || marketGroup.name;
        return config.scrollable
            ? { id: marketGroup.id, data: label, hash: marketGroup.eventId }
            : {
                  key: marketGroup.id,
                  newFeature: newFeatures.includes(marketGroup.id),
                  label,
                  hash: marketGroup.eventId,
              };
    });

    if (config.scrollable) {
        return (
            <MenuRow
                config={config.marketsMenu}
                menuItems={items}
                selectedItemId={selectedMarketGroupId}
                cbItemClicked={onMarketGroupChange}
            />
        );
    }
    return (
        <ButtonBar
            config={config.buttonBar}
            items={items}
            onSelectionChanged={onMarketGroupChange}
            selectedKey={selectedMarketGroupId}
            newFeatureText={stringResolver.resolveString(
                '$MARKET_GROUP_SELECTOR.NEW_FEATURE_HIGHLIGHT'
            )}
            onSHowHideToggleClicked={onSHowHideToggleClicked}
        />
    );
};
export default MarketGroupSelector;
