import PropTypes from 'prop-types';
import StatscoreImpl from './statscore.jsx';
import MojitoCore from 'mojito/core';
import { STATE } from './types.js';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Encapsulates a native Statscore tracker in a Mojito/React component.
 *
 * @class Statscore
 * @memberof Mojito.Presentation.Components
 */

/**
 * Statscore prop types.
 *
 * @property {string} visualisationId - The sprite's id within the spritemap.
 * @property {Function} [onChange => ()=>{}] - Callback, triggered when the state of the Statscore component changes.
 *
 * @memberof Mojito.Presentation.Components.Statscore
 */
const propTypes = {
    visualisationId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

const defaultProps = {
    onChange: noop,
};

const Statscore = UIView('Statscore', StatscoreImpl, propTypes, defaultProps);

Statscore.Types = {
    STATE,
};

export default Statscore;
