/**
 * @classdesc Types {@link Mojito.Presentation.Components.DocumentHead|DocumentHead}.
 * @class DocumentHeadTypes
 * @memberof Mojito.Presentation.Components
 */
export default class DocumentHeadTypes {}

/**
 * Page id
 * <br><br>
 * This id is passed to DocumentHead when it is not possible to render it from a page component, and the component it is
 * rendered from is used on more than one page. This is useful when the pages should show additional meta data, but the
 * meta data differs depending on the page. Such approach requires a custom meta factory to be implemented and the
 * page id can then be used to differentiate between the pages.
 * <br><br>
 * The description of each property value will tell which component that provides it and which page component it identifies.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Components.DocumentHeadTypes
 */
DocumentHeadTypes.PAGE_ID = {
    MATCH_SPORT_OUTRIGHTS: 'MATCH_SPORT_OUTRIGHTS',
    MATCH_SPORT_COMPETITIONS: 'MATCH_SPORT_COMPETITIONS',
};
