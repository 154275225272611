import ToastViewImpl from './toast-view.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * A view displaying toast message and reacting on user interactions.
 *
 * @class ToastView
 * @memberof Mojito.Presentation.Components
 */

/**
 * Callback function triggered when user interacts with the toast view, for instance by closing or clicking it.
 *
 * @callback toastCallback
 * @param {object} message - Toast message object.
 * @memberof Mojito.Presentation.Components.ToastView
 */

/**
 * Prop types for the `ToastView`.
 *
 * @property {object} message - The message to be shown.
 * @property {Mojito.Presentation.Components.ToastView.toastCallback} [onClick = () => {}] - Callback triggered on toast click.
 * @property {Mojito.Presentation.Components.ToastView.toastCallback} [onClose = () => {}] - Callback triggered on clicking the close button.
 * @memberof Mojito.Presentation.Components.ToastView
 */
const propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        payload: PropTypes.shape({
            text: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.string,
        }),
    }).isRequired,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
};

const defaultProps = {
    onClick: noop,
    onClose: noop,
};

export default UIView('ToastView', ToastViewImpl, propTypes, defaultProps);
