import MojitoCore from 'mojito/core';
import RangeInputImpl from './range-input.jsx';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

/**
 * RangeInput (slider) component.
 *
 * The RangeInput is a slider component enabling users to define a numeric value within a fixed range.
 * This component makes use of HTML input of type range.
 * Because of the need for pseudo-classes, styling for the component is dynamically applied.
 * For each configuration, these styles are initiated once for the property "class".
 *
 * @class RangeInput
 * @memberof Mojito.Presentation.Components
 */

/**
 * Callback function for the RangeInput component when the input value changes.
 *
 * @param {number} value - The current value of the input.
 * @param {React.SyntheticEvent} event - A React-wrapped HTML input event. For more information, refer to {@link https://reactjs.org/docs/events.html}.
 *
 * @callback Mojito.Presentation.Components.RangeInput.changeCallback
 */

/**
 * RangeInput prop types.
 *
 * @property {string} [inputId] - An optional id given to the RangeInput root element.
 * @property {number} [value] - The RangeInput value.
 * @property {number} [min] - The minimum value for the RangeInput.
 * @property {number} [max] - The maximum value for the RangeInput.
 * @property {number} [step] - The step increment for the RangeInput.
 * @property {boolean} [disabled=false] - A flag indicating whether the RangeInput is disabled (true if disabled).
 * @property {Mojito.Presentation.Components.RangeInput.changeCallback} [onChange = () => {}] - A callback function to handle onchange events for input changes.
 *
 * @memberof Mojito.Presentation.Components.RangeInput
 */
const propTypes = {
    inputId: PropTypes.string,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

const defaultProps = {
    onChange: noop,
    disabled: false,
};

export default MojitoCore.Presentation.UIView(
    'RangeInput',
    RangeInputImpl,
    propTypes,
    defaultProps
);
