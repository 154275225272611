import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import ComponentsRouterImpl from './components-router.jsx';

/**
 * Component gives possibility to render different views (one at moment) inside it,
 * switch between them with keeping navigation history.
 *
 * @class ComponentsRouter
 * @memberof Mojito.Presentation.Components
 */

/**
 * History state configuration.
 *
 * @typedef State
 * @type {object}
 * @property {string} name - The name (id). Example: root, state1, step_4, show-login.
 * @property {Function} renderer - The function that renders state.
 * @memberof Mojito.Presentation.Components.ComponentsRouter
 */
const stateType = PropTypes.shape({
    name: PropTypes.string,
    renderer: PropTypes.func,
});

/**
 * `ComponentsRouter` component prop types.
 *
 * @property {string} [startFrom] - Name of initial state for rendering.
 * @property {Mojito.Presentation.Components.ComponentsRouter.State[]} states - Array of all possible states.
 * @memberof Mojito.Presentation.Components.ComponentsRouter
 */
const propTypes = {
    startFrom: PropTypes.string,
    states: PropTypes.arrayOf(stateType).isRequired,
};

export default MojitoCore.Presentation.UIView('ComponentsRouter', ComponentsRouterImpl, propTypes);
