export const isEmpty = obj => {
    if (obj === undefined || obj === null || obj === '') {
        return true;
    }
    if (Object.prototype.toString(obj) === '[object String]') {
        return obj.toString().trim() === '';
    }
    if (obj instanceof Array) {
        return obj.length === 0;
    }
    return false;
};

export function isObject(val) {
    return typeof val === 'object' && val !== null && !Array.isArray(val);
}

function isPlainObject(val) {
    return isObject(val) && (!val.__proto__ || val.__proto__ === Object.prototype);
}

export const getConfigByContext = (config, context) => {
    if (isEmpty(context)) {
        return config;
    }

    const tempConfig = Object.assign({}, config);

    Object.keys(tempConfig).forEach(key => {
        let planePropertyName = getPlanePropertyName(context, key);
        let valueToApply = null;
        let propToReset = null;

        if (tempConfig[key] && tempConfig[key].constructor === Object) {
            valueToApply = getConfigByContext(tempConfig[key], context);
            propToReset = planePropertyName;
        } else if (isContextProperty(context, key)) {
            valueToApply = tempConfig[key];
            propToReset = planePropertyName;
        } else if (context === 'native') {
            // '_mobile_' prefixed props should be applied when there are no '_native_' prefixed props
            planePropertyName = getPlanePropertyName('mobile', key);
            if (isContextProperty('mobile', key) && !tempConfig[planePropertyName]) {
                valueToApply = tempConfig[key];
                propToReset = planePropertyName;
            }
        }

        if (propToReset !== null) {
            tempConfig[propToReset] = valueToApply;
        }
    });

    return tempConfig;
};

export const removeContextProperties = config => {
    if (typeof config === 'object' && config !== null) {
        if (Array.isArray(config)) {
            return config.map(removeContextProperties);
        } else {
            const result = {};
            for (let key in config) {
                if (config.hasOwnProperty && !config.hasOwnProperty(key)) continue;
                if (key.startsWith('_')) continue;
                const value = config[key];
                if (typeof value === 'object') {
                    result[key] = removeContextProperties(value);
                } else {
                    result[key] = value;
                }
            }
            return result;
        }
    }
    return config;
};

export const isContextProperty = (context, property) => {
    const tester = new RegExp(`^_${context}_.*$`, 'i');
    return tester.test(property);
};

const getPlanePropertyName = (context, property) => {
    return property.replace(`_${context}_`, '');
};

/**
 * This will remove all objects from config that has hide:true property according to context if provided
 * Supports plain objects and Arrays
 * @param config - JS object of any level of hierarchy deepness (usually part of viewsconfig)
 * @param context - application context mobile|desktop
 * @returns {config} object without hidden items
 */
export const escapeHiddenItems = (config, context) => {
    if (isEmpty(config)) {
        return config;
    }
    switch (config.constructor) {
        case Array: {
            return config.reduce((result, item) => {
                const configPart = escapeHiddenItems(item, context);
                if (configPart != null) {
                    result.push(configPart);
                }
                return result;
            }, []);
        }
        case Object: {
            const tempConfig = getConfigByContext(config, context);
            if (isHidden(tempConfig)) {
                return undefined;
            }
            return Object.keys(tempConfig).reduce((result, key) => {
                const configPart = escapeHiddenItems(tempConfig[key], context);
                if (configPart != null) {
                    result[key] = configPart;
                }
                return result;
            }, {});
        }
    }
    return config;
};

const isHidden = item => {
    return Object.prototype.hasOwnProperty.call(item, 'hide') && item.hide === true;
};

export function deepFreezeAllEnumerables(obj) {
    const propNames = Object.getOwnPropertyNames(obj);
    propNames.forEach(function (name) {
        if (!Object.prototype.propertyIsEnumerable.call(obj, name)) {
            return;
        }
        const prop = obj[name];
        if (typeof prop === 'object' && prop !== null) deepFreezeAllEnumerables(prop);
    });

    return Object.freeze(obj);
}

/**
 * Creates an object composed of the picked object properties.
 * Properties are shallow copied. Missing props are ignored.
 * @param obj {Object} Source object
 * @param properties {String[]} List of property names to copy
 * @returns {Object} Resulting object
 */
export function pickProperties(obj, properties) {
    const result = {};
    for (let item of properties) {
        if (item in obj) {
            result[item] = obj[item];
        }
    }
    return result;
}

/**
 * Set the value for property specified by dot-notation path
 * @param object {Object} Object to set property of
 * @param path  Dot-notation path to property
 * @param value Value to set
 * @return {*} object
 */
export function setObjectProperty(object, path, value) {
    const idx = path.indexOf('.');
    if (idx < 0) {
        object[path] = value;
        return object;
    }

    const subPath = path.substr(idx + 1);
    const first = path.substr(0, idx);
    if (!object[first]) {
        object[first] = {};
    }
    setObjectProperty(object[first], subPath, value);
    return object;
}

/**
 * Get the value of property specified by dot-notation path
 * @param object {Object} Object to set property of
 * @param path Dot-notation path to the property
 * @param defaultVal Value returned if property not found
 * @return {*}
 */
export function getObjectProperty(object, path, defaultVal) {
    if (path === '') {
        return object;
    }

    const idx = path.indexOf('.');
    if (idx < 0) {
        return object[path] === undefined ? defaultVal : object[path];
    }

    const subPath = path.substr(idx + 1);
    const first = path.substr(0, idx);
    if (object[first] === undefined) {
        return defaultVal;
    }
    return getObjectProperty(object[first], subPath, defaultVal);
}

/**
 * Delete property of object specified by dot-notation path
 * @param object {Object} Object to delete property of
 * @param path {string} Dot-notation path to the property
 * @param deleteEmpty {boolean} Flag to delete empty objects in chain
 * @return {Object} Source object
 */
export function deleteObjectProperty(object, path, deleteEmpty) {
    const idx = path.indexOf('.');

    if (idx < 0) {
        delete object[path];
        return object;
    }

    const subPath = path.substring(idx + 1);
    const first = path.substring(0, idx);
    if (typeof object[first] === 'object' && object[first] !== null) {
        deleteObjectProperty(object[first], subPath, deleteEmpty);
        if (deleteEmpty && Object.keys(object[first]).length === 0) {
            delete object[first];
        }
    } else {
        delete object[first];
    }
    return object;
}

/**
 * Make sure hierarchy exists in specified object.
 * Actually its creating empty object by specified path if it not exists yet.
 * @param object {Object} Object to get/create hierarchy in
 * @param path Dot-notation path to the property
 * @return {Object} object value of specified path
 */
export function ensureHierarchy(object, path) {
    const idx = path.indexOf('.');
    if (idx < 0) {
        if (typeof object[path] !== 'object') {
            object[path] = {};
        }
        return object[path];
    }

    const subPath = path.substr(idx + 1);
    const first = path.substr(0, idx);
    if (typeof object[first] !== 'object') {
        object[first] = {};
    }
    return ensureHierarchy(object[first], subPath);
}

export function assignToHierarchy(object, path, value) {
    const target = ensureHierarchy(object, path);
    Object.assign(target, value);
}

export function mergeToHierarchy(object, path, ...values) {
    const target = ensureHierarchy(object, path);
    mergeTo(target, ...values);
}

/**
 * Recursively copies/clones all enumerable own properties from source object(s) to a target object. It returns the modified target object.
 * @param {object} target - what to apply the sources’ properties to, which is returned after it is modified.
 * @param {object} sources - object containing the properties you want to apply.
 * @returns {object} The target object.
 */
export function assignRecursively(target, ...sources) {
    if (sources.length === 0) return target;

    for (const source of sources) {
        Object.keys(source).forEach(key => {
            if (target[key] === undefined || target[key] === null || typeof target[key] !== 'object') {
                // target is primitive
                if (
                    typeof source[key] === 'object' &&
                    source[key] !== null &&
                    !Array.isArray(source[key]) &&
                    !(source[key] instanceof Promise)
                ) {
                    target[key] = assignRecursively({}, source[key]); // just clone object
                } else {
                    target[key] = source[key];
                }
            } else {
                if (
                    typeof source[key] === 'object' &&
                    source[key] !== null &&
                    !Array.isArray(source[key]) &&
                    !(source[key] instanceof Promise)
                ) {
                    assignRecursively(target[key], source[key]);
                } else {
                    target[key] = source[key]; // source is primitive
                }
            }
        });
    }
    return target;
}

/**
 * Will return a clone of an object.
 * WARNING: It doesn't do deep cloning of functions and prototype chains. Created object is just plain object with all props
 * @param {String|Number|Boolean|Object|Array} input Object to clone
 * @returns {String|Number|Boolean|Object|Array} A clone of the input object
 */
export function clone(input) {
    const type = typeof input;
    if (type === 'function') {
        return input;
    }

    if (type === 'object' && !!input) {
        if (Array.isArray(input)) {
            return input.slice().map(itm => clone(itm));
        }
        const inputProto = Object.getPrototypeOf(input);
        // cloneProto could be only Object.prototype or null. Prototype chain is not allowed
        const cloneProto = inputProto === Object.prototype || inputProto === null ? inputProto : null;
        const result = Object.create(cloneProto);
        for (const key in input) {
            result[key] = clone(input[key]);
        }
        return result;
    }
    return input;
}

/**
 * Recursively merge many objects into one. Used shallow copy where possible
 * @param {object} objs - One or more objects to merge
 * @returns {object} An object whose values are a union of all the argument objects' values.
 */
export function merge(...objs) {
    if (objs.length === 0) return {};
    if (objs.length === 1) return objs[0];

    const merged = {};
    for (let obj of objs) {
        if (obj === undefined) continue; // ignore undefined arguments

        if (Array.isArray(obj)) {
            // Explicitly disallow merging array objects, as it produces nonsense results.
            throw new Error('Cannot merge arrays');
        }
        if (!isPlainObject(obj)) {
            throw new Error('Cannot merge non plain objects');
        }

        const keys = Object.keys(obj);
        for (let key of keys) {
            const sourceVal = obj[key];

            if (sourceVal === undefined) {
                continue; // We ignore keys whose value is undefined
            }

            const targetVal = merged[key];

            let shouldMergeObjects = isObject(targetVal) && isObject(sourceVal);

            merged[key] = shouldMergeObjects ? merge(targetVal, sourceVal) : sourceVal;
        }
    }
    return merged;
}

export function mergeTo(target, ...sources) {
    for (const src of sources) {
        for (const key in src) {
            const sourceVal = src[key];
            if (sourceVal === undefined) {
                continue; // Ignore keys whose value is undefined
            }
            const targetVal = target[key];

            const targetIsObj =
                targetVal !== undefined &&
                targetVal !== null &&
                typeof targetVal === 'object' &&
                !Array.isArray(targetVal) &&
                (!targetVal.__proto__ || targetVal.__proto__ === Object.prototype); // Avoid of merging classes
            const srcIsObj =
                sourceVal !== null &&
                typeof sourceVal === 'object' &&
                !Array.isArray(sourceVal) &&
                (!sourceVal.__proto__ || sourceVal.__proto__ === Object.prototype); // Avoid of merging classes

            if (targetIsObj && srcIsObj) {
                mergeTo(targetVal, sourceVal);
            } else {
                target[key] = sourceVal;
            }
        }
    }
    return target;
}

/**
 * Recursively count object properties
 * @param obj {object}
 */
export function countProperties(obj) {
    let result = 0;
    const keys = Object.keys(obj);
    for (const key of keys) {
        const entity = obj[key];
        if (typeof entity !== 'object') {
            result++;
        } else {
            if (!entity) {
                result++;
            } else {
                if (Object.getPrototypeOf(entity) !== Object.prototype && Object.getPrototypeOf(entity) !== null) {
                    if (Array.isArray(entity)) {
                        result++; // += entity.length;
                    } else {
                        result++;
                    }
                } else {
                    result += countProperties(entity);
                }
            }
        }
    }
    return result;
}

/**
 * Expand flat key-value list to structure.
 * Only make sense for list which keys represent path (have dots)
 * @param keyValues {Object}
 * @returns {Object}
 */
export function expandToStructure(keyValues) {
    const result = {};
    for (const id in keyValues) {
        const value = keyValues[id];
        setObjectProperty(result, id, value);
    }

    return result;
}

export function deepEqual(obj1, obj2) {
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
        return true;
    }
    // Check if both objects are objects and not null.
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }
    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
}
