import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

/**
 *
 * Class offering the possibility to link to promotions.
 *
 * @class PromotionsProvider
 * @extends Mojito.Core.DataProviders.DataProvider
 * @memberof Mojito.Services.Promotions
 */
class PromotionsProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addLocalizedCollection(
            PromotionsProvider.COLLECTION_NAME,
            languageCode
        );
    }

    /**
     * Link to a promotion channel.
     *
     * @param {string} channel - The specific promotion channel to link to (example: desktop|mobile|desktop_auth).
     * @param {Function} setter - Function called when the requested data is updated.
     * @returns {object} Subscription that must be disposed when no longer needed.
     * @function Mojito.Services.Promotions.PromotionsProvider#subscribeToEntity
     */
    subscribeToEntity(channel, setter) {
        const subscription = super.subscribeToEntity(
            channel,
            setter,
            PromotionsProvider.COLLECTION_NAME
        );
        return handleSubscription(subscription, channel, PromotionsProvider.COLLECTION_NAME);
    }
}

PromotionsProvider.COLLECTION_NAME = 'promotions';

export default new PromotionsProvider();
