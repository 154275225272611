import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'items/list';
/**
 *
 * Class offering the possibility to link to generic item lists.
 *
 * @class ItemListProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.ItemList
 */
class ItemListProvider extends DataProvider {
    init() {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME);
    }

    subscribeToEntity(listId, setter) {
        const subscription = super.subscribeToEntity(listId, setter, COLLECTION_NAME);
        return handleSubscription(subscription, listId, COLLECTION_NAME);
    }
}

export default new ItemListProvider();
