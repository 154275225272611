import { useSanitizeHtml } from 'presentation/hooks';

export default function TextView(props) {
    const {
        text,
        header,
        mojitoTools: { style },
    } = props;

    return (
        <div style={style.content} className="TextView">
            {header && (
                <div style={style.header} className="header">
                    {header}
                </div>
            )}
            {text && (
                <div style={style.textArea} className="text">
                    <HTMLText html={text} />
                </div>
            )}
        </div>
    );
}

const HTMLText = ({ html }) => {
    const innerHtml = {
        __html: useSanitizeHtml(html),
    };
    return <span dangerouslySetInnerHTML={innerHtml} />;
};

TextView.getStyle = function (config, applicationMode, merge) {
    return {
        content: merge(config.content.borderStyle, config.content.style),
        header: merge(config.header.textStyle, config.header.borderStyle, config.header.style),
        textArea: merge(
            config.textArea.textStyle,
            config.textArea.borderStyle,
            config.textArea.style
        ),
    };
};
