import PropTypes from 'prop-types';
import TimeOffsetSelectorImpl from './time-offset-selector.jsx';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;
const { types: UserSettingsTypes } = MojitoServices.UserSettings;

/**
 * TimeOffsetSelector component.
 *
 * @class TimeOffsetSelector
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the TimeOffsetSelector.
 *
 * @property {Function} [cbChange = ()=>{}] - Callback function with 'offset' as a parameter.
 * @property {number|string} [selectedTimeOffset = Mojito.Services.UserSettings.types.LOCAL_TIME] - Selected time offset or the string 'localTime' if system time is used.
 *
 * @memberof Mojito.Presentation.Components.TimeOffsetSelector
 */
const propTypes = {
    selectedTimeOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cbChange: PropTypes.func,
};

const defaultProps = {
    cbChange: noop,
    selectedTimeOffset: UserSettingsTypes.LOCAL_TIME,
};

export default UIView('TimeOffsetSelector', TimeOffsetSelectorImpl, propTypes, defaultProps);
