import MojitoCore from 'mojito/core';
import BestOddsGuaranteedPopupImpl from './best-odds-guaranteed-popup.jsx';

/**
 * Best odds guaranteed popup component. Displays a clickable button which opens a popup dialog to show information about the best odds.
 *
 * @class BestOddsGuaranteedPopup
 * @memberof Mojito.Presentation.Components
 */

export default MojitoCore.Presentation.UIView(
    'BestOddsGuaranteedPopup',
    BestOddsGuaranteedPopupImpl
);
