import ResourceHandler from '../resourcehandler/index.jsx';
import {
    selectActiveSlidingViewType,
    selectActiveSlidingViewData,
} from 'application/stores/application/selectors.js';
import { getRouteResolver } from 'application/stores/router/selectors.js';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import AppView from './view/index.jsx';
import Routing from './routing/index.jsx';
import { pick } from 'mojito/utils';
import analyticsEmitter from 'application/analytics/analytics-emitter.js';
import { matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import PerformanceEmitter from 'application/performance/performance-emitter.js';

const reduxInstance = MojitoCore.Services.redux;
const AppContextProvider = MojitoCore.Presentation.AppContext.Context.Provider;
const AppContextBuilder = MojitoCore.Presentation.AppContext.ContextBuilder;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const { PostponedRenderer, LoadingView, ComponentErrorView } = MojitoPresentation.Components;
const { NAVIGATION } = MojitoPresentation.Base.Navigation.types;
const { OVERLAY_INTERACTION } = MojitoPresentation.Base.Intent.Types;
const intentActions = MojitoCore.Intents.actions;
const { useGlobalInteractionMetric } = MojitoModules.Performance.Hooks;
const {
    selectState: selectSystemSettings,
    selectAdditionalContext,
    selectApplicationMode,
    subscribe: subscribeSystemSettings,
} = MojitoCore.Services.SystemSettings.selectors;
const { selectors: userSettingsSelectors, slice: userSettingsSlice } = MojitoServices.UserSettings;
const {
    selectUserSettings,
    selectOddsFormat,
    subscribe: subscribeUserSettings,
} = userSettingsSelectors;
const { STORE_KEY: USER_SETTINGS_STORE_KEY } = userSettingsSlice;
const { selectors: userBonusesSelectors } = MojitoServices.UserBonuses;
const { selectUserInfo } = MojitoServices.UserInfo.selectors;
const { selectActiveFreeBets } = userBonusesSelectors;
const { descriptor: SportsDataDescriptor } = MojitoServices.SportsContent.Sports;

const { selectSport } = MojitoServices.SportsContent.Sports.selectors;
const { selectLoginState } = MojitoServices.Authentication.selectors;

const sportName = sportId => selectSport(sportId)?.name || '';

class AppControllerView extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.isContentShown = (routingData, strict = false) => {
            const pathObject = {
                path: getRouteResolver().getRoute(routingData),
                end: strict || routingData.type === NAVIGATION.HOME, // Should be true for homepage, because it is the highest route in a hierarchy.
            };
            return !!matchPath(pathObject, this.props.location.pathname);
        };

        const uiContextPath = () => {
            const additionalContext = selectAdditionalContext();
            const context = selectApplicationMode();
            return additionalContext ? `${additionalContext}_${context}` : context;
        };

        const appContext = new AppContextBuilder()
            .withSystemSettings(() => selectSystemSettings())
            .withUserSettings(() => selectUserSettings())
            .withSportName(sportName)
            .withGetLoginState(() => selectLoginState())
            .withIsContentShown(this.isContentShown)
            .withGetImage(path => ResourceHandler.getImage(path))
            .withGetFixture(path => ResourceHandler.getFixture(path))
            .withUiContextPath(uiContextPath())
            .withAnalyticsEmitter(analyticsEmitter)
            .withRouteResolver(getRouteResolver())
            .withOverlayInteraction(() =>
                this.props.dispatch(intentActions.publishIntent(OVERLAY_INTERACTION))
            )
            .withPerformanceEmitter(new PerformanceEmitter())
            .withPathname(props.location.pathname)
            .withSuspenseFallback(LoadingView)
            .withErrorFallback(ComponentErrorView)
            .build();

        // Store location.pathname to detect changes in the future.
        this.pathname = props.location.pathname;

        this.state = {
            contextVersion: 0,
            appContext,
            oddsFormat: selectOddsFormat(),
        };
    }

    componentDidMount() {
        this.unsubscribeLanguageChange = subscribeSystemSettings('language', () => {
            // Reload on a microtask to allow other subscribers to execute, e.g. analytics wants to report language change.
            queueMicrotask(() => window.location.reload());
        });
        this.unsubscribeApplicationModeChange = subscribeSystemSettings('applicationMode', () => {
            // Reload on a microtask to allow other potential subscribers to execute.
            queueMicrotask(() => window.location.reload());
        });
        this.unsubscribeAdditionalContextChange = subscribeSystemSettings(
            'additionalContext',
            () => {
                const contextDelta = new AppContextBuilder().withSystemSettings(() =>
                    selectSystemSettings()
                );
                this.updateAppContext(contextDelta);
            }
        );
        this.unsubscribeUserSettings = subscribeUserSettings('oddsFormat', () => {
            // Reload on a microtask to allow other subscribers to execute, e.g. analytics wants to report language change.
            queueMicrotask(() => {
                window.location.reload();
            });
        });
        this.unwatchUserSettings = reduxInstance.watch(USER_SETTINGS_STORE_KEY, () => {
            const contextDelta = new AppContextBuilder().withUserSettings(() =>
                selectUserSettings()
            );
            this.updateAppContext(contextDelta);
        });
        this.controllerHelper().requestData(SportsDataDescriptor.create(this.config.sportListName));
    }

    componentWillUnmount() {
        this.unsubscribeLanguageChange();
        this.unsubscribeApplicationModeChange();
        this.unsubscribeAdditionalContextChange();
        this.unsubscribeUserSettings();
        this.unwatchUserSettings();
    }

    componentDidUpdate() {
        const newPathName = this.props.location.pathname;
        // We can't compare this.props.location with prevProps.location coz it is mutable object.
        if (newPathName !== this.pathname) {
            this.pathname = newPathName;
            const contextDelta = new AppContextBuilder()
                .withIsContentShown(this.isContentShown)
                .withPathname(this.pathname)
                .build();
            // Updating context to force re-render for components interested in appContext.isContentShown().
            this.updateAppContext(contextDelta);
        }
    }

    updateAppContext(newContext) {
        const appContext = {
            ...this.state.appContext,
            ...newContext,
        };
        this.setState({ appContext });
    }

    render() {
        return (
            <PostponedRenderer>
                <AppContextProvider value={this.state.appContext}>
                    <AppViewModule {...this.props} config={this.config} />
                </AppContextProvider>
            </PostponedRenderer>
        );
    }
}

const AppViewModule = props => {
    // Catch user interactions with application
    useGlobalInteractionMetric(props.config.interactionEvents);
    return (
        <AppView
            userInfo={props.userInfo}
            freeBets={props.freeBets}
            selectedSportId={props.params?.sportId}
            activeSlidingViewType={props.activeSlidingViewType}
            activeSlidingViewData={props.activeSlidingViewData}
        >
            <Routing {...pick(props, 'customRoutes')} />
        </AppView>
    );
};

const mapStateToProps = state => ({
    userInfo: selectUserInfo(state),
    freeBets: selectActiveFreeBets(state),
    activeSlidingViewType: selectActiveSlidingViewType(state),
    activeSlidingViewData: selectActiveSlidingViewData(state),
});

export default connect(mapStateToProps)(AppControllerView);
