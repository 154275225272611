import PropTypes from 'prop-types';
import ToggleButtonImpl from './toggle-button.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Toggle button view - presenting a customizable toggle button.
 *
 * @class ToggleButton
 * @memberof Mojito.Presentation.Components
 */

/**
 * Toggle button prop types.
 *
 * @property {boolean} value - True if the toggle button is active, otherwise false.
 * @property {boolean} [disabled = false] - True if the button is disabled, otherwise false.
 * @property {boolean} [pending = false] - True if the button toggle is in progress.
 * @property {Function} onToggle - Callback for toggle button click.
 * @property {string} [class] - Class name applied to the root element.
 * @property {string} label - Label for element.
 *
 * @memberof Mojito.Presentation.Components.ToggleButton
 */
const propTypes = {
    value: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    pending: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    class: PropTypes.string,
    label: PropTypes.string,
};

const defaultProps = {
    disabled: false,
    pending: false,
};

export default UIView('ToggleButton', ToggleButtonImpl, propTypes, defaultProps);
