import MojitoServices from 'mojito/services';

import {mergeToHierarchy} from 'core/utils/config-utils';

import {AbstractVisualFeature, allVisuals} from '../visual-feature';

import {bonusesFactory} from './bonuses.factory';

import SCHEMA from './bonuses.schema.yaml';
import PALETTE_SCHEMA from './bonuses.palette-schema.yaml';

class BonusesFeature extends AbstractVisualFeature {
    get name() {
        return 'Bonuses';
    }

    get configSchema() {
        return SCHEMA;
    }

    get paletteSchema() {
        return PALETTE_SCHEMA;
    }

    getVisualConfig(PALETTE) {
        return bonusesFactory(this, PALETTE);
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        if (this.useBonusService) {
            mergeToHierarchy(mojitoConfig, 'services.bonusService', {
                service: MojitoServices.Bonus.service,
                // Interval used to poll freebets. Set to 0 to disable.
                pollFreeBetsIntervalSec: this.config.pollFreeBetsInterval,
            });
        }
        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    // Internal logic here

    get showVouchersInMenu() {
        return this.config.freebets || this.config.etb;
    }

    get showBonusesInMenu() {
        return this.config.prewager;
    }

    get showAccountPage() {
        return this.config.prewager || this.config.freebets || this.config.etb;
    }

    get showFreeBetCode() {
        return this.config.showFreeBetCode;
    }

    get showPrewager() {
        return this.config.prewager;
    }

    get doSeparateBonusCall() {
        return this.config.acca || this.config.etb;
    }

    get doBonusValidation() {
        return this.config.acca || this.config.etb;
    }

    get useBonusService() {
        return this.config.prewager || this.config.freebets;
    }

    get useBonusOfferService() {
        return this.config.acca || this.config.freebets || this.config.etb;
    }

    get useVoucherService() {
        return this.config.acca || this.config.freebets;
    }

    get refreshBonusOffersInterval() {
        return this.config.refreshBonusOffersInterval;
    }

    get useIMSFreebetBalance() {
        return this.config.useIMSFreebetBalance;
    }
}

export const Bonuses = new BonusesFeature(allVisuals);
