import MojitoNGen from 'mojito/ngen';
import { selectIsTAMode } from 'core/services/system-settings/selectors';

/**
 * A factory class for creating collections in the Mojito NGen repository.
 *
 * @class RepositoryCollectionFactory
 * @name repositoryCollectionFactory
 * @memberof Mojito.Core.Base
 */

/**
 * Use for 'EndpointWithoutLocale' type endpoints.
 *
 * @param {string} collectionKey - The collection key.
 * @param {string} lang - The language.
 * @function Mojito.Core.Base.repositoryCollectionFactory#addSimpleCollection
 */
const addSimpleCollection = function (collectionKey, lang) {
    const locale = selectIsTAMode() ? undefined : lang;
    MojitoNGen.repository.addCollection(collectionKey, {
        networkProvider: MojitoNGen.socketEnablerClient.createNetworkProvider(collectionKey, {
            headerLocale: locale,
        }),
        locale,
    });
};

/**
 * Use for 'EndpointWithLocale' type endpoints.
 *
 * @param {string} collectionKey - The collection key.
 * @param {string} lang - The language.
 * @function Mojito.Core.Base.repositoryCollectionFactory#addLocalizedCollection
 */
const addLocalizedCollection = function (collectionKey, lang) {
    const locale = selectIsTAMode() ? undefined : lang;

    MojitoNGen.repository.addCollection(collectionKey, {
        networkProvider: MojitoNGen.socketEnablerClient.createNetworkProvider(collectionKey, {
            locale,
        }),
        locale,
    });
};

export { addSimpleCollection, addLocalizedCollection };
