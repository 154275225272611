import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import BonusItemsImpl from './bonus-items.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View that renders free bet and odds boost items.
 *
 * @class BonusItems
 * @memberof Mojito.Modules.Bonuses
 */

/**
 * BonusItems prop types.
 *
 * @property {Array} bonusItems - List of free bets or odds bosts.
 * @property {string} [currencyCode] - User currency code.
 *
 * @memberof Mojito.Modules.BonusItems
 */
const propTypes = {
    bonusItems: PropTypes.array.isRequired,
    currencyCode: PropTypes.string,
};

const defaultProps = {
    currencyCode: '',
};

export default UIView('BonusItems', BonusItemsImpl, propTypes, defaultProps);
