import PropTypes from 'prop-types';
import AnimatedButtonImpl from './animated-button.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import AnimatedButtonTypes from './types';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Simple button with text and/or icon that can show animated background when needed.
 *
 * @class AnimatedButton
 * @name component
 * @memberof Mojito.Presentation.Components.AnimatedButton
 */

/**
 * AnimatedButton component prop types.
 *
 * @property {Function} [onClick = ()=>{}] - Callback triggered when the button is clicked.
 * @property {string} state - The current {@link Mojito.Presentation.Components.AnimatedButton.types.STATES|State} of the button.
 * @property {string} [class] - A string containing additional CSS class names for the button's root element.
 * @property {node} [children] - The content to be rendered within the button.
 *
 * @memberof Mojito.Presentation.Components.AnimatedButton.component
 */
const propTypes = {
    onClick: PropTypes.func,
    state: PropTypes.oneOf(Object.values(AnimatedButtonTypes.STATES)).isRequired,
    class: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    onClick: noop,
};

export default UIView('AnimatedButton', AnimatedButtonImpl, propTypes, defaultProps);
