export default {
    /**
     * FreeBetCode component position.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.Bonuses.types
     */
    FREE_BET_CODE_POSITION: {
        ABSOLUTE: 'absolute',
        ON_TAB: 'onTab',
        HIDDEN: 'hidden',
    },
};
