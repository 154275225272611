export function inProgressBonusesFactory(PALETTE, BONUS_ITEM) {
    const {FONT} = PALETTE;

    return {
        InProgressBonuses: {
            bonusContainer: {
                style: {
                    ...BONUS_ITEM.container,
                    borderWidth: 0,
                },
            },
            noBonusesLabel: {
                container: {
                    ...BONUS_ITEM.container,
                    padding: '16px 8px',
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'left',
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
            bonusNameContainer: {
                itemSpacing: 0,
            },
            bonusNameLabel: {
                style: {
                    ...BONUS_ITEM.title,
                },
            },
            prewagerIcon: {
                style: BONUS_ITEM.icon,
            },
            freeBetIcon: {
                style: BONUS_ITEM.icon,
            },
            cashIcon: {
                style: BONUS_ITEM.icon,
            },
            bonusPriceLabel: {
                style: {
                    ...BONUS_ITEM.amount,
                },
            },
            bonusDescription: {
                style: {
                    ...BONUS_ITEM.description,
                },
            },
            stepSubContainerBorder: {
                borderColor: PALETTE.BONUS_ITEM_STEP_SEPARATOR_COLOR,
            },
            stepNumber: {
                style: {
                    ...BONUS_ITEM.step.numberLabelContainer,
                },
            },
            stepNumberLabel: {
                style: {
                    ...BONUS_ITEM.step.numberLabel,
                },
            },
            stepNameLabel: {
                style: {
                    ...BONUS_ITEM.step.title,
                },
            },
            stepStatus: {
                style: {
                    ...BONUS_ITEM.step.status,
                },
            },
            stepDescription: {
                style: {
                    ...BONUS_ITEM.step.description,
                },
            },
            progressBar: BONUS_ITEM.progressBar,
            stepInfo: BONUS_ITEM.progressBar.limitLabel,
        },
    };
}
