import {registerTileSchema} from '#core/application/schema-registry.js';

import SKELETON_PART from './skeleton-part.palette-schema.yaml';

registerTileSchema(SKELETON_PART);

export function generateSkeletonPart(TILE) {
    return {
        fill: TILE.SKELETON_COLOR,
        style: {
            borderRadius: TILE.SKELETON_BORDER_RADIUS,
        },
    };
}
