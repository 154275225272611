import MojitoPresentation from 'mojito/presentation';

const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

export default function ApplicationHeaderSkeleton(props) {
    const {
        mojitoTools: { config },
    } = props;
    return (
        <FlexPane class={'ta-ApplicationHeaderSkeleton'} config={config.container}>
            <FlexPane config={config.buttonsContainer}>
                <SkeletonPart config={config.buttonSkeleton} />
                <SkeletonPart config={config.buttonSkeleton} />
            </FlexPane>
            <FlexPane config={config.navigationBarContainer} />
        </FlexPane>
    );
}
