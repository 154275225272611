import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import EventUtils from './utils.js';
import ServicesTypes from 'services/common/types.js';
import ServicesUtils from 'services/common/utils.js';
import EventTypes from './types.js';
import { omit, isEmpty, keyBy } from 'mojito/utils';
import EventDataDescriptors from './descriptors.js';
import channelFactory from 'services/common/content/content-channel-factory.js';
import eventProvider from 'services/sports-content/content-provider/event-provider';
import marketProvider from 'services/sports-content/content-provider/market-provider';

const reduxInstance = MojitoCore.Services.redux;
const { RACE } = EventTypes.EVENT_TYPE;
const { UNKNOWN, AVAILABLE, UNAVAILABLE } = ServicesTypes.CONTENT_STATE;
const { EVENT, EVENT_CHUNK, EVENT_MARKET, EVENT_MARKET_CHUNK } = EventDataDescriptors.DATA_TYPES;
const { actionsRegistry } = MojitoCore.Services.Content;

export const getEventChannel = () => channelFactory.getChannel(eventProvider, EVENT);
export const getMarketChannel = () => channelFactory.getChannel(marketProvider, EVENT_MARKET);

/**
 * Defines the state of the events store. Contains events and markets.
 *
 * @typedef EventsState
 *
 * @property {object} events - Map of events. Key is event id and value is event content object.
 * @property {object} markets - Map of markets. Key is market id and value is market content object.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} eventsState - Map of events lifetime state. Key is event id and value is of type {@link Mojito.Services.SportsContent.Common.types.CONTENT_STATE|CONTENT_STATE}.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} marketsState - Map of markets lifetime state. Key is market id and value is of type {@link Mojito.Services.SportsContent.Common.types.CONTENT_STATE|CONTENT_STATE}.
 *
 * @memberof Mojito.Services.SportsContent.Events
 */

/**
 * The name of the events store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.Events
 */
export const STORE_KEY = 'eventsStore';

export const INITIAL_STATE = {
    events: {},
    markets: {},
    eventsState: {},
    marketsState: {},
};

const arrangeParticipants = event => {
    const participants = EventUtils.sortParticipants(event.participants, event.homeAway);
    return participants ? { ...event, participants } : event;
};

const reducers = {
    updateEvent(state, { payload }) {
        const { eventId, event } = payload;
        if (event) {
            state.events[eventId] = arrangeParticipants(event);
            state.eventsState[eventId] = AVAILABLE;
        } else if (eventId) {
            delete state.events[eventId];
            state.eventsState[eventId] = UNAVAILABLE;
        }
    },
    updateEvents(state, { payload: eventInfos }) {
        eventInfos.forEach(eventInfo => {
            const payload = { eventId: eventInfo.id, event: eventInfo.data };
            reducers.updateEvent(state, { payload });
        });
    },
    updateMarket(state, { payload }) {
        const { eventId, marketId, market } = payload;
        if (market) {
            // For non-race markets, the order of the selections should be reversed for team or participants markets when
            // homeWay flag is set to false. This happens for american sports.
            const marketCopy = { ...market };
            const event = state.events[eventId];
            const isAwayHome = event && !event.homeAway;
            if (isAwayHome && event.eventType !== RACE) {
                EventUtils.convertToAwayHome(marketCopy);
            }
            marketCopy.selectionMap = keyBy(market.selections || [], 'id');
            state.markets[marketId] = marketCopy;
            state.marketsState[marketId] = AVAILABLE;
        } else {
            delete state.markets[marketId];
            state.marketsState[marketId] = UNAVAILABLE;
        }
    },
    updateMarkets(state, { payload: marketInfos }) {
        marketInfos.forEach(marketInfo => {
            const payload = {
                marketId: marketInfo.id,
                market: marketInfo.data,
                eventId: marketInfo.eventId,
            };
            reducers.updateMarket(state, { payload });
        });
    },
    removeEvents(state, { payload }) {
        const { eventIds = [] } = payload;
        state.events = omit(state.events, eventIds);
        eventIds.forEach(eventId => (state.eventsState[eventId] = UNKNOWN));
    },
    removeMarkets(state, { payload }) {
        const { marketIds = [] } = payload;
        state.markets = omit(state.markets, marketIds);
        marketIds.forEach(eventId => (state.marketsState[eventId] = UNKNOWN));
    },
    pendingEvents: ServicesUtils.createPendingHandler('eventsState'),
    pendingMarkets: ServicesUtils.createPendingHandler('marketsState'),
    reset() {
        return { ...INITIAL_STATE };
    },
};

export const { reducer, actions } = createSlice({
    name: 'events',
    initialState: INITIAL_STATE,
    reducers,
});

/**
 * Events store actions.
 *
 * @class EventsActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.Events
 */

/**
 * Update event.
 *
 * @function updateEvent
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for event update.
 * @param {string} payload.eventId - Event id.
 * @param {object|undefined} payload.event - Event object.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Update market.
 *
 * @function updateMarket
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{eventId: string, marketId: string, market: object|undefined}} payload - Payload for market update.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Remove events.
 *
 * @function removeEvents
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{eventIds: Array<string>}} payload - Payload for remove events.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Remove markets.
 *
 * @function removeMarkets
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{marketIds: Array<string>}} payload - Payload for remove markets.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Pending events.
 *
 * @function pendingEvents
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{eventIds: Array<string>}} payload - Payload contains the ids of pending events.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Pending markets.
 *
 * @function pendingMarkets
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{marketIds: Array<string>}} payload - Payload contains the ids of pending markets.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Reset sports state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.Events.actions
 */

/**
 * Subscribes to events.
 *
 * @function subscribeEvents
 *
 * @param {{clientId: string, eventIds: Array<string>}} payload - Subscription payload. Contains the list of event ids to subscribe to and the id of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to events thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.subscribeEvents = payload => {
    return dispatch => {
        const { clientId, eventIds } = payload;
        const pendingEventIds = getEventChannel().subscribe(
            eventIds,
            clientId,
            (eventId, event) => {
                dispatch(actions.updateEvent({ eventId, event }));
            }
        );
        !isEmpty(pendingEventIds) && dispatch(actions.pendingEvents(pendingEventIds));
    };
};

/**
 * Triggers events chunk subscription for multiple clients.
 *
 * @function subscribeEventsChunk
 *
 * @param {object} payload - Subscription payload. Contains the list of event requests for each client subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to events thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.subscribeEventsChunk = payload => {
    return dispatch => {
        const { requests } = payload;

        const onInit = chunk => dispatch(actions.updateEvents(chunk));
        const onUpdate = (eventId, event) => dispatch(actions.updateEvent({ eventId, event }));

        const pendingEventIds = getEventChannel().subscribeMultiple(requests, onInit, onUpdate);
        queueMicrotask(() => {
            !isEmpty(pendingEventIds) && dispatch(actions.pendingEvents(pendingEventIds));
        });
    };
};

/**
 * Subscribes to markets.
 *
 * @function subscribeMarkets
 *
 * @param {{clientId: string, eventId: string, marketIds: Array<string>}} payload - Subscription payload. Contains the list of market ids to subscribe to, eventId to which markets belongs and the id of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to markets thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.subscribeMarkets = payload => {
    return dispatch => {
        const { clientId, eventId, marketIds } = payload;
        const onData = (marketId, market) => {
            dispatch(actions.updateMarket({ eventId, marketId, market }));
        };
        const pendingMarketIds = getMarketChannel().subscribe(marketIds, clientId, onData);
        !isEmpty(pendingMarketIds) && dispatch(actions.pendingMarkets(pendingMarketIds));
    };
};

/**
 * Triggers market chunk subscription for multiple clients.
 *
 * @function subscribeMarketsChunk
 *
 * @param {object} payload - Subscription payload. Contains the list of market requests for each client subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to events thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.subscribeMarketsChunk = payload => {
    return dispatch => {
        const { requests } = payload;
        const resolveEventId = marketId => {
            const request = requests.find(request => request.marketIds?.includes(marketId));
            return request?.eventId;
        };
        const ensureEventId = chunk =>
            chunk.map(marketInfo => {
                const eventId = resolveEventId(marketInfo.id);
                return { ...marketInfo, eventId };
            });
        const onInit = marketsChunk => {
            const chunk = ensureEventId(marketsChunk);
            dispatch(actions.updateMarkets(chunk));
        };
        const onUpdate = (marketId, market) => {
            dispatch(actions.updateMarket({ eventId: resolveEventId(marketId), marketId, market }));
        };
        const pendingMarketIds = getMarketChannel().subscribeMultiple(requests, onInit, onUpdate);
        queueMicrotask(() => {
            !isEmpty(pendingMarketIds) && dispatch(actions.pendingMarkets(pendingMarketIds));
        });
    };
};

/**
 * Unsubscribe client from all events.
 *
 * @function unsubscribeEvents
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from events thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.unsubscribeEvents = clientId => {
    return dispatch => {
        getEventChannel().unsubscribeAll(clientId, eventIds => {
            dispatch(actions.removeEvents({ eventIds }));
        });
    };
};

/**
 * Unsubscribe client from all markets.
 *
 * @function unsubscribeMarkets
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from markets thunk.
 * @memberof Mojito.Services.SportsContent.Events.actions
 */
actions.unsubscribeMarkets = clientId => {
    return dispatch => {
        getMarketChannel().unsubscribeAll(clientId, marketIds => {
            dispatch(actions.removeMarkets({ marketIds }));
        });
    };
};

// Register thunks in common registry to use them by request data helper.
actionsRegistry.addSubscription(EVENT, actions.subscribeEvents, actions.unsubscribeEvents);
actionsRegistry.addSubscription(EVENT_CHUNK, actions.subscribeEventsChunk);
actionsRegistry.addSubscription(EVENT_MARKET, actions.subscribeMarkets, actions.unsubscribeMarkets);
actionsRegistry.addSubscription(EVENT_MARKET_CHUNK, actions.subscribeMarketsChunk);

reduxInstance.injectReducer(STORE_KEY, reducer);
