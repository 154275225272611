import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import BonusHistoryTypes from './types.js';

const {
    Image,
    FlexPane,
    DateTime,
    Text,
    BetFunds: { GenericBetFundsInfo },
    Spinner,
    GridLayout,
} = MojitoPresentation.Components;
const { GridPane, GridSeparator } = GridLayout;
const BonusStatus = MojitoServices.Bonus.types.STATUS;
const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;
const { CurrencyHelper } = MojitoPresentation.Utils;
const { UIViewImplementation } = MojitoCore.Presentation;
const { HEADER_COLUMNS } = BonusHistoryTypes;

const bonusToIconName = {
    [BonusType.FREEBET]: 'freeBetIcon',
    [BonusType.PREWAGER]: 'prewagerIcon',
    [BonusType.CASH]: 'cashIcon',
    [BonusType.ODDS_BOOST]: 'oddsBoostIcon',
    [BonusType.ODDS_MULTIPLIER]: 'oddsBoostIcon',
};

export default class BonusHistory extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.getItemExpireText = this.getItemExpireText.bind(this);
        this.renderBonusItem = this.renderBonusItem.bind(this);
        this.renderCompactBonusItem = this.renderCompactBonusItem.bind(this);
        this.renderCompactModeContent = this.renderCompactModeContent.bind(this);
        this.renderers = {
            [HEADER_COLUMNS.NAME]: this.renderNameColumn.bind(this),
            [HEADER_COLUMNS.DESCRIPTION]: this.renderDescriptionColumn.bind(this),
            [HEADER_COLUMNS.EXPIRY]: this.renderExpiryColumn.bind(this),
            [HEADER_COLUMNS.TOTAL]: this.renderTotalColumn.bind(this),
            [HEADER_COLUMNS.REMAINING]: this.renderRemainingColumn.bind(this),
            [HEADER_COLUMNS.STATUS]: this.renderStatusColumn.bind(this),
        };
    }

    getBonusDescription(bonus) {
        let description;

        if (bonus.bonusType === BonusType.ODDS_BOOST) {
            if (bonus.percent) {
                description = this.resolveAndFormatString(
                    '$BONUS_ITEM.ODDS_BOOST.MULTIPLIER_DESCRIPTION',
                    `${bonus.percent}`
                );
            } else {
                description = this.resolveAndFormatString('$BONUS_ITEM.ODDS_BOOST.DESCRIPTION');
            }
        } else {
            description = bonus.description;
        }

        return description;
    }

    getItemExpireText(expireDate, hasExpired) {
        const expireLabel = hasExpired
            ? this.resolveString('$BONUS_HISTORY.EXPIRED')
            : this.resolveString('$BONUS_HISTORY.EXPIRY');

        return this.formatString(expireLabel, expireDate);
    }

    resolveItemStatusLabel(status) {
        switch (status) {
            case BonusStatus.ACTIVE:
                return this.resolveString('$BONUS_HISTORY.STATUS.ACTIVE');
            case BonusStatus.AWARDED:
                return this.resolveString('$BONUS_HISTORY.STATUS.AWARDED');
            case BonusStatus.CANCELLED:
                return this.resolveString('$BONUS_HISTORY.STATUS.CANCELLED');
            case BonusStatus.EXPIRED:
                return this.resolveString('$BONUS_HISTORY.STATUS.EXPIRED');
            case BonusStatus.COMPLETE:
                return this.resolveString('$BONUS_HISTORY.STATUS.COMPLETE');
            case BonusStatus.REDEEMED:
                return this.resolveString('$BONUS_HISTORY.STATUS.REDEEMED');
            case BonusStatus.SUSPENDED:
                return this.resolveString('$BONUS_HISTORY.STATUS.SUSPENDED');
            case BonusStatus.USED:
                return this.resolveString('$BONUS_HISTORY.STATUS.USED');
            default:
                return this.resolveString('$BONUS_HISTORY.STATUS.UNKNOWN');
        }
    }

    getHeaderStyle(key) {
        const headerStyle = {
            [HEADER_COLUMNS.TOTAL]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.REMAINING]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.STATUS]: 'headerStyleCenter',
        };
        const headerDefaultStyle = 'headerStyle';
        return this.config[headerStyle[key]] || this.config[headerDefaultStyle];
    }

    renderGridHeader() {
        return this.config.headerColumns.map(columnName => {
            const headerStyle = this.getHeaderStyle(columnName);
            return (
                <Text config={headerStyle} key={columnName}>
                    {this.resolveString(`$BONUS_HISTORY.HEADERS.${columnName.toUpperCase()}`)}
                </Text>
            );
        });
    }

    renderNameColumn(bonus) {
        const bonusIcon = bonusToIconName[bonus.bonusType];

        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.NAME}
            >
                {bonusIcon && <Image config={this.config[bonusIcon]} />}
                <Text config={this.config.textItem}>{bonus.name}</Text>
            </FlexPane>
        );
    }

    renderDescriptionColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.DESCRIPTION}
            >
                <Text config={this.config.textItem}>{this.getBonusDescription(bonus)}</Text>
            </FlexPane>
        );
    }

    renderExpiryColumn(bonus) {
        const expireDate = bonus.expireDate;
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.EXPIRY}
            >
                <Text config={this.config.textItem}>
                    {expireDate !== undefined ? (
                        <DateTime config={this.config.expireDateTimeFormat} dateTime={expireDate} />
                    ) : undefined}
                </Text>
            </FlexPane>
        );
    }

    renderTotalColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.TOTAL}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-BonusTotalAmount">
                    {CurrencyHelper.formatCurrency(bonus.amount || 0, this.props.currencyCode)}
                </Text>
            </FlexPane>
        );
    }

    renderRemainingColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.REMAINING}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-BonusRemainingAmount">
                    {CurrencyHelper.formatCurrency(bonus.remaining || 0, this.props.currencyCode)}
                </Text>
            </FlexPane>
        );
    }

    renderStatusColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-bonusInfo"
                key={bonus._id + HEADER_COLUMNS.STATUS}
            >
                <Text config={this.config.textItemCenter}>
                    {this.resolveItemStatusLabel(bonus.status)}
                </Text>
            </FlexPane>
        );
    }

    renderGridContent(bonus) {
        return this.config.headerColumns.map(column => this.renderers[column](bonus));
    }

    renderBonusItem(item) {
        return (
            <GridSeparator config={this.config.gridSeparator} key={item.id}>
                {this.renderGridContent(item)}
            </GridSeparator>
        );
    }

    renderCompactBonusItem(item) {
        const { id, name, status, amount = 0, remaining = 0, expireDate, bonusType } = item;
        const bonusIcon = bonusToIconName[bonusType];

        return (
            <FlexPane config={this.config.compactItem.container} key={id} class="item">
                <FlexPane config={this.config.compactItem.headerContainer}>
                    <FlexPane config={this.config.compactItem.nameDateContainer}>
                        <FlexPane config={this.config.compactItem.nameContainer}>
                            {bonusIcon && <Image config={this.config[bonusIcon]} />}
                            <Text config={this.config.bonusTitle}>{name}</Text>
                        </FlexPane>
                        {expireDate && (
                            <DateTime
                                config={this.config.expireDateTimeFormat}
                                dateTime={expireDate}
                                dateTimeRenderer={this.getItemExpireText}
                                rendererData={!expireDate || new Date(expireDate) < new Date()}
                            />
                        )}
                    </FlexPane>
                    <Text config={this.config.compactItem.status}>
                        {this.resolveItemStatusLabel(status)}
                    </Text>
                </FlexPane>
                <Text config={this.config.compactItem.description}>
                    {this.getBonusDescription(item)}
                </Text>
                <GenericBetFundsInfo
                    config={
                        remaining > 0
                            ? this.config.compactItem.amount
                            : this.config.compactItem.highlightedAmount
                    }
                    label={this.resolveString('$BONUS_HISTORY.TOTAL_VALUE')}
                    valueClass="ta-BonusTotalAmount"
                    value={CurrencyHelper.formatCurrency(amount, this.props.currencyCode)}
                />
                {remaining > 0 && (
                    <GenericBetFundsInfo
                        config={this.config.compactItem.remainingAmount}
                        label={this.resolveString('$BONUS_HISTORY.REMAINING_VALUE')}
                        valueClass="ta-RemainingAmount"
                        value={CurrencyHelper.formatCurrency(remaining, this.props.currencyCode)}
                    />
                )}
            </FlexPane>
        );
    }

    renderEmptyContent() {
        return (
            <Text config={this.config.compactNoBonusesLabel} class="ta-noBonusesFoundText">
                {this.resolveString('$BONUS_HISTORY.NO_BONUSES_FOUND_LABEL')}
            </Text>
        );
    }

    renderDataLoader() {
        return (
            <FlexPane config={this.config.spinnerContainer}>
                <Text config={this.config.spinnerLabel} class="ta-fetchingBonusesHistoryText">
                    {this.resolveString('$BONUS_HISTORY.BONUSES_ARE_LOADING')}
                </Text>
                <Spinner config={this.config.spinner} />
            </FlexPane>
        );
    }

    renderCompactModeContent(bonuses) {
        if (this.props.bonusHistoryLoading) {
            return this.renderDataLoader();
        }

        // Opting out early if we don't have any bonuses AND don't intend to tell the user about it
        if (!bonuses.length && !this.config.showCompactEmptyContent) {
            return null;
        }

        return (
            <FlexPane config={this.config.compactItemList}>
                {!bonuses.length && this.renderEmptyContent()}
                {Boolean(bonuses.length) && bonuses.map(this.renderCompactBonusItem)}
            </FlexPane>
        );
    }

    render() {
        const freeBets = this.props.freeBets.map(freeBet => ({
            ...freeBet,
            bonusType: BonusType.FREEBET,
        }));

        const bonuses = this.props.campaigns
            .concat(this.props.oddsBoosts)
            .concat(freeBets)
            .sort((a, b) => a.expireDate - b.expireDate);

        if (this.config.compactMode) {
            return this.renderCompactModeContent(bonuses);
        }

        const header = this.renderGridHeader();

        return (
            <GridPane class="BonusHistory" config={this.config.listContainer}>
                {header}
                {bonuses.map(this.renderBonusItem)}
            </GridPane>
        );
    }
}
