import MojitoServices from 'mojito/services';
import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {getDetectedHostURL} from 'core/utils/url-utils';
import {WidgetsProviderService} from './widgets-provider';
import {DrivenSSOService} from './embedded-app/driven-sso-service';
import {DBXPerformanceService} from 'core/services/performance-reporting-service';

export const AUT_METHOD = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

// Dummy App feature, that will be as parent of other features
class ApplicationFeature extends AbstractFeature {
    get name() {
        return 'Application';
    }
    // eslint-disable-next-line no-unused-vars
    setupConfigs(globalConfig, options, filterFn) {
        super.setupConfigs(...arguments);
        this.authMethod = options.authMethod;
    }

    getServicesConfig() {
        return {
            transactionUrl: this.config.apiUrl,
            pdfServiceUrl: this.config.apiUrl,
            bootstrapService: MojitoServices.Bootstrap.service,
            promotionsService: {
                service: MojitoServices.Promotions.service,
            },
            betsService: {
                service: MojitoServices.Bets.service,
                openBets: {
                    timePeriodInYears: 1,
                    pollIntervalSec: 150,

                    // Number of seconds to stop polling open bets after cashout before polling restarts. Defaults to 5.
                    //pollDelayAfterCashoutSec: 5,
                }, // Moved to 'core/cashout' feature
                // cashout: { },
                getAssignedTickets: false,
                payoutEnabled: false,
            },
            matchAccaService: {
                service: MojitoServices.MatchAcca.matchAccaService,
            },
            videoStreamingService: {
                service: MojitoServices.VideoStreaming.service,
            },
            priceChangePolicy: 'ALLOW_ALL',
            searchService: MojitoServices.Search.searchService,
            performanceService: {
                service: DBXPerformanceService,
                // config: {} // Nothing to configure here. DBXPerformanceService could be configured in runtime
            },
            stores: {
                betslip: {}, // Moved to 'core/cashout' feature
                // bets: { },
                betslipProposals: {},
                recentlyVisited: {
                    eventMaxCount: 5,
                },
                content: {
                    contentKeepAliveTime: 5 * 60 * 1000, // Keep data cached for 30 minutes
                },
                userInfo: {},
                overask: {},
                device: {},
                promotions: {
                    // Genbet doesnt support polling
                    pollOnLoggedIn: !GENBET_BASED,
                },
                search: {
                    recentHistory: {
                        maxSize: 10,
                        maxDays: 180,
                    },
                },
            },
            userInfoService: {
                service: MojitoServices.UserInfo.service,
                // balanceServices: is moved to corresponding features
            },
            authenticationService: getAuthServiceConfig(this.authMethod),
        };
    }
}

function getAuthServiceConfig(authMethod) {
    if (!BUILT_IN_AUTHORIZATION) {
        // No authentication available at all
        return {
            service: MojitoServices.Authentication.service,
            ssoImplementation: DrivenSSOService,
        };
    }
    if (WidgetsProviderService.getWidgetData().noAuthenticationService) {
        // 1. This is a widget
        // 2. It doesn't require authentication service
        return undefined;
    }

    return {
        service: MojitoServices.Authentication.service,
        redirectUrl: `${getDetectedHostURL()}/resetpassword`,
        ssoImplementation: authMethod === AUT_METHOD.EXTERNAL ? DrivenSSOService : undefined,
    };
}

export const Application = new ApplicationFeature(allFeatures);
