/**
 * Market group types.
 *
 * @class MarketGroupTypes
 * @name types
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */
export default class MarketGroupTypes {}

/**
 * Market group types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.MarketGroups.types
 */
MarketGroupTypes.GROUP_TYPES = {
    /** Group contains markets that can be combined in match acca bet. */
    MATCH_ACCA: 'MATCH_ACCA',
    /** Group type used for generic market grouping on event details page, e.g. 'Top markets', 'Handicap markets', 'Player markets', 'All markets' etc. */
    GENERIC: 'GENERIC',
    /** Group type that contains supported racing market(s). */
    RACE_MARKET: 'RACE_MARKET',
    /** Group contains markets that are already combined into prebuilt match acca bets. */
    PREBUILT_MATCH_ACCA: 'PREBUILT_MATCH_ACCA',
    /** Group type that contains fast markets. */
    FAST_MARKETS: 'FAST_MARKETS',
};

/**
 * Aggregated market types. Defines the shape and nature of the market on UI.
 * This values are populated on a client.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.MarketGroups.types
 */
MarketGroupTypes.AGGREGATED_MARKET_TYPE = {
    /** Represents generic market. Typically displayed on event details view, e.g. "Over/Under Total Goals", "Match Result", "Double Chance" etc. */
    GENERIC: 'GENERIC',
    /** Game line aggregated market. Typically part of view for american sports. Usually contains up to three market lines: Spread, Money and Total. */
    GAME_LINE: 'GAME_LINE',
    /** Goal scorer aggregated market. Usually contains up to five market lines: First, Anytime, Last, 2+ and Hat-Trick. */
    GOAL_SCORER: 'GOAL_SCORER',
    /** Correct score aggregated market. Special market separated in 2 views to select the score individually or from the view to see all the scores available. */
    CORRECT_SCORE: 'CORRECT_SCORE',
    /** Aggregated market contains market lines with selections that have boosted prices. */
    PRICE_BOOST: 'PRICE_BOOST',
    /** Aggregated market contains market lines with selections that have handicap. */
    HANDICAP: 'HANDICAP',
};

/**
 * Markets line type. Reflects the type of market line within marketIds list.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.MarketGroups.types
 */
MarketGroupTypes.LINE_TYPE = {
    FIRST: 'FIRST',
    ANYTIME: 'ANYTIME',
    LAST: 'LAST',
    TWO_OR_MORE: 'PLUS2',
    HAT_TRICK: 'HAT_TRICK',
};

/**
 * Represents an aggregated market object descriptor containing one or more market lines and can be used to preview markets in long lists.
 * The actual market objects, typically loaded on demand (for example, when a pane is expanded), are not included within the AggregatedMarketInfo.
 * The presentation of the aggregated market in the UI changes depending on its type.
 *
 * @typedef AggregatedMarketInfo
 * @type {object}
 *
 * @property {string} id - The unique identifier of the aggregated market.
 * @property {string} name - The name of the aggregated market.
 * @property {Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE} type - The type of the aggregated market. This property is populated on the client side.
 * @property {Array<string>} marketIds - An array of market IDs that make up a particular aggregated market.
 * @property {Array<Mojito.Services.SportsContent.MarketGroups.types.LINE_TYPE>} mask - A mask of market lines, indicating the type of market line within the marketIds list.
 * @property {boolean} matchAcca - Indicates whether the market can be used to combine match accumulator bets.
 * @property {Array<string>} fallbackMarketIds - An array of fallback market IDs. This is a subset of marketIds. If not empty, these markets are typically presented on coupons in a specific way.
 * FallbackMarketIds are generally available only when no main markets are active, providing an alternative for a user on a coupon.
 * @property {number} displayOrder - The display order of the market.
 *
 * @memberof Mojito.Services.SportsContent.MarketGroups.types
 */
