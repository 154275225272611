import MojitoCore from 'mojito/core';
import Overlay from 'presentation/components/overlay/index.jsx';
import SlidingPaneTypes from './types';
import ScrollPane from 'presentation/components/scroll-pane/index.jsx';
import ImageButton from 'presentation/components/image-button/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import { omitBy } from 'mojito/utils';

const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

const SLIDING_SIDE_TO_CLOSED_TRANSFORM = {
    [SlidingPaneTypes.SLIDING_SIDE.LEFT]: 'translateX(-100%)',
    [SlidingPaneTypes.SLIDING_SIDE.RIGHT]: 'translateX(100%)',
    [SlidingPaneTypes.SLIDING_SIDE.TOP]: 'translateY(-100%)',
    [SlidingPaneTypes.SLIDING_SIDE.BOTTOM]: 'translateY(100%)',
};

export default class SlidingPane extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.animationTimeout = undefined;
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.clearAnimationTimeout();
            this.animationTimeout = setTimeout(() => {
                this.animationTimeout = undefined;
                const { isOpen, onOpened, onClosed } = this.props;
                const callback = isOpen ? onOpened : onClosed;
                callback();
            }, this.config.animationDuration);
        }
    }

    componentWillUnmount() {
        this.clearAnimationTimeout();
    }

    onContentClick(event) {
        // Stop event from bubbling up to overlay
        event.stopPropagation();
    }

    clearAnimationTimeout() {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
            this.animationTimeout = undefined;
        }
    }

    renderContent() {
        return (
            <div
                style={this.style.childrenContainer}
                onClick={this.onContentClick}
                className="ta-slidingPane"
            >
                {this.config.isScrollable ? (
                    <ScrollPane config={this.config.scrollPane}>{this.props.children}</ScrollPane>
                ) : (
                    this.props.children
                )}
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton() {
        if (!this.props.isOpen || this.config.hideCloseButton) {
            return null;
        }

        return (
            <AbsolutePane config={this.config.closeButtonContainer}>
                <ImageButton
                    class="ta-slidingPane-closeButton"
                    onClick={this.props.onCloseClick}
                    config={this.config.closeButton}
                />
            </AbsolutePane>
        );
    }

    render() {
        // If we don't have rendered content, we render once in closed state, to set the transition's initial value
        const containerStyle = this.props.isOpen
            ? this.style.container
            : this.style.containerWithTransform;

        return (
            <Overlay
                onClicked={this.props.onClickOutside}
                visible={this.props.isOpen}
                config={this.config.overlay}
            >
                <div
                    style={containerStyle}
                    className={`ta-slidingPaneContainer ta-${this.config.slidingSide}`}
                >
                    {this.renderContent()}
                </div>
            </Overlay>
        );
    }
}

SlidingPane.getStyle = function (config, applicationMode, merge) {
    const { size, offset, childrenContainer, ...restStyles } = config.style;
    const vertical =
        config.slidingSide === SlidingPaneTypes.SLIDING_SIDE.TOP ||
        config.slidingSide === SlidingPaneTypes.SLIDING_SIDE.BOTTOM;
    const container = merge(
        {
            position: 'fixed',
            [config.slidingSide]: 0,
            [vertical ? 'left' : 'top']: 0,
            width: vertical ? '100%' : size,
            height: vertical ? size : '100%',
            transition: `transform ${config.animationDuration}`,
            willChange: 'transform',
            boxSizing: 'border-box',
            ...omitBy(restStyles, val => val === undefined),
        },
        offset
    );

    return {
        container,
        childrenContainer: { ...childrenContainer },
        containerWithTransform: merge(
            { transform: SLIDING_SIDE_TO_CLOSED_TRANSFORM[config.slidingSide] },
            container
        ),
    };
};
