import { createRef } from 'react';
import MojitoCore from 'mojito/core';
import HideablePane from 'presentation/components/hideable-pane/index.jsx';

const { resizeDetector } = MojitoCore.Base.resizeDetector;

export default class SwitchPane extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.contentWrapperRef = createRef();

        this.state = {
            contentHeight: undefined,
        };

        if (this.config.transitionContainerHeight) {
            this.onContentSizeChange = contentWrapperEl =>
                this.setState({ contentHeight: contentWrapperEl.offsetHeight });
        }
    }

    componentDidMount() {
        if (this.config.transitionContainerHeight) {
            resizeDetector.listen(this.contentWrapperRef.current, this.onContentSizeChange);
        }
    }

    componentWillUnmount() {
        if (this.config.transitionContainerHeight) {
            resizeDetector.unlisten(this.contentWrapperRef.current);
        }
    }

    renderChild(child) {
        return (
            <HideablePane
                hidden={child.key !== this.props.selectedKey}
                config={this.style.container}
                key={child.key}
            >
                {child}
            </HideablePane>
        );
    }

    render() {
        const outerContainerStyle = this.config.transitionContainerHeight
            ? {
                  ...this.style.outerContainer,
                  height: this.state.contentHeight,
              }
            : this.style.outerContainer;

        return (
            <div style={outerContainerStyle}>
                <div style={this.style.contentWrapper} ref={this.contentWrapperRef}>
                    {this.props.children.map(this.renderChild, this)}
                </div>
            </div>
        );
    }
}

SwitchPane.getStyle = config => {
    return {
        outerContainer: {
            display: 'flex',
            flex: '1 1 auto',
            ...(config.transitionContainerHeight && {
                overflow: 'hidden',
                transition: 'height 0.2s ease-out',
                willChange: 'height',
            }),
        },
        contentWrapper: {
            position: 'relative',
            display: 'flex',
            flex: '1 1 auto',
            // this was added for my account popup tabs to scroll properly
            minHeight: '100%',
            minWidth: '100%',
        },
        container: {
            ...config.container,
            absolutePositionWhenHidden: true,
        },
    };
};
