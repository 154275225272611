import MojitoCore from 'mojito/core';
import CheckboxImpl from './checkbox.jsx';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * `CheckBox` is a component used to render a checkbox.
 *
 * @class CheckBox
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Checkbox` component prop types.
 *
 * @property {Function} onClick - Callback function to be triggered when the checkbox is clicked.
 * @property {boolean} checked - Boolean to control the display of the check icon.
 * @property {boolean} enabled - Boolean to control checkbox interactivity (clickable or not).
 * @property {string} [label] - Optional label for the checkbox.
 *
 * @memberof Mojito.Presentation.Components.Checkbox
 */
const propTypes = {
    onClick: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string,
};

export default UIView('Checkbox', CheckboxImpl, propTypes);
