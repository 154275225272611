import { isNil, pick, keyBy } from 'mojito/utils';
import EventTypes from 'services/sports-content/events/types.js';
import UserSettingsTypes from 'services/user-settings/types.js';

const {
    CLOCK_DIRECTION,
    EVENT_TYPE,
    TEAM_OR_PARTICIPANT_TYPES,
    MARKET_TYPE_CATEGORY,
    PARTICIPANT_POSITION,
} = EventTypes;

const mapMarketsOnLineIds = (markets, marketIds) => {
    const marketsMap = keyBy(markets, ({ id }) => id);
    return marketIds.map(marketId => marketsMap[marketId]);
};

/**
 * Event data model utilities.
 *
 * @class EventUtils
 * @name utils
 * @memberof Mojito.Services.SportsContent.Events
 */
export default class EventUtils {
    /**
     * Checks if event type is Outright.
     *
     * @param {string} eventType - Event type.
     *
     * @returns {boolean} True if it is an outright.
     * @function Mojito.Services.SportsContent.Events.utils.isOutrightEventType
     */
    static isOutrightEventType(eventType) {
        return eventType === EVENT_TYPE.OUTRIGHT;
    }

    /**
     * Checks if events of the specified type are considered as match.
     *
     * @param {string} eventType - Event type.
     *
     * @returns {boolean} True if considered match event.
     * @function Mojito.Services.SportsContent.Events.utils.isMatchEventType
     */
    static isMatchEventType(eventType) {
        return eventType === EVENT_TYPE.MATCH;
    }

    /**
     * Checks if the status of a market is active.
     *
     * @param {string} marketStatus - Parent market status.
     *
     * @returns {boolean} True if market's status should be considered to be active.
     * @function Mojito.Services.SportsContent.Events.utils.marketIsActive
     */
    static marketIsActive(marketStatus) {
        return marketStatus === EventUtils.STATUS.ACTIVE;
    }

    /**
     * Checks if a selection with the specified status and ancestor statuses
     * is considered suspended.
     * <br>
     * If at least one of the statuses is considered suspended, the the selection is considered suspended.
     *
     * @param {string} selectionStatus - Selection status.
     * @param {string} marketStatus - Parent market status.
     * @param {string} eventStatus - Ancestor event status.
     *
     * @returns {boolean} True if selection should be considered to be suspended.
     * @function Mojito.Services.SportsContent.Events.utils.selectionIsSuspended
     */
    static selectionIsSuspended(selectionStatus, marketStatus, eventStatus) {
        return (
            marketStatus === EventUtils.STATUS.SUSPENDED ||
            eventStatus === EventUtils.STATUS.SUSPENDED ||
            selectionStatus === EventUtils.STATUS.SUSPENDED
        );
    }

    /**
     * Checks if the specified prices array has any prices.
     *
     * @param {object[]} prices - Array of prices.
     *
     * @returns {boolean} True if array has at least one price.
     * @function Mojito.Services.SportsContent.Events.utils.pricesAreAvailable
     */
    static pricesAreAvailable(prices) {
        return Boolean(EventUtils.getPrice(prices));
    }

    /**
     * Get available price by price type from prices list.
     * Returns first "non-disabled price" if no priceType provided.
     *
     * @param {object[]} prices - Array of prices.
     * @param {Mojito.Services.Bets.types.PRICE_TYPE} [priceType] - Price type. If not provided then the first available will be used.
     *
     * @returns {object|undefined} The price or undefined if no available price found.
     * @function Mojito.Services.SportsContent.Events.utils.getPrice
     */
    static getPrice(prices, priceType) {
        if (priceType) {
            return prices?.find(price => price.type === priceType && !price.disabled);
        }
        return prices?.find(price => !price.disabled);
    }

    /**
     * Checks if the specified test price is higher than the reference price.
     * Returns false, if one or more prices are not defined.
     *
     * @param {object} refPrice - Reference price object.
     * @param {object} testPrice - Test price object.
     *
     * @returns {boolean} True if the test price is higher than the reference price, else false.
     * @function Mojito.Services.SportsContent.Events.utils.priceIsHigher
     */
    static priceIsHigher(refPrice, testPrice) {
        if (!refPrice || !testPrice) {
            return false;
        }

        return Number.parseFloat(testPrice.decimalLabel) > Number.parseFloat(refPrice.decimalLabel);
    }

    /**
     * Sort the participants of an event according to which should be displayed first, home team or away team.
     *
     * @param {Array|undefined} participants - List to be sorted.
     * @param {boolean} homeAway - True if home team should be first in list.
     *
     * @returns {Array} List of sorted participants.
     *
     * @function Mojito.Services.SportsContent.Events.utils.sortEventParticipants
     */
    static sortParticipants(participants, homeAway) {
        const { AWAY, HOME } = PARTICIPANT_POSITION;
        return participants?.toSorted((p1, p2) => {
            if (p1.position === HOME && p2.position === AWAY) {
                return homeAway ? -1 : 1;
            }
            if (p1.position === AWAY && p2.position === HOME) {
                return homeAway ? 1 : -1;
            }
            return 0;
        });
    }

    /**
     * Check if event has three participants.
     *
     * @param {object} event - Event to check for three participants.
     *
     * @function Mojito.Services.SportsContent.Events.utils.isThreeParticipantsEvent
     * @returns {boolean} True if event has three participants, otherwise false.
     */

    static isThreeParticipantsEvent(event) {
        const { participants } = event;
        return participants?.length === 3;
    }

    /**
     * Check if market with marketId for event is an each way market.
     *
     * @param {object} event - Event containing market with id marketId.
     * @param {string} marketId - Id of market to check if it is each way.
     *
     * @function Mojito.Services.SportsContent.Events.utils.isEachWayMarket
     * @returns {boolean} True if market has each way terms, otherwise false.
     */
    static isEachWayMarket(event, marketId) {
        const market = event && event.markets && event.markets[marketId];
        return !!market && !!market.eachWayTerms;
    }

    /**
     * Check if an event provides all
     * {@link Mojito.Services.SportsContent.Events.types.IN_PLAY_CLOCK_DETAILS|in play clock details}. The clock data
     * can typically be used to control an in play clock for the event.
     *
     * @param {object} event - Event to check for in play clock data.
     *
     * @returns {boolean} True if the event has all required in play clock data, otherwise false.
     * @function Mojito.Services.SportsContent.Events.utils.hasInPlayClockDetails
     */
    static hasInPlayClockDetails(event) {
        const { details } = event || {};
        const { timeInformation, clockDirection, isClockRunning } = details || {};
        return (
            !isNil(timeInformation) &&
            !isNil(isClockRunning) &&
            !isNil(CLOCK_DIRECTION[clockDirection])
        );
    }

    /**
     * Get time from in play details time information. Adjusts the time according to when it started.
     *
     * @param {{ time: number, validAt: number }} timeInfo - In play details time information.
     * @param {boolean} isClockRunning - Boolean indicating if clock is running. If not running, validAt won't be considered.
     * @param {Mojito.Services.SportsContent.Events.types.CLOCK_DIRECTION} clockDirection - Clock direction.
     *
     * @returns {number} Time.
     */
    static calculateTime(timeInfo, isClockRunning, clockDirection) {
        const { time = 0, validAt } = timeInfo || {};
        const countingDown = clockDirection === CLOCK_DIRECTION.B;
        // validAt will be the seconds from unix time if this is provided.
        let diff = 0;
        if (validAt && isClockRunning) {
            const currentDate = new Date();
            const secondsSinceEpoch = Math.round(currentDate.getTime() / 1000);
            diff = secondsSinceEpoch - validAt;
        }
        const timeWhenCountingDown = time - diff < 0 ? 0 : time - diff;
        const timeWhenCountingUp = time + diff;
        return countingDown ? timeWhenCountingDown : timeWhenCountingUp;
    }

    /**
     * Returns odds from selection based on provided <code>oddsFormat</code>.
     *
     * @param {object} selection - Selection content object.
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format value.
     * @param {boolean} [isBaseOdds = false] - Should get base selection odds.
     *
     * @returns {string} Odds from selection according to provided <code>oddsFormat</code>.
     * @function Mojito.Services.SportsContent.Events.utils.getSelectionOdds
     */
    static getSelectionOdds(selection, oddsFormat, isBaseOdds = false) {
        const { ODDS_FORMAT } = UserSettingsTypes;
        const price =
            (isBaseOdds ? selection.basePrice : EventUtils.getPrice(selection.prices)) || {};
        const { decimalLabel, fractionalLabel, americanLabel } = price;
        const oddsMap = {
            [ODDS_FORMAT.DECIMAL]: decimalLabel,
            [ODDS_FORMAT.FRACTIONAL]: fractionalLabel,
            [ODDS_FORMAT.AMERICAN]: americanLabel,
        };
        return oddsMap[oddsFormat];
    }

    /**
     * Finds selection object by id inside <code>market.selections</code>.
     *
     * @param {object} market - Market content object.
     * @param {string} selectionId - Selection id.
     *
     * @returns {object} Selection content object.
     * @function Mojito.Services.SportsContent.Events.utils.getSelectionFromMarket
     */
    static getSelectionFromMarket(market, selectionId) {
        const { selections } = market || {};
        return selections?.find(selection => selection.id === selectionId);
    }

    /**
     * If market category belongs to TEAM_OR_PARTICIPANT_TYPES then it's selections will be reversed.
     * This is typically how markets are presented for american sports where first selection belongs to away team and last selection to home team.
     * Note: this function mutates market object.
     *
     * @param {object} market - Market object.
     *
     * @function Mojito.Services.SportsContent.Events.utils.convertToAwayHome
     */
    static convertToAwayHome(market) {
        const isTeamOrParticipant = TEAM_OR_PARTICIPANT_TYPES.includes(market.typeCategory);
        if (isTeamOrParticipant && market.selections) {
            market.selections.reverse();
        }
    }

    /**
     * Checks if market belongs to 'Home Draw Away' type. Means that it is three way
     * and can have 1X2 selections set.
     *
     * @param {object} market - Market to check.
     *
     * @returns {boolean} Returns true if market is 'Home Draw Away'.
     * @function Mojito.Services.SportsContent.Events.utils.isHDAMarket
     */
    static isHDAMarket(market) {
        return market.typeCategory === MARKET_TYPE_CATEGORY.TEAM_HDA;
    }

    /**
     * Checks if market belongs either to 'Home Draw Away' or 'Over Exactly Under' type category.
     * Means that it is three way and can have '1' 'X' '2' or 'O' 'E' 'U' selections set.
     *
     * @param {object} market - Market to check.
     *
     * @returns {boolean} Returns true if market is 'Home Draw Away' or 'Over Exactly Under'.
     * @function Mojito.Services.SportsContent.Events.utils.isThreeWayMarket
     */
    static isThreeWayMarket(market) {
        if (!market) {
            return false;
        }
        const { OVER_EXACTLY_UNDER } = MARKET_TYPE_CATEGORY;
        const { typeCategory } = market;
        return EventUtils.isHDAMarket(market) || typeCategory === OVER_EXACTLY_UNDER;
    }

    /**
     * Creates aggregated markets object. Handy to be used for rendering.
     *
     * @param {Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo} marketInfo - Aggregated market descriptor object. Defines market lines that should be aggregated and generic market meta info.
     * @param {Array<object>} markets - List of market objects to be aggregated.
     *
     * @returns {Mojito.Services.SportsContent.Events.types.AggregatedMarket|undefined} Aggregated market with the list of markets and additional meta info.
     * @function Mojito.Services.SportsContent.Events.utils.createAggregatedMarket
     */
    static createAggregatedMarket(marketInfo, markets) {
        if (!marketInfo) {
            return;
        }

        return {
            ...pick(marketInfo, 'name', 'type', 'displayOrder', 'mostBalancedLineMarketId'),
            key: marketInfo.id,
            markets: mapMarketsOnLineIds(markets, marketInfo.marketIds),
            eachWayTerms: markets[0]?.eachWayTerms,
            mask: marketInfo.mask,
        };
    }
}

EventUtils.STATUS = {
    UNKNOWN: 'UNKNOWN',
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
};
