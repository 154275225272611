import PropTypes from 'prop-types';
import TabbedPaneImpl from './tabbed-pane.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * TabbedPane is a layout component designed to provide a declarative means for displaying a set
 * of selectable options, each one associated with different content. Only the content of the selected
 * option is visible at any given time.
 *
 * The component is more versatile than its name suggests; it can be used to implement a main menu,
 * a side menu, a slideshow component, or a filter view, among other things.
 *
 * Due to React's rendering process, extra care is needed to ensure that the content of unselected
 * tabs isn't rendered. In general, if the children of the TabbedPane tabs are nothing but other
 * components, everything will work as expected. However, if there's any inline function invocation
 * in the JSX code, it will be executed unconditionally.
 *
 * A console warning is generated in debug mode whenever unnecessary rendering occurs. If there's
 * no warning in the console upon rendering the TabbedPane, everything should be fine. In some
 * cases, it's acceptable to render all tab content unconditionally, for example, when the content
 * isn't complex enough to impact performance.
 *
 * In such instances, the warning can be silenced by setting the
 * 'TabbedPane.contentContainer.container.warnAboutUnconditionallyRenderedContent' configuration
 * value to false. The example below demonstrates unconditional rendering and how to convert it to
 * conditional rendering.
 *
 * In the following example, 'renderSomething0' will be called unconditionally on every render, whereas
 * 'renderSomething1' gets called only when its parent tab is selected. See the 'Renderable'
 * component for more details.
 *
 * @example <TabbedPane>
 *   <TabbedPaneTab>
 *     {this.renderSomething0()}
 *   </TabbedPaneTab>
 *   <TabbedPaneTab>
 *     <Renderable content={this.renderSomething1} context={this} />
 *   </TabbedPaneTab>
 * </TabbedPane>
 *
 * @class TabbedPane
 * @memberof Mojito.Presentation.Components
 */
const propTypes = {
    selectedKey: PropTypes.string,
    onSelectionChange: PropTypes.func,
    children: PropTypes.node,
};

const defaultProps = {
    onSelectionChange: noop,
};

export default MojitoCore.Presentation.UIView(
    'TabbedPane',
    TabbedPaneImpl,
    propTypes,
    defaultProps
);
