import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {DebugFlags} from 'core/application/debug';
import {DBXConsolePerformanceLogger} from './abstract-performance-logger';
import './mojito-patching.js';
import {Logger} from '#core/utils/logger.js';

const reduxInstance = MojitoCore.Services.redux;
const bootstrapActions = MojitoServices.Bootstrap.actions;
const {AbstractPerformanceService, types} = MojitoServices.Performance;
const {TIMELINE_RECORD_TYPES} = types;
const log = Logger('DBXPerformanceService');

/*eslint no-console: "off"  */
class DBXPerformanceServiceImpl extends AbstractPerformanceService {
    constructor() {
        super();
        this.loggers = [];
        this.initialLoading = true;
        this.tags = {}; // Public
        this.report = this.report.bind(this);
        this.statsHasBeenSentForPath = '';

        this.wsOpenedSubscriptionsMap = new Map();
        this.wsUsedSubscriptionsMap = new Map();

        this.checkIfAllSubscriptionsFinished = this.checkIfAllSubscriptionsFinished.bind(this);
        this.timeoutId = 0;

        this._allTimings = new Map();
        this._allDurations = new Map();
        this._allMetrics = new Map();

        this.resetStats();
    }

    /**
     * Should be called from outside when to allow sending collected metrics to the external storage or 3rd party services.
     * For example activate the service only after user gave cookie consent.
     *
     */
    activate() {
        // Do nothing.
        // Activeness is depending on added loggers.
        // Cookie consent is ignored (atm).
    }

    configure() {
        // Do nothing.
        // All configurations could be accepted in runtime
    }

    /**
     * This method should be called from outside when transition from one page to another happened.
     *
     * @param {string} newRoute - The route of a new page where a user was just navigated to.
     */
    navigate(newRoute) {
        try {
            if (newRoute === this.pathname) return;
            if (this.statsHasBeenSentForPath !== this.pathname && this.statsHasBeenSentForPath !== '') {
                this.logWSActivityFinished();
            }
            this.resetStats(newRoute);
            this.statsHasBeenSentForPath = '';
        } catch (e) {
            // Just to re-ensure it not break mojito flow
        }
    }

    report(type, payload) {
        log.debug('%cReport:', Logger.STYLE_DEBUG, type, payload);
        try {
            if (this.statsHasBeenSentForPath === this.pathname) return;
            if (type === TIMELINE_RECORD_TYPES.WS_MESSAGE) {
                this.addWSRecord(payload);
            }
        } catch (e) {
            // Just to re-ensure it not break mojito flow
        }
    }

    // ====================================
    addLogger(logger) {
        if (this.loggers.indexOf(logger) >= 0) return; // already added
        this.loggers.push(logger);

        // Report to newly added logger everything we collected before
        logger.setTags && logger.setTags(this.tags);
        this._allTimings.forEach((value, key) => logger.reportTiming && logger.reportTiming(key, value));
        this._allDurations.forEach((value, key) => logger.reportDuration && logger.reportDuration(key, value));
        this._allMetrics.forEach(
            (value, key) => logger.reportMetric && logger.reportMetric(key, value.value, value.unit)
        );
    }

    removeLogger(logger) {
        const idx = this.loggers.indexOf(logger);
        if (idx >= 0) this.loggers.splice(idx, 1);
    }

    resetAll() {
        this.loggers = [];
        this.resetStats();
    }

    reportTiming(name, timestampInMs) {
        if (typeof timestampInMs !== 'number' || !isFinite(timestampInMs)) return; // durationInMs is not a Number
        this._allTimings.set(name, timestampInMs);
        for (let logger of this.loggers) {
            try {
                logger.reportTiming && logger.reportTiming(name, timestampInMs);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportDuration(name, durationInMs) {
        if (typeof durationInMs !== 'number' || !isFinite(durationInMs)) return; // durationInMs is not a Number
        this._allDurations.set(name, durationInMs);
        for (let logger of this.loggers) {
            try {
                logger.reportDuration && logger.reportDuration(name, durationInMs);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportMetric(name, value, unit) {
        if (!unit) return; // it is mandatory
        this._allMetrics.set(name, {value, unit});
        for (let logger of this.loggers) {
            try {
                logger.reportMetric && logger.reportMetric(name, value, unit);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportSystemMeasurementStartTime(perfName, reportName) {
        const measure = performance.getEntriesByName(perfName);
        if (measure.length <= 0) return;
        const value = measure[0].startTime;
        this.reportTiming(reportName, value);
    }

    reportSystemMeasurementDuration(perfName, reportName) {
        const measure = performance.getEntriesByName(perfName);
        if (measure.length <= 0) return;
        const value = measure[0].duration;
        this.reportDuration(reportName, value);
    }
    // ====================================
    setTags(tags) {
        Object.assign(this.tags, tags);
        for (let logger of this.loggers) {
            try {
                logger.setTags && logger.setTags(this.tags);
            } catch (e) {
                // do nothing
            }
        }
    }
    // ====================================

    resetStats(newRoute) {
        this.pathname = newRoute || window.location.pathname;
        this.lastByteTimestamp = performance.timeOrigin;
        this.firstSubscriptionTimestamp = -1;
        this.firstByteTimestamp = -1;
        this.wsOpenedSubscriptionsMap.clear();
        this.wsUsedSubscriptionsMap.clear();
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = 0;

        this._allTimings.clear();
        this._allDurations.clear();
        this._allMetrics.clear();
    }

    /**
     * @private
     * @param payload {object}
     */
    addWSRecord(payload) {
        if (IS_LOCAL && payload.collection === 'promotions') return;

        const id = payload.collection + '/' + payload.id;
        if ('wsLatency' in payload) {
            // Response
            if (this.wsOpenedSubscriptionsMap.has(id)) {
                // Got response on subscription
                if (this.firstByteTimestamp < 0) {
                    this.firstByteTimestamp = performance.now();
                }

                const sub = this.wsOpenedSubscriptionsMap.get(id);
                this.wsOpenedSubscriptionsMap.delete(id); // Track only first response
                const duration = performance.now() - sub.timestamp;
                performance.measure(`WS - ${id}`, {start: sub.timestamp});

                const result = this.wsUsedSubscriptionsMap.get(payload.collection) || {
                    size: 0,
                    maxDuration: 0,
                    minDuration: 99999,
                    subscriptionsCount: 0,
                };

                // result.size += sub.size + getIncomingMessageSize(payload.collection, payload.id, payload.data || '');
                result.minDuration = Math.min(result.minDuration, duration);
                result.maxDuration = Math.max(result.maxDuration, duration);
                result.subscriptionsCount++;

                this.wsUsedSubscriptionsMap.set(payload.collection, result);
                this.lastByteTimestamp = performance.now();

                if (this.wsOpenedSubscriptionsMap.size === 0) {
                    if (this.timeoutId) clearTimeout(this.timeoutId);
                    this.timeoutId = setTimeout(this.checkIfAllSubscriptionsFinished, 500);
                }
            } else {
                // Response is already processed, or not tracked. Ignore this
            }
        } else {
            // New subscription
            this.wsOpenedSubscriptionsMap.set(id, {
                timestamp: performance.now(),
                size: getOutgoingMessageSize(payload.collection, payload.id),
            });

            if (this.firstSubscriptionTimestamp < 0) {
                this.firstSubscriptionTimestamp = performance.now();
                performance.mark('WSActivityStarted');
                this.logWSActivityStarted();
            }
        }
    }
    checkIfAllSubscriptionsFinished() {
        if (this.wsOpenedSubscriptionsMap.size === 0) {
            // If all subscriptions are finished
            this.reportCollectedData();
            this.logWSActivityFinished();
            performance.mark('WSActivityFinished');
            this.statsHasBeenSentForPath = this.pathname;

            this.resetStats();
        }
        this.timeoutId = 0;
    }

    /**
     * @private
     */
    reportCollectedData() {
        const wsCollections = Object.fromEntries(this.wsUsedSubscriptionsMap);
        let totalWSSize = 0;
        let totalWSSubscriptions = 0;
        let totalWSDuration = Math.round(this.lastByteTimestamp - this.firstByteTimestamp);
        this.wsUsedSubscriptionsMap.forEach(value => {
            totalWSSize += value.size;
            totalWSSubscriptions += value.subscriptionsCount;
        });
        const result = {
            path: this.pathname,
            totalWSSize,
            totalWSDuration,
            timeToFirstWSSubscription: Math.round(this.firstSubscriptionTimestamp),
            timeToFirstWSByte: Math.round(this.firstByteTimestamp),
            totalWSSubscriptions,
            staleWSSubscriptions: this.wsOpenedSubscriptionsMap.size,
            wsCollections,
            initialLoading: this.initialLoading,
        };
        if (!this.initialLoading) delete result.timeToFirstByte;

        this.logWSActivityStatistics(result);

        this.initialLoading = false;
    }

    /**
     * @private
     */
    logWSActivityStarted() {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityStarted && logger.wsActivityStarted(this.pathname);
            } catch (e) {
                // do nothing
            }
        }
    }

    /**
     * @private
     */
    logWSActivityFinished() {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityMostlyFinished && logger.wsActivityMostlyFinished(this.pathname);
            } catch (e) {
                // do nothing
            }
        }
    }

    /**
     * @private
     * @param data {object}
     */
    logWSActivityStatistics(data) {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityData && logger.wsActivityData(data);
            } catch (e) {
                // do nothing
            }
        }
    }
}

export const DBXPerformanceService = new DBXPerformanceServiceImpl();

function handleVisibilityChange() {
    if (document.visibilityState === 'hidden') {
        performance.mark('Sportsbook page is hidden');
    } else if (document.visibilityState === 'visible') {
        performance.mark('Sportsbook page is visible');
    }
}

const GO_ONLINE = 'Network change: on-line';
const GO_OFFLINE = 'Network change: off-line';
function handleNetworkChange() {
    if (window.navigator.onLine) {
        // Report as soon as we went online
        // This event triggered only if was offline before
        performance.mark(GO_ONLINE);
        performance.measure('OFFLINE', GO_OFFLINE, GO_ONLINE);
    } else {
        performance.mark(GO_OFFLINE);
    }
}
document.addEventListener('visibilitychange', handleVisibilityChange);
window.addEventListener('online', handleNetworkChange);
window.addEventListener('offline', handleNetworkChange);

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    effect: () => {
        DBXPerformanceService.resetAll();
    },
});

if (DebugFlags.allLogs) {
    DBXPerformanceService.addLogger(new DBXConsolePerformanceLogger());
}

// ---------------

// const INCOMING_MESSAGE_PAYLOAD_SIZE =
//     'a["MESSAGE\\nid://\\nlocale:en\\ndestination:/user/request-response\\ntype:FULL\\ncontent-type:application/json\\nsubscription:/user/request-response\\nmessage-id:cxrrnoyh-000000\\ncontent-length:XXXX\\n\\n\u0000"]'
//         .length;
const OUTGOING_MESSAGE_PAYLOAD_SIZE = '["SUBSCRIBE\\nid:/api//\\nlocale:en\\ndestination:/api//\\n\\n\u0000"]'.length;

// function getIncomingMessageSize(collection, id, data) {
//     return INCOMING_MESSAGE_PAYLOAD_SIZE + collection.length + id.length + JSON.stringify(data).length;
// }

function getOutgoingMessageSize(collection, id) {
    return OUTGOING_MESSAGE_PAYLOAD_SIZE + collection.length * 2 + id.length * 2;
}
