import {getActiveSpan, getGlobalScope, getRootSpan, setMeasurement, startInactiveSpan} from '@sentry/react';
import {DBXAbstractPerformanceLogger} from 'core/services/performance-reporting-service/abstract-performance-logger';

// Sentry has limitation of 10 measurements per transaction
// We need to filter out some of them to not exceed this limit
// TODO: Find better solution
const allowedMeasurements = [
    'dbx.js_load_time',
    'dbx.init.start',
    'dbx.init.duration',
    'dbx.render_start',
    'dbx.moj_timings.get_config_duration',

    'dbx.initial_page.ws_duration',
    'dbx.initial_page.ws_ttfb',
    'dbx.initial_page.ws_ttfs',
    'dbx.initial_page.full_load_time',

    'dbx.page.ws_duration',
];
class PerfLoggerImpl extends DBXAbstractPerformanceLogger {
    constructor() {
        super();
        this.span = null;
        this.initializedOnSportsbookUrl = false;
    }

    setTags(tags) {
        super.setTags(tags);
        this.initializedOnSportsbookUrl = this.tags.initializedOnSportsbookUrl;
        delete this.tags.initializedOnSportsbookUrl;

        getGlobalScope().setTags(this.tags);
    }

    reportTiming(name, timestamp) {
        if (!this.initializedOnSportsbookUrl) {
            // Sportsbook is initialized on some 'casino' url
            // Ignore this
            return;
        }
        this.reportMetric(name, timestamp, 'millisecond');
    }

    reportDuration(name, duration) {
        if (!this.initializedOnSportsbookUrl) {
            // Sportsbook is initialized on some 'casino' url
            // Ignore this
            return;
        }
        this.reportMetric(name, duration, 'millisecond');
    }

    reportMetric(name, value, unit) {
        if (!this.initializedOnSportsbookUrl) {
            // Sportsbook is initialized on some 'casino' url
            // Ignore this
            return;
        }

        const transformedName = name.replaceAll('-', '_');

        const activeSpan = getActiveSpan();
        const rootSpan = activeSpan ? getRootSpan(activeSpan) : undefined;
        rootSpan?.setAttribute(transformedName, value);

        if (!allowedMeasurements.includes(name)) return; // push only allowed measurements
        setMeasurement(transformedName, value, unit);
    }

    /**
     * Called when first WS subscription created
     * @param path {string}
     */
    wsActivityStarted(path) {
        if (this.span) {
            // Should not fall here, but just to be reinsured
            this.span.end();
            this.span = null;
        }
        if (!this.initializedOnSportsbookUrl) return;

        this.span = startInactiveSpan({name: `WS fetching data for ${path}`, onlyIfParent: true});
    }

    /**
     * Called to log collected performance statistics
     * @param data {object}
     */
    wsActivityData(data) {
        if (!this.span || this.span.endTimestamp) return; // Span is ended. Do not report anything
        if (
            data.totalWSDuration > 30000 ||
            (data.initialLoading && data.timeToFirstWSByte > 30000) ||
            (data.initialLoading && !this.initializedOnSportsbookUrl)
        ) {
            // User probably lock phone during WS fetching
            // Or sportsbook is initialized on some 'casino' url
            // Ignore these stats, as it is not representative
            return;
        }

        if (data.initialLoading) {
            this.reportMetric('dbx.initial_page.ws_duration', data.totalWSDuration, 'millisecond');
            // this.reportMetric('dbx.initial_page.ws_transferred_bytes', data.totalWSSize, 'byte'); // mojito is stopped reporting it
            this.reportMetric('dbx.initial_page.ws_ttfb', data.timeToFirstWSByte, 'millisecond'); // From page navigation
            this.reportMetric('dbx.initial_page.ws_ttfs', data.timeToFirstWSSubscription, 'millisecond');
            this.reportMetric('dbx.initial_page.ws_subscriptions_count', data.totalWSSubscriptions);
            this.reportMetric('dbx.initial_page.ws_stale_subscriptions_count', data.staleWSSubscriptions);
            this.reportMetric(
                'dbx.initial_page.full_load_time',
                data.timeToFirstWSByte + data.totalWSDuration,
                'millisecond'
            );

            if (this.span) {
                this.span.setAttributes({
                    ws_duration: data.totalWSDuration,
                    ws_ttfb: data.timeToFirstWSByte,
                    ws_ttfs: data.timeToFirstWSSubscription,
                    ws_subscriptions_count: data.totalWSSubscriptions,
                    ws_stale_subscriptions_count: data.staleWSSubscriptions,
                });
            }
        } else {
            this.reportMetric('dbx.page.ws_duration', data.totalWSDuration, 'millisecond');
            // this.reportMetric('dbx.page.ws_transferred_bytes', data.totalWSSize, 'byte');
            this.reportMetric('dbx.page.ws_subscriptions_count', data.totalWSSubscriptions);
            this.reportMetric('dbx.page.ws_stale_subscriptions_count', data.staleWSSubscriptions);

            if (this.span) {
                this.span.setAttributes({
                    ws_duration: data.totalWSDuration,
                    ws_subscriptions_count: data.totalWSSubscriptions,
                    ws_stale_subscriptions_count: data.staleWSSubscriptions,
                });
            }
        }
    }

    /**
     * Called when all subscriptions received at least one response.
     * It also may be called to close corresponding wsActivityStarted, even if no websocket activity
     */
    wsActivityMostlyFinished() {
        this.span && this.span.end();
        this.span = null;
    }
}

export const DBXSentryPerformanceLogger = new PerfLoggerImpl();
