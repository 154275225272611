import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import BettingHelper from 'presentation/formatters/betting/index.js';

const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

export default class FormattedLabelWithPrice extends MojitoCore.Presentation.UIViewImplementation {
    shouldComponentUpdate(nextProps) {
        return !isShallowEqual(this.props, nextProps);
    }

    render() {
        return (
            <FlexPane config={this.config.container}>
                <Text config={this.config.label}>{this.props.label}</Text>
                <Text config={this.config.value}>
                    {BettingHelper.formatPayout(
                        this.props.price,
                        this.props.valueNotAvailableLabel ||
                            this.resolveString('$FORMATTED_LABEL_WITH_PRICE.VALUE_NOT_AVAILABLE'),
                        this.props.currency
                    )}
                </Text>
            </FlexPane>
        );
    }
}
