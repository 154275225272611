import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'eventgroups';

/**
 *
 * Class offering the possibility to link to specific event group.
 *
 * @class EventGroupProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.EventGroups
 */
class EventGroupProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(eventGroupId, onUpdate) {
        const subscription = super.subscribeToEntity(eventGroupId, onUpdate, COLLECTION_NAME);
        return handleSubscription(subscription, eventGroupId, COLLECTION_NAME);
    }

    subscribeToEntities(eventGroupIds, onInit, onUpdate) {
        const subscriptionInfo = super.subscribeToEntities(
            eventGroupIds,
            onInit,
            onUpdate,
            COLLECTION_NAME
        );
        return handleSubscription(
            subscriptionInfo,
            eventGroupIds.join('-'),
            COLLECTION_NAME,
            false
        );
    }
}

export default new EventGroupProvider();
