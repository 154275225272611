import MojitoCore from 'mojito/core';
import { connect } from 'react-redux';
import MojitoPresentation from 'mojito/presentation';
import BannerPromotion from 'modules/promotions/banners/banner-promotion/index.jsx';
import PromotionUtils from 'modules/promotions/utils/promotion-utils.js';
import BannerSkeletons from 'modules/promotions/banners/banner-skeletons/index.jsx';
import { range } from 'mojito/utils';

const { SkeletonPart, LoaderSuspense, FlexPane, Swiper } = MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;
const { useIrreversibleTrue } = MojitoPresentation.Hooks;
const intentActions = MojitoCore.Intents.actions;
const log = MojitoCore.logger.get('BannerPromotionsView');

class BannerPromotionsView extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.onPromotionClicked = this.onPromotionClicked.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.promotions !== prevProps.promotions) {
            const bannerPromotions = this.getBannerPromotions();
            if (!bannerPromotions.length || this.shouldShowCarousel(bannerPromotions)) {
                return;
            }
            this.props.onPrepareBannersToRender(range(bannerPromotions.length));
        }
    }

    onPromotionClicked(promotion) {
        const { dispatch } = this.props;
        const intent = PromotionUtils.resolveIntentFromPromotion(promotion);

        if (intent) {
            dispatch(intentActions.publishIntent(intent.type, intent.data));
        }
        this.emitAnalytics('promotionClicked', {
            locationId: this.props.locationId,
            bannerName: promotion.linkText,
        });
    }

    showAsSingleImage() {
        return this.props.promotions.length === 1 && this.config.stacked;
    }

    shouldShowCarousel(bannerPromotions) {
        return bannerPromotions.length > 0 && this.config.stacked;
    }

    _renderHeader(label) {
        if (!label) {
            return null;
        }

        return (
            <div style={this.style.header} className={`ta-header-${label}`}>
                {label}
            </div>
        );
    }

    renderCarousel() {
        const bannerPromotions = this.getBannerPromotions();
        if (!bannerPromotions.length) {
            return null;
        }

        return this.shouldShowCarousel(bannerPromotions) ? (
            <Swiper
                onPrepareSlidesToRender={this.props.onPrepareBannersToRender}
                config={this.config.swiper}
            >
                {bannerPromotions}
            </Swiper>
        ) : (
            bannerPromotions.map(promotionRenderer => promotionRenderer(true))
        );
    }

    getBannerPromotions() {
        const bannerConfig = this.config.stacked
            ? this.config.banner
            : this.config.unstackedSingleBanner;
        return (
            this.props.promotions
                // eslint-disable-next-line react/display-name
                .map(promotion => shouldLoad => (
                    <LazyBannerPromotion
                        readyToLoad={shouldLoad}
                        config={bannerConfig}
                        promotion={promotion}
                        key={promotion.id}
                        shouldRequestData={this.props.shouldRequestData}
                        onClick={() => this.onPromotionClicked(promotion)}
                    />
                ))
        );
    }

    render() {
        const showAsSingleImage = this.showAsSingleImage();
        const content = this.renderCarousel();

        const { promotionsLoaded } = this.props;

        const containerConfig = showAsSingleImage
            ? this.config.style.singleBannerContainer
            : this.style.container;

        const label = this.resolveString(this.config.label);
        const skeleton = (
            <FlexPane config={this.config.skeletonsContainer}>
                {label && <SkeletonPart config={this.config.headerSkeleton} />}
                <BannerSkeletons config={this.config.bannerSkeletons} />
            </FlexPane>
        );
        return (
            <FlexPane config={this.config.mainContainer}>
                <LoaderSuspense
                    isContentPending={!promotionsLoaded}
                    config={this.config.skeletonLoader}
                    loader={skeleton}
                >
                    {content && (
                        <div style={containerConfig} className="ta-BannerPromotionsView">
                            {label && this._renderHeader(label)}
                            {content}
                        </div>
                    )}
                </LoaderSuspense>
            </FlexPane>
        );
    }
}

const LazyBannerPromotion = props => {
    const { config, promotion, readyToLoad, onClick, shouldRequestData } = props;
    const shouldShowPromotion = useIrreversibleTrue(readyToLoad);
    // The banners are lazy loadable. Once user is swiping the banners the swiper will inform us that banner is approaching the view port.
    // Swiper will send readyToLoad:true prop in this case. Once it is true we will send promotion object to the banner component.
    // The banner will use it to load image and render itself. The aim is not to load images until user is intended to see it.
    return (
        <BannerPromotion
            config={config}
            promotion={shouldShowPromotion ? promotion : {}}
            key={promotion.id}
            onClick={onClick}
            shouldRequestData={shouldRequestData}
        />
    );
};

BannerPromotionsView.getStyle = function (config, applicationMode, merge) {
    const containerBase = config.style.container;

    let containerStyle;
    switch (config.itemsLayout) {
        case 'vertical':
            containerStyle = merge(containerBase, {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            });
            break;

        case 'horizontal':
            containerStyle = merge(containerBase, {
                display: 'flex',
                alignItems: 'center',
            });
            break;

        default:
            log.warn('Unsupported items layout: ', config.itemsLayout);
            break;
    }

    const style = {
        container: containerStyle,
        header: merge(config.style.header, {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
        }),
    };

    return style;
};

export default connect()(BannerPromotionsView);
