import MojitoCore from 'mojito/core';
import Text from 'presentation/components/text/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Popover from 'presentation/components/popover/index.jsx';

const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;

const BestOddsGuaranteedPopup = props => {
    const { mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;

    if (config.hide) {
        return null;
    }

    return (
        <Popover
            signpostContent={<SignPostContent mojitoTools={mojitoTools} />}
            class="ta-bestOddsGuaranteedPopup"
            config={config.popover}
        >
            <Text config={config.contentText}>
                {stringResolver.resolveString('$BEST_ODDS_GUARANTEED_POPUP.CONTENT')}
            </Text>
            {config.intent && (
                <Button
                    config={config.linkButton}
                    onClick={() =>
                        dispatch(
                            intentActions.publishIntent(config.intent.type, config.intent.data)
                        )
                    }
                >
                    <Text config={config.linkText}>
                        {stringResolver.resolveString('$BEST_ODDS_GUARANTEED_POPUP.LINK_TEXT')}
                    </Text>
                </Button>
            )}
        </Popover>
    );
};

const SignPostContent = ({ mojitoTools }) => {
    const { config, stringResolver } = mojitoTools;

    return (
        <>
            <Image config={config.bestOddsGuaranteedIcon} class="ta-BestOddsGuaranteedIcon" />
            <Text config={config.text}>
                {stringResolver.resolveString('$BEST_ODDS_GUARANTEED_POPUP.TEXT')}
            </Text>
        </>
    );
};

export default BestOddsGuaranteedPopup;
