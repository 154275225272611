import MarketGroupSelectorSkeletonImpl from './market-group-selector-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * The component works as a preloading component for MarketGroupSelector to show while the content is loading.
 *
 * @function MarketGroupSelectorSkeleton
 *
 * @memberof Mojito.Presentation.Components
 */

export default UIView('MarketGroupSelectorSkeleton', MarketGroupSelectorSkeletonImpl);
