import serviceFactory from 'services/performance/service/service-factory';
import performanceTypes from 'services/performance/types';

const { TIMELINE_RECORD_TYPES } = performanceTypes;

/**
 * Decorates the DataProvider's subscription with an additional callback and logs performance marks to the performance service timeline.
 *
 * @param {object} subscription - The DataProvider subscription to be decorated with an additional callback.
 * @param {string} id - The identifier of the entity for which the subscription is created.
 * @param {string} collection - The name of the collection associated with the entity.
 * @param {boolean} [shouldReportData = true] - True if initial received data should be sent as a part of report.
 * @returns {object} Returns the DataProvider subscription decorated with an additional promise callback.
 */
export function handleSubscription(subscription, id, collection, shouldReportData = true) {
    const performanceService = serviceFactory.getService();
    const requestTimestamp = Date.now();

    // Report outgoing message
    performanceService.report(TIMELINE_RECORD_TYPES.WS_MESSAGE, {
        collection,
        id,
    });

    subscription.promise.then(initialData => {
        // Report incoming message with initial data
        const data = shouldReportData ? initialData : undefined;
        performanceService.report(TIMELINE_RECORD_TYPES.WS_MESSAGE, {
            collection,
            id,
            data,
            wsLatency: Date.now() - requestTimestamp,
        });
    });

    return subscription;
}

export default {
    handleSubscription,
};
