import MojitoCore from 'mojito/core';
import ExpandableView from 'presentation/components/expandableview/index.jsx';
import SelectableButton from 'presentation/components/selectable-button/index.jsx';
import SelectableButtonGroup from 'presentation/components/selectable-button-group/index.jsx';
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class LanguageSelector extends UIViewImplementation {
    constructor(component) {
        super(component);
        this.onButtonClicked = this.onButtonClicked.bind(this);
    }

    onButtonClicked(languageCode) {
        this.props.onLanguageChange(languageCode);
    }

    renderLanguageButtonList(languages, selectedLanguage) {
        const selectableButtons = languages.map(item => (
            <SelectableButton
                key={item.languageCode}
                selectableKey={item.languageCode}
                class={`ta-MenuItem ta-MenuItem_${item.languageCode}`}
            >
                {item.name}
            </SelectableButton>
        ));

        return (
            <SelectableButtonGroup
                selectedKey={selectedLanguage.languageCode}
                onSelectionChange={this.onButtonClicked}
                config={this.config.languageButtons}
            >
                {selectableButtons}
            </SelectableButtonGroup>
        );
    }

    render() {
        const { languages, selectedLanguageCode } = this.props;
        let selectedLanguageName;

        const selectedLanguage = languages.find(function (language) {
            return language.languageCode === selectedLanguageCode;
        });

        if (selectedLanguage && selectedLanguage.hasOwnProperty('name')) {
            selectedLanguageName = selectedLanguage.name;
        }

        return (
            <ExpandableView
                headerText={selectedLanguageName}
                config={this.config.expandable}
                class="ta-LanguageSelector"
            >
                {this.renderLanguageButtonList(languages, selectedLanguage)}
            </ExpandableView>
        );
    }
}
