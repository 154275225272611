import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import serviceFactory from 'services/bonus/service/service-factory.js';
import { isLoggedIn as isLoggedInSelector } from 'services/authentication/selectors.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';

const reduxInstance = MojitoCore.Services.redux;
const log = MojitoCore.logger.get('BonusCampaignStore');
const BonusTypes = MojitoServices.Bonus.types;
const { BONUS_FILTER } = BonusTypes;

/**
 * Defines the state of the bonus campaign tracking store.
 *
 * @typedef BonusCampaignState
 *
 * @property {boolean} isPending - Defines if campaigns request was made but response not received yet.
 * @property {object} campaignsByFilter - Mapping {Mojito.Services.Bonus.types.BONUS_FILTER} to {Mojito.Services.Bonus.types.Campaign}.
 *
 * @memberof Mojito.Services.BonusCampaign
 */

/**
 * The name of the bonus campaign store. It is used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.BonusCampaign.slice
 */
export const STORE_KEY = 'bonusCampaignStore';

export const initialState = { isPending: false, campaignsByFilter: {} };

/**
 * The action for resetting store to initial state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.BonusCampaign.actions
 */

/**
 * The action for changing pending state.
 *
 * @function setPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{isPending: boolean}} payload - Pending flag.
 *
 * @memberof Mojito.Services.BonusCampaign.actions
 */

/**
 * The action for storing the retrieved campaigns.
 *
 * @function getCampaignsSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{campaigns: Array<Mojito.Services.Bonus.types.CAMPAIGN>, inProgressBonuses: Array<Mojito.Services.Bonus.types.InProgressBonus>, filter: Mojito.Services.Bonus.types.BONUS_FILTER, isLoggedIn: boolean}} payload - Campaign data.
 *
 * @memberof Mojito.Services.BonusCampaign.actions
 */

export const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState,

    reducers: {
        reset() {
            return { ...initialState };
        },

        setPending(state, { payload: isPending }) {
            state.isPending = isPending;
        },

        getCampaignsSuccess(state, { payload }) {
            const { campaigns, inProgressBonuses, filter, isLoggedIn } = payload;
            const { HISTORIC, IN_PROGRESS, ACTIVE } = BONUS_FILTER;

            state.isPending = false;
            if (isLoggedIn) {
                if (filter === HISTORIC) {
                    state.campaignsByFilter[HISTORIC] = campaigns || [];
                } else {
                    state.campaignsByFilter[IN_PROGRESS] = inProgressBonuses || [];
                    state.campaignsByFilter[ACTIVE] = campaigns || [];
                }
            }
        },
    },
});

/**
 * The action for requesting campaigns.
 *
 * @function getCampaigns
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Bonus.types.BONUS_FILTER} filter - Campaign filter option.
 * @returns {Mojito.Core.Services.redux.ThunkFunction}
 *
 * @memberof Mojito.Services.BonusCampaign.actions
 */

actions.getCampaigns = filter => (dispatch, getState) => {
    dispatch(actions.setPending(true));

    serviceFactory
        .getService()
        .getCampaigns(filter)
        .then(({ campaigns, inProgressBonuses }) => {
            const isLoggedIn = isLoggedInSelector(getState());
            dispatch(
                actions.getCampaignsSuccess({ campaigns, inProgressBonuses, filter, isLoggedIn })
            );
        })
        .catch(({ message, type } = {}) => {
            log.warn(`Fetching of campaigns failed: ${type} ${message}`);
            dispatch(actions.setPending(false));
        });
};

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(bootstrapActions.dispose, authenticationActions.disposeSession),
    effect: (action, listenerApi) => listenerApi.dispatch(actions.reset()),
});

reduxInstance.injectReducer(STORE_KEY, reducer);
