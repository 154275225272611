import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'sportMetaInformation';

/**
 *
 * Class offering the possibility to link to specific sport meta information.
 *
 * @class SportMetaInformationProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class SportMetaInformationProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(sportMetaInformationId, setter) {
        const subscription = super.subscribeToEntity(
            sportMetaInformationId,
            setter,
            COLLECTION_NAME
        );

        return handleSubscription(subscription, sportMetaInformationId, COLLECTION_NAME);
    }

    subscribeToEntities(sportMetaInformationIds, onInit, onUpdate) {
        const subscriptionInfo = super.subscribeToEntities(
            sportMetaInformationIds,
            onInit,
            onUpdate,
            COLLECTION_NAME
        );
        return handleSubscription(
            subscriptionInfo,
            sportMetaInformationIds.join('-'),
            COLLECTION_NAME,
            false
        );
    }
}

export default new SportMetaInformationProvider();
