import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import BonusItemsTypes from './types.js';

const { CurrencyHelper } = MojitoPresentation.Utils;

const { FlexPane, Image, Text, DateTime, GridLayout } = MojitoPresentation.Components;
const { GridPane, GridSeparator } = GridLayout;
const { UIViewImplementation } = MojitoCore.Presentation;
const UserBonusesUtils = MojitoServices.UserBonuses.utils;
const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;
const { HEADER_COLUMNS } = BonusItemsTypes;

const bonusToIconName = {
    [BonusType.FREEBET]: 'freeBetsIcon',
    [BonusType.ODDS_BOOST]: 'oddsBoostIcon',
    [BonusType.ODDS_MULTIPLIER]: 'oddsBoostIcon',
};

export default class BonusItems extends UIViewImplementation {
    constructor(reactComponent) {
        super(reactComponent);
        this.onExpireDateRender = this.onExpireDateRender.bind(this);
        this.renderers = {
            [HEADER_COLUMNS.NAME]: this.renderNameColumn.bind(this),
            [HEADER_COLUMNS.DESCRIPTION]: this.renderDescriptionColumn.bind(this),
            [HEADER_COLUMNS.EXPIRY]: this.renderExpiryColumn.bind(this),
            [HEADER_COLUMNS.TOTAL]: this.renderTotalColumn.bind(this),
            [HEADER_COLUMNS.REMAINING]: this.renderRemainingColumn.bind(this),
            [HEADER_COLUMNS.STATUS]: this.renderStatusColumn.bind(this),
        };
    }

    getBonusDescription(bonus) {
        let description;

        if (bonus.bonusType === BonusType.ODDS_MULTIPLIER) {
            description = this.resolveAndFormatString(
                '$BONUS_ITEM.ODDS_BOOST.MULTIPLIER_DESCRIPTION',
                `${bonus.percent}`
            );
        } else if (bonus.bonusType === BonusType.ODDS_BOOST) {
            description = this.resolveAndFormatString('$BONUS_ITEM.ODDS_BOOST.DESCRIPTION');
        } else {
            description = bonus.description;
        }

        return description;
    }

    onExpireDateRender(expireDate) {
        return this.resolveAndFormatString('$BONUS_ITEM.ATTRIBUTES.COLUMN.EXPIRE_DATE', expireDate);
    }

    renderBonusNameAndStatus(bonus) {
        const bonusIcon = bonusToIconName[bonus.bonusType];

        return (
            <FlexPane config={this.config.compactContainerInner}>
                <Image config={this.config[bonusIcon]} class={`ta-${bonus.bonusType}-Icon`} />
                <Text config={this.config.title} class="ta-bonusTitle">
                    {bonus.name.toUpperCase()}
                </Text>
                <Text config={this.config.status} class="ta-bonusStatus">
                    {this.resolveString('$BONUS_ITEM.STATUS_ACTIVE').toUpperCase()}
                </Text>
            </FlexPane>
        );
    }

    renderBonusExpiry(expiry) {
        return (
            <FlexPane config={this.config.expireDateContainer}>
                <Text config={this.config.expiry} class="ta-bonusExpirationDate">
                    <DateTime
                        config={this.config.expireDateTimeFormat}
                        dateTime={expiry}
                        dateTimeRenderer={this.onExpireDateRender}
                    />
                </Text>
            </FlexPane>
        );
    }

    renderBonusDescription(bonus) {
        return (
            <Text config={this.config.description} class="ta-bonusDescription">
                {this.getBonusDescription(bonus)}
            </Text>
        );
    }

    renderBonusTotal(total, remaining) {
        const amountStyle =
            remaining !== undefined ? this.config.amountAndRemainingAmount : this.config.onlyAmount;
        return (
            <FlexPane config={this.config.amountContainer}>
                <Text config={amountStyle} class="ta-bonusAmountLabel">
                    {this.resolveAndFormatString('$BONUS_ITEM.ATTRIBUTES.COLUMN.TOTAL_VALUE')}
                </Text>
                <Text config={amountStyle} class="ta-bonusAmount">
                    {total}
                </Text>
            </FlexPane>
        );
    }

    renderBonusRemaining(remaining) {
        return (
            <FlexPane config={this.config.remainingAmountContainer}>
                <Text config={this.config.remainingAmount} class="ta-bonusRemainingAmountLabel">
                    {this.resolveAndFormatString('$BONUS_ITEM.ATTRIBUTES.COLUMN.REMAINING_VALUE')}
                </Text>
                <Text config={this.config.remainingAmount} class="ta-bonusRemainingAmount">
                    {remaining}
                </Text>
            </FlexPane>
        );
    }

    renderCompactView(bonus) {
        const remainingAmount = UserBonusesUtils.getRemainingAmount(bonus);
        const remainingFormatCurrency = CurrencyHelper.formatCurrency(
            remainingAmount,
            this.props.currencyCode
        );
        const totalFormatCurrency =
            bonus.amount && CurrencyHelper.formatCurrency(bonus.amount, this.props.currencyCode);
        return (
            <FlexPane
                config={this.config.compactContainer}
                class={`ta-${bonus.bonusType}-ItemsCompactView`}
            >
                {this.renderBonusNameAndStatus(bonus)}
                {bonus.expireDate && this.renderBonusExpiry(bonus.expireDate)}
                {this.renderBonusDescription(bonus)}
                {totalFormatCurrency &&
                    this.renderBonusTotal(totalFormatCurrency, remainingFormatCurrency)}
                {bonus.remainingAmount && this.renderBonusRemaining(remainingFormatCurrency)}
            </FlexPane>
        );
    }

    getHeaderStyle(key) {
        const headerStyle = {
            [HEADER_COLUMNS.TOTAL]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.REMAINING]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.STATUS]: 'headerStyleCenter',
        };
        const headerDefaultStyle = 'headerStyle';
        return this.config[headerStyle[key]] || this.config[headerDefaultStyle];
    }

    renderGridHeader() {
        return this.config.headerColumns.map(columnName => {
            const headerStyle = this.getHeaderStyle(columnName);
            return (
                <Text config={headerStyle} key={columnName}>
                    {this.resolveString(`$BONUS_ITEM.HEADERS.${columnName.toUpperCase()}`)}
                </Text>
            );
        });
    }

    renderNameColumn(bonus) {
        const { bonusType } = bonus;
        const bonusIcon = bonusToIconName[bonusType];

        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.NAME}
            >
                <Image config={this.config[bonusIcon]} class={`ta-${bonusType}-Icon`} />
                <Text config={this.config.textItem}>{bonus.name}</Text>
            </FlexPane>
        );
    }

    renderDescriptionColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonus.bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.DESCRIPTION}
            >
                <Text config={this.config.textItem}>{this.getBonusDescription(bonus)}</Text>
            </FlexPane>
        );
    }

    renderExpiryColumn(bonus) {
        const expireDate = bonus.expireDate;
        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonus.bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.EXPIRY}
            >
                <Text config={this.config.textItem}>
                    {expireDate !== undefined ? (
                        <DateTime config={this.config.expireDateTimeFormat} dateTime={expireDate} />
                    ) : undefined}
                </Text>
            </FlexPane>
        );
    }

    renderTotalColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonus.bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.TOTAL}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-bonusAmount">
                    {bonus.amount
                        ? CurrencyHelper.formatCurrency(bonus.amount, this.props.currencyCode)
                        : null}
                </Text>
            </FlexPane>
        );
    }

    renderRemainingColumn(bonus) {
        const remainingAmount = UserBonusesUtils.getRemainingAmount(bonus);
        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonus.bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.REMAINING}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-bonusRemainingAmount">
                    {remainingAmount
                        ? CurrencyHelper.formatCurrency(remainingAmount, this.props.currencyCode)
                        : null}
                </Text>
            </FlexPane>
        );
    }

    renderStatusColumn(bonus) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class={`ta-${bonus.bonusType}-Info`}
                key={bonus._id + HEADER_COLUMNS.STATUS}
            >
                <Text config={this.config.textItemCenter}>
                    {this.resolveString('$BONUS_ITEM.STATUS_ACTIVE')}
                </Text>
            </FlexPane>
        );
    }

    renderContent(bonus) {
        return this.config.headerColumns.map(column => this.renderers[column](bonus));
    }

    renderFullLayout(bonusItems) {
        const bonusItemsInfo = bonusItems.map(bonus => {
            return (
                <GridSeparator config={this.config.gridSeparator} key={bonus.id}>
                    {this.renderContent(bonus)}
                </GridSeparator>
            );
        });
        const header = this.renderGridHeader();

        return (
            <GridPane class={`ta-userBonusItems`} config={this.config.listContainer}>
                {header}
                {bonusItemsInfo}
            </GridPane>
        );
    }

    renderCompactLayout(bonusItems) {
        return bonusItems.map(bonus => (
            <FlexPane
                key={bonus.id}
                config={this.config.container}
                class={`ta-${bonus.bonusType}-Item`}
            >
                {this.renderCompactView(bonus)}
            </FlexPane>
        ));
    }

    render() {
        return (
            <>
                {this.config.compactLayout
                    ? this.renderCompactLayout(this.props.bonusItems)
                    : this.renderFullLayout(this.props.bonusItems)}
            </>
        );
    }
}
