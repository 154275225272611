import { useCallback, useMemo } from 'react';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import EventListItemDetails from 'modules/event-list/item/details/index.jsx';
import GenericMarketContent from 'modules/aggregated-market-pane/generic/index.jsx';
import GameLineMarketContent from 'modules/aggregated-market-pane/game-line/index.jsx';
import { useMarketItems, useEventItem, useEventsLoadDone } from 'modules/common/hooks';
import withEventCardSkeleton from 'modules/event-cards/skeleton/with-event-card-skeleton.js';

const { EventTimingStatus, FlexPane, Text, Button, Image, AbsolutePane } =
    MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const EventsUtils = MojitoServices.SportsContent.Events.utils;
const { types: MarketGroupTypes, utils: MarketGroupUtils } =
    MojitoServices.SportsContent.MarketGroups;
const { createAggregatedMarket } = EventsUtils;
const { createGameLineMarketInfo } = MarketGroupUtils;
const { AGGREGATED_MARKET_TYPE } = MarketGroupTypes;

const getHrefLink = (event, appContext) => {
    const getHrefItem = event =>
        NavigationPathBuilder.sportEventPath(event.sportId, event.id, appContext.routeResolver);
    return getHrefItem(event);
};

const useLoadDone = props => useEventsLoadDone([props.eventId]);

const MatchEventCard = withEventCardSkeleton(
    props => {
        const { mojitoTools, eventId, marketId, gameLine, shouldRequestData } = props;
        const { config, style, appContext } = mojitoTools;
        const isGameLineCard = !!gameLine;

        const event = useEventItem(eventId, shouldRequestData);
        const onEventClick = useCallback(() => {
            IntentActionTrigger.showSportEvent(event.sportId, event.id);
            appContext.analyticsEmitter.emitAnalytics('eventCardClicked', event.canonicalName);
        }, [event?.sportId, event?.id, event?.canonicalName, appContext.analyticsEmitter]);

        if (!event) {
            return null;
        }

        const sportImage = config.sportIcons[event.sportId] || config.sportIcons.defaultIcon;
        const showBackground =
            config.showBackgroundSportImage &&
            !config.noBackgroundImageSports.includes(event.sportId);

        return (
            <div style={style.cardWrapper}>
                {showBackground && (
                    <BackgroundSportImage
                        {...props}
                        isGameLine={isGameLineCard}
                        image={sportImage}
                    />
                )}
                <FlexPane class="ta-MatchEventCard" config={config.eventCard.container}>
                    <Button
                        class="ta-clickableArea"
                        onClick={onEventClick}
                        config={config.eventCard.clickableArea}
                    >
                        <FlexPane class="ta-headerContainer" config={config.headerContainer}>
                            {config.showSportIcon && (
                                <Image
                                    src={sportImage}
                                    config={config.sportIcon}
                                    class="ta-sportIcon"
                                />
                            )}
                            <Text class="ta-headerTitle" config={config.eventCard.title}>
                                {event.typeName}
                            </Text>
                            <EventTimingStatus config={config.headerTime} eventItem={event} />
                        </FlexPane>
                    </Button>
                    <FlexPane config={config.participantsAndMarketsContainer}>
                        {!isGameLineCard && (
                            <EventListItemDetails
                                hrefLink={getHrefLink(event, appContext)}
                                config={config.eventItemDetails}
                                event={event}
                                cbClick={onEventClick}
                            />
                        )}
                        {isGameLineCard ? (
                            <GameLine {...props} event={event} onEventClick={onEventClick} />
                        ) : (
                            <RegularMarket {...props} event={event} marketId={marketId} />
                        )}
                    </FlexPane>
                </FlexPane>
            </div>
        );
    },
    useLoadDone,
    config => config.eventCard.skeletonLoader
);

const BackgroundSportImage = props => {
    const { mojitoTools, image, isGameLine } = props;
    const { config } = mojitoTools;
    const containerConfig = isGameLine
        ? config.usBackgroundImageWrapper
        : config.backgroundImageWrapper;
    const imageConfig = isGameLine ? config.usBackgroundSportImage : config.backgroundSportImage;
    return (
        <AbsolutePane config={containerConfig}>
            <Image src={image} config={imageConfig} class="ta-backgroundImage" />
        </AbsolutePane>
    );
};

const GameLine = props => {
    const { mojitoTools, event, gameLine, marketLines } = props;
    const { config } = mojitoTools;

    const gameLineMarketInfo = createGameLineMarketInfo(marketLines, gameLine);
    const markets = useMarketItems(event.id, gameLineMarketInfo.marketIds);

    return (
        <GameLineMarketContent
            config={config.gameLineMarketContent}
            eventItem={event}
            aggregatedMarket={createAggregatedMarket(gameLineMarketInfo, markets)}
        />
    );
};

const RegularMarket = props => {
    const { mojitoTools, event, marketName, marketId } = props;
    const { config } = mojitoTools;
    const marketIds = useMemo(() => [marketId], [marketId]);
    const markets = useMarketItems(event.id, marketIds);
    const marketInfo = { type: AGGREGATED_MARKET_TYPE.GENERIC, marketIds: [marketId] };
    return (
        <>
            <Text class="ta-marketTitle" config={config.marketTitle}>
                {marketName}
            </Text>
            <GenericMarketContent
                config={config.genericMarketContent}
                aggregatedMarket={createAggregatedMarket(marketInfo, markets)}
                eventItem={event}
                showSelectionHeaders={false}
            />
        </>
    );
};

MatchEventCard.getStyle = () => {
    return {
        cardWrapper: {
            position: 'relative',
        },
    };
};

export default MatchEventCard;
