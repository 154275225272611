import PropTypes from 'prop-types';
import TextView from './textview.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Text view displaying one Bet.
 *
 * @class TextView
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the TextView.
 *
 * @property {string} [header] - Header text.
 * @property {string} [text] - Content text.
 * @memberof Mojito.Presentation.Components.TextView
 */
const propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
};

export default UIView('TextView', TextView, propTypes);
