import NavigationTypes from './navigation-types.js';
const { NAVIGATION, PARAMETER } = NavigationTypes;
const {
    SPORT_ID,
    EVENT_ID,
    CLASS_ID,
    TYPE_ID,
    COUPON_ID,
    CATEGORY,
    MEETING_ID,
    RACE_ID,
    ITEM_ID,
    PART_ID,
    URL,
    FILTER,
} = PARAMETER;

/**
 * Helper class offers functionality to build navigational payload objects and dynamic URL paths.
 *
 * @class NavigationPathBuilder
 * @memberof Mojito.Presentation.Base.Navigation
 */

/**
 * Builds object used to navigate to sport page.
 *
 * @function toSport
 *
 * @param {string} sportId - Sport id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSport = (sportId = '') => ({
    type: NAVIGATION.SPORT_PAGE,
    params: { [SPORT_ID]: sportId },
});

/**
 * Builds object used to navigate to sport event page.
 *
 * @function toSportEvent
 *
 * @param {string} sportId - Sport id.
 * @param {string} eventId - Event id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportEvent = (sportId, eventId) => ({
    type: NAVIGATION.SPORT_EVENT_PAGE,
    params: { [SPORT_ID]: sportId || '', [EVENT_ID]: eventId || '' },
});

/**
 * Builds object used to navigate to inplay events page.
 * Accepts optional sportId parameter to perform navigation to inplay events of desired sport.
 *
 * @function toInplay
 *
 * @param {string} [sportId] - Sport id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toInplay = sportId =>
    sportId
        ? {
              type: NAVIGATION.INPLAY_SPORT_PAGE,
              params: { sportId: sportId },
          }
        : {
              type: NAVIGATION.INPLAY_PAGE,
              params: {},
          };

/**
 * Builds object used to navigate to home page.
 *
 * @function toHome
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toHome = () => ({ type: NAVIGATION.HOME });

/**
 * Builds object used to navigate to quick start guide sub page within home page.
 *
 * @function toQuickStartGuide
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toQuickStartGuide = () => ({
    ...toHome(),
    nested: {
        type: NAVIGATION.QUICK_START_GUIDE,
    },
});

/**
 * Builds object used to navigate to inplay event.
 *
 * @function toInplayEvent
 *
 * @param {string} eventId - Event id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toInplayEvent = eventId => ({
    type: NAVIGATION.INPLAY_EVENT_PAGE,
    params: { [EVENT_ID]: eventId || '' },
});

/**
 * Builds object used to navigate to sport coupon.
 * For example, today, tomorrow, price boost, cms coupons, etc.
 *
 * @function toSportCoupon
 *
 * @param {string} sportId -Sport id.
 * @param {string} couponId - Coupon id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportCoupon = (sportId, couponId) => ({
    type: NAVIGATION.SPORTS_COUPON_PAGE,
    params: { [SPORT_ID]: sportId || '', [COUPON_ID]: couponId || '' },
});

/**
 * Builds object used to navigate to sport highlights.
 *
 * @function toSportHighlights
 *
 * @param {string} sportId - Sport id.
 * @param {string} sportClassId - Sport class id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportHighlights = (sportId, sportClassId) => ({
    ...toSport(sportId),
    nested: {
        type: NAVIGATION.HIGHLIGHTS,
        anchor: sportClassId,
    },
});

/**
 * Builds object used to navigate to future racing sport pivot.
 *
 * @function toSportFuture
 *
 * @param {string} sportId - Sport id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportFuture = sportId => ({
    ...toSport(sportId),
    nested: {
        type: NAVIGATION.FUTURE,
    },
});

/**
 * Builds object used to navigate to sport matches.
 *
 * @function toSportMatches
 *
 * @param {string} sportId - Sport id value to build correct path `/sports/:sportId/matches/:filter`.
 * @param {string} [filter] - Value of the filter to show the correct event coupon `/sports/:sportId/matches/:filter`.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportMatches = (sportId, filter) => {
    const toEventsCoupon = {
        type: NAVIGATION.EVENTS_COUPON,
        params: { [FILTER]: filter },
    };
    return {
        ...toSport(sportId),
        nested: {
            type: NAVIGATION.MATCHES,
            nested: filter ? toEventsCoupon : undefined,
        },
    };
};

/**
 * Builds object used to navigate to sport priceboosts.
 *
 * @function toSportPriceBoosts
 *
 * @param {string} sportId - Sport id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportPriceBoosts = sportId => ({
    ...toSport(sportId),
    nested: {
        type: NAVIGATION.PRICE_BOOSTS,
    },
});

/**
 * Builds object used to navigate to sport competitions.
 *
 * @function toSportCompetitions
 *
 * @param {string} sportId - Sport id.
 * @param {string} sportClassId - Sport class id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportCompetitions = (sportId, sportClassId) => ({
    ...toSport(sportId),
    nested: {
        type: NAVIGATION.COMPETITIONS,
        anchor: sportClassId,
    },
});

/**
 * Builds object used to navigate to sport competitions league.
 *
 * @function toSportCompetitionsLeague
 *
 * @param {string} sportId - Sport id.
 * @param {string} typeId - Type id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */

const toSportCompetitionsLeague = (sportId, typeId) => ({
    type: NAVIGATION.COMPETITIONS_LEAGUE,
    params: {
        [SPORT_ID]: sportId,
        [TYPE_ID]: typeId,
    },
});

/**
 * Builds object used to navigate to sport outrights league.
 *
 * @function toSportOutrightsLeague
 *
 * @param {string} sportId - Sport id.
 * @param {string} typeId - Type id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportOutrightsLeague = (sportId, typeId) => ({
    type: NAVIGATION.OUTRIGHTS_LEAGUE,
    params: {
        [SPORT_ID]: sportId,
        [TYPE_ID]: typeId,
    },
});

/**
 * Builds object used to navigate to sport outrights.
 *
 * @function toSportOutright
 *
 * @param {string} sportId - Sport id.
 * @param {string} sportClassId - Sport class id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportOutright = (sportId, sportClassId) => ({
    ...toSport(sportId),
    nested: {
        type: NAVIGATION.OUTRIGHTS,
        anchor: sportClassId,
    },
});

/**
 * Builds object used to navigate to sport category.
 * For example, highlights, competitions, outrights, meetings, future races, etc.
 *
 * @function toSportCategory
 *
 * @param {string} sportId - Sport id.
 * @param {string} category - Category name.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportCategory = (sportId, category) => ({
    type: NAVIGATION.SPORT_CATEGORY_PAGE,
    params: { [SPORT_ID]: sportId || '', [CATEGORY]: category || '' },
});

/**
 * Builds object used to navigate to racing sport meetings.
 *
 * @function toSportMeetings
 *
 * @param {string} sportId - The id of the racing sport.
 * @param {string} filter - The selected content filter of the racing sport (TODAY,TOMORROW,STREAMING).
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toSportMeetings = (sportId, filter) => {
    const toMeetingCoupon = {
        type: NAVIGATION.MEETINGS_COUPON,
        params: { [FILTER]: filter },
    };
    return {
        ...toSport(sportId),
        nested: {
            type: NAVIGATION.MEETINGS,
            nested: filter ? toMeetingCoupon : undefined,
        },
    };
};

/**
 * Builds object used to navigate to the first race event available for the specified meeting id.
 *
 * @function toRaceMeeting
 *
 * @param {string} sportId - The id of the racing sport.
 * @param {string} meetingId - Meeting id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toRaceMeeting = (sportId, meetingId) => ({
    type: NAVIGATION.RACE_MEETING_PAGE,
    params: { [SPORT_ID]: sportId || '', [MEETING_ID]: meetingId || '' },
});

/**
 * Builds object used to navigate to race event.
 *
 * @function toRaceEvent
 *
 * @param {string} sportId - The id of the racing sport.
 * @param {string} meetingId - Meeting id.
 * @param {string} raceId - Race event id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toRaceEvent = (sportId, meetingId, raceId) => ({
    type: NAVIGATION.RACE_PAGE,
    params: { [SPORT_ID]: sportId || '', [MEETING_ID]: meetingId || '', [RACE_ID]: raceId || '' },
});

/**
 * Builds object used to navigate to future race event.
 *
 * @function toFutureRaceEvent
 *
 * @param {string} sportId - The id of the racing sport.
 * @param {string} raceId - Future race event id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toFutureRaceEvent = (sportId, raceId) => ({
    type: NAVIGATION.FUTURE_RACE_PAGE,
    params: { [SPORT_ID]: sportId || '', [RACE_ID]: raceId || '' },
});

/**
 * Builds object used to navigate to virtual sports.
 *
 * @function toVirtualSports
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toVirtualSports = () => ({ type: NAVIGATION.VIRTUAL_SPORTS_PAGE });

/**
 * Builds object used to navigate to specific virtual sport.
 *
 * @function toVirtualSport
 *
 * @param {string} sportId - The id of the virtual sport.
 * @param {string} sportClassId - The id of the virtual sport class.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toVirtualSport = (sportId, sportClassId) => ({
    type: NAVIGATION.VIRTUAL_SPORT_CLASS_PAGE,
    params: { [SPORT_ID]: sportId || '', [CLASS_ID]: sportClassId },
});

/**
 * Builds object used to navigate to specific virtual sport event.
 *
 * @function toVirtualSportEvent
 *
 * @param {string} sportId - The id of the virtual sport.
 * @param {string} sportClassId - The id of the virtual sport class.
 * @param {string} eventId - Virtual sport event id.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toVirtualSportEvent = (sportId, sportClassId, eventId) => ({
    type: NAVIGATION.VIRTUAL_SPORT_EVENT_PAGE,
    params: {
        [SPORT_ID]: sportId || '',
        [CLASS_ID]: sportClassId || '',
        [EVENT_ID]: eventId || '',
    },
});

/**
 * Builds object used to navigate to user account.
 *
 * @function toAccount
 *
 * @param {string} [itemId] - Account page id.
 * @param {string} [partId] - Account page part id. Usually sub page.
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toAccount = (itemId, partId) => {
    if (itemId) {
        if (partId) {
            return {
                type: NAVIGATION.ACCOUNT_PAGE,
                params: { [ITEM_ID]: itemId, [PART_ID]: partId },
            };
        }

        return {
            type: NAVIGATION.ACCOUNT_ITEM_PAGE,
            params: { [ITEM_ID]: itemId },
        };
    }

    return {
        type: NAVIGATION.ACCOUNT_MAIN_PAGE,
        params: {},
    };
};

/**
 * Builds object used to navigate the coupon with boosted prices events.
 *
 * @function toPriceBoost
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toPriceBoost = () => ({ type: NAVIGATION.PRICE_BOOST_PAGE });

/**
 * Builds object used to navigate to bad route page.
 * Usually shown when route did not match to any of the page.
 *
 * @function toBadRequest
 *
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toBadRequest = () => ({ type: NAVIGATION.BAD_REQUEST_PAGE });

/**
 * Builds object used to navigate to custom page by direct url path.
 *
 * @function toCustom
 *
 * @param {string} url - URL path to be explicitly used for navigation (e.g., `/my-custom-page/item/1?param=foo` etc.).
 * @returns {Mojito.Presentation.Base.Navigation.types.NavigationPayload} Navigation payload object.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const toCustom = url => ({ type: NAVIGATION.CUSTOM_NAVIGATION, params: { [URL]: url } });

/**
 * Builds ready to use url path for sport page navigation.
 * For example, `/sports/tennis` for <code>sportId: 'tennis'</code>.
 *
 * @function sportPath
 *
 * @param {string} sportId - Sport id.
 * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
 * @returns {string} URL path.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const sportPath = (sportId, routeResolver) => routeResolver.getRoute(toSport(sportId));

/**
 * Builds ready to use url path for event page navigation.
 * For example, `/sports/tennis/events/12345` for <code>sportId: 'tennis', eventId: '12345'</code>.
 *
 * @function sportEventPath
 *
 * @param {string} sportId - Sport id.
 * @param {string} eventId - Event id.
 * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
 * @returns {string} URL path.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const sportEventPath = (sportId, eventId, routeResolver) =>
    routeResolver.getRoute(toSportEvent(sportId, eventId));

/**
 * Builds ready to use url path for race meeting page navigation.
 * For example, `/sports/horse_racing/meetings/123/races/456` for <code>sportId: 'horse_racing', eventId: '456', meetingId: '123'</code>.
 *
 * @function raceMeetingPath
 *
 * @param {string} sportId - Sport id.
 * @param {string} eventId - Event id.
 * @param {string} meetingId - Meeting id.
 * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
 * @returns {string} URL path.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const raceMeetingPath = (sportId, eventId, meetingId, routeResolver) =>
    routeResolver.getRoute(toRaceEvent(sportId, meetingId, eventId));

/**
 * Builds ready to use url path for inplay events page navigation.
 * Accepts optional sportId parameter to perform navigation to inplay events of desired sport.
 * For example, `/inplay` or `/inplay/tennis` for <code>sportId: 'tennis'</code>.
 *
 * @function inplayPath
 *
 * @param {string} [sportId] - Sport id.
 * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
 * @returns {string} URL path.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const inplayPath = (sportId, routeResolver) => routeResolver.getRoute(toInplay(sportId));

/**
 * Builds ready to use url path for in-play event page navigation.
 * For example, `/inplay/events/12345` for <code>eventId: '12345'</code>.
 *
 * @function inplayEventPath
 *
 * @param {string} eventId - Event id.
 * @param {Mojito.Core.Base.AbstractRouteResolver} routeResolver - Route resolver instance.
 * @returns {string} URL path.
 *
 * @memberof Mojito.Presentation.Base.Navigation.NavigationPathBuilder
 */
const inplayEventPath = (eventId, routeResolver) => routeResolver.getRoute(toInplayEvent(eventId));

export default {
    toHome,
    toInplay,
    toInplayEvent,
    toSport,
    toSportEvent,
    toSportCoupon,
    toSportCompetitions,
    toSportCategory,
    toSportHighlights,
    toSportOutright,
    toRaceMeeting,
    toSportMeetings,
    toVirtualSport,
    toVirtualSports,
    toVirtualSportEvent,
    toRaceEvent,
    toFutureRaceEvent,
    toAccount,
    toPriceBoost,
    toBadRequest,
    toCustom,
    sportPath,
    sportEventPath,
    raceMeetingPath,
    inplayPath,
    inplayEventPath,
    toSportMatches,
    toSportFuture,
    toSportPriceBoosts,
    toSportCompetitionsLeague,
    toSportOutrightsLeague,
    toQuickStartGuide,
};
