import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME_RACE_MEETINGS = 'raceMeetings';

/**
 *
 * Class offering the possibility to link to specific meeting lists.
 *
 * @class MeetingProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MeetingProvider extends DataProvider {
    /**
     * Initialize the meeting provider.
     *
     * @param {string} languageCode - The current language code in use.
     *
     * @function Mojito.Services.SportsContent.DataProviders.MeetingProvider#init
     */
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(
            COLLECTION_NAME_RACE_MEETINGS,
            languageCode
        );
    }

    subscribeToEntity(meetingId, setter) {
        const subscription = super.subscribeToEntity(
            meetingId,
            setter,
            COLLECTION_NAME_RACE_MEETINGS
        );
        return handleSubscription(subscription, meetingId, COLLECTION_NAME_RACE_MEETINGS);
    }
}

export default new MeetingProvider();
