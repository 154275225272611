import PropTypes from 'prop-types';
import FormattedLabelWithPriceImpl from './formatted-label-with-price.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component that displays a label and a price inside a container.
 *
 * @class FormattedLabelWithPrice
 * @memberof Mojito.Presentation.Components
 */

/**
 * `FormattedLabelWithPrice` component prop types.
 *
 * @property {number} [price] - The price to be displayed.
 * @property {string} [label] - The label to be displayed.
 * @property {string} [valueNotAvailableLabel] - The label to be displayed when prices are not available (N/A).
 * @property {string} [currency] - The type of currency for the price.
 *
 * @memberof Mojito.Presentation.Components.FormattedLabelWithPrice
 */
const propTypes = {
    price: PropTypes.number,
    label: PropTypes.string,
    valueNotAvailableLabel: PropTypes.string,
    currency: PropTypes.string,
};

export default MojitoCore.Presentation.UIView(
    'FormattedLabelWithPrice',
    FormattedLabelWithPriceImpl,
    propTypes
);
