import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import channelFactory from 'services/common/content/content-channel-factory.js';
import eventGroupsProvider from './provider/index.js';
import EventGroupsDescriptor from './descriptor.js';
import ServicesTypes from 'services/common/types.js';
import ServicesUtils from 'services/common/utils.js';

const reduxInstance = MojitoCore.Services.redux;
const { EVENT_GROUP, EVENT_GROUP_CHUNK } = EventGroupsDescriptor.DATA_TYPES;
const { actionsRegistry } = MojitoCore.Services.Content;
const { AVAILABLE, UNAVAILABLE, UNKNOWN } = ServicesTypes.CONTENT_STATE;
export const getEventGroupsChannel = () =>
    channelFactory.getChannel(eventGroupsProvider, EVENT_GROUP);

/**
 * Defines the state of the event groups store.
 *
 * @typedef EventGroupsState
 *
 * @property {object} eventGroups - Map of event groups. Key is event group id and value is array of groups.
 * @property {object} eventGroupNames - Map of event groups names. Key is event group id and value is event group name.
 * @property {object} loadedEventGroups - Map of event groups that has been loaded from content. Key is event group id and value is boolean, true if loaded, else false.
 *
 * @memberof Mojito.Services.EventGroups
 */

/**
 * The name of the event groups store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.EventGroups
 */
export const STORE_KEY = 'eventGroupsStore';

export const INITIAL_STATE = {
    eventGroups: {},
    eventGroupNames: {},
    marketOptions: {},
    eventGroupsState: {},
};

const reducers = {
    updateEventGroup(state, { payload }) {
        const { eventGroup, id } = payload;
        const { groups, name, marketOptions } = eventGroup || {};
        state.eventGroups[id] = groups;
        state.eventGroupNames[id] = name;
        state.marketOptions[id] = marketOptions;
        state.eventGroupsState[id] = eventGroup ? AVAILABLE : UNAVAILABLE;
    },
    updateEventGroups(state, { payload: eventGroupInfos }) {
        eventGroupInfos.forEach(eventGroupInfo => {
            const payload = { id: eventGroupInfo.id, eventGroup: eventGroupInfo.data };
            reducers.updateEventGroup(state, { payload });
        });
    },
    removeEventGroups(state, { payload: ids = [] }) {
        ids.forEach(id => {
            delete state.eventGroups[id];
            delete state.eventGroupNames[id];
            delete state.marketOptions[id];
            state.eventGroupsState[id] = UNKNOWN;
        });
    },
    pendingEventGroups: ServicesUtils.createPendingHandler('eventGroupsState'),
    reset() {
        return { ...INITIAL_STATE };
    },
};

export const { reducer, actions } = createSlice({
    name: 'eventGroups',
    initialState: INITIAL_STATE,
    reducers,
});

/**
 * Event groups actions.
 *
 * @class EventGroupsActions
 * @name actions
 * @memberof Mojito.Services.EventGroups
 */

/**
 * Update event group.
 *
 * @function updateEventGroup
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ id: string, eventGroup: object }} payload - Payload for update event group.
 * @memberof Mojito.Services.EventGroups.actions
 */

/**
 * Remove event group.
 *
 * @function removeEventGroup
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {string} id - Id of event group to be removed.
 * @memberof Mojito.Services.EventGroups.actions
 */

/**
 * Reset event groups state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.EventGroups.actions
 */

/**
 * Subscribe to event group.
 *
 * @function subscribe
 *
 * @param {{eventGroupIds: Array<string>, clientId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to event group thunk.
 * @memberof Mojito.Services.EventGroups.actions
 */
actions.subscribe = payload => dispatch => {
    const { eventGroupIds, clientId } = payload;
    getEventGroupsChannel().subscribe(eventGroupIds, clientId, (id, data) => {
        dispatch(actions.updateEventGroup({ id, eventGroup: data }));
    });
};

/**
 * Subscribe to multiple event group.
 *
 * @function subscribeMultiple
 *
 * @param {{eventGroupIds: Array<string>, clientId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to event group thunk.
 * @memberof Mojito.Services.EventGroups.actions
 */
actions.subscribeMultiple = payload => dispatch => {
    const { requests } = payload;
    const onInit = initChunk => {
        dispatch(actions.updateEventGroups(initChunk));
    };
    const onUpdate = (id, data) => dispatch(actions.updateEventGroup({ id, eventGroup: data }));
    getEventGroupsChannel().subscribeMultiple(requests, onInit, onUpdate);
};

/**
 * Unsubscribe client from event groups.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from event groups thunk.
 * @memberof Mojito.Services.EventGroups.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getEventGroupsChannel().unsubscribeAll(clientId, ids => {
        dispatch(actions.removeEventGroups(ids));
    });
};

// Register thunks in common registry to use them by request data helper.
actionsRegistry.addSubscription(EVENT_GROUP, actions.subscribe, actions.unsubscribe);
actionsRegistry.addSubscription(EVENT_GROUP_CHUNK, actions.subscribeMultiple);
reduxInstance.injectReducer(STORE_KEY, reducer);
