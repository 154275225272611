import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import ScrollPane from 'presentation/components/scroll-pane/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import RaceExplanationPaneTypes from './types.js';

const { RESULT, RACE_OFF, PRICED, BEST_ODDS } = RaceExplanationPaneTypes.ITEM_TYPES;

const RaceExplanationPane = props => {
    const {
        showBestOddsGuaranteed,
        mojitoTools: { config },
    } = props;

    const explanationsIconsConfig = {
        [RESULT]: config.resultRaceIcon,
        [RACE_OFF]: config.raceOffIcon,
        [PRICED]: config.pricedRaceIcon,
        [BEST_ODDS]: config.bestOddsRaceIcon,
    };

    return (
        <ScrollPane config={config.scrollPane}>
            <FlexPane class="ta-RaceExplanationPane" config={config.explanationItemsContainer}>
                {config.items
                    .filter(item => showBestOddsGuaranteed || item !== BEST_ODDS)
                    .map(item => {
                        return (
                            <ExplanationItem
                                key={item}
                                {...props}
                                iconConfig={explanationsIconsConfig[item]}
                                textKey={item}
                            />
                        );
                    })}
            </FlexPane>
        </ScrollPane>
    );
};

const ExplanationItem = props => {
    const {
        iconConfig,
        textKey,
        mojitoTools: { config, stringResolver },
    } = props;
    return (
        <FlexPane config={config.explanationItem}>
            <Image config={iconConfig} />
            <Text config={config.explanationText}>
                {stringResolver.resolveString(`$RACE_EXPLANATION_PANE.${textKey}`)}
            </Text>
        </FlexPane>
    );
};

export default RaceExplanationPane;
