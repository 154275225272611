import PropTypes from 'prop-types';
import SwitchPaneImpl from './switch-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * SwitchPane is a layout component that renders at most one child component at a time. The rendered
 * child is the one whose key matches the "selectedKey" prop value. This component is particularly useful
 * for transitions, such as crossfades, between contents.
 *
 * Note: During transitions, multiple children may be visible simultaneously.
 *
 * @class SwitchPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * SwitchPane prop types.
 *
 * @property {string} [selectedKey] - The key of the child that should be visible.
 * @memberof Mojito.Presentation.Components.SwitchPane
 */
const propTypes = {
    selectedKey: PropTypes.string,
    children: PropTypes.node,
};

export default MojitoCore.Presentation.UIView('SwitchPane', SwitchPaneImpl, propTypes);
