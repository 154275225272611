import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract performance service to be implemented by concrete implementation.
 *
 * @class AbstractPerformanceService
 * @abstract
 * @memberof Mojito.Services.Performance
 */
export default class AbstractPerformanceService {
    /**
     * Configure performance service.
     *
     * @param {Mojito.Services.types.ServiceConfig} config - Performance service config object.
     *
     * @function Mojito.Services.Performance.AbstractPerformanceService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        log.error('configure must be implemented by concrete subclass!');
    }

    /**
     * Notify performance service that navigation to a new page happend.
     *
     * @param {string} newRoute - New route.
     * @function Mojito.Services.Peformance.AbstractPerformanceService#navigate
     */
    // eslint-disable-next-line no-unused-vars
    navigate(newRoute) {
        log.error('navigate must be implemented by concrete subclass!');
    }

    /**
     * Activate performance service to allow sending metrics to external storages / 3rd party services.
     *
     * @function Mojito.Services.Peformance.AbstractPerformanceService#activate
     */
    activate() {
        log.error('activate must be implemented by concrete subclass!');
    }

    /**
     * Report performance metric.
     *
     * @param {Mojito.Services.Performance.types.TIMELINE_RECORD_TYPES} type - Performance mark type.
     * @param {*} payload - Any data that could be useful during processing performance metrics.
     * @function Mojito.Services.Peformance.AbstractPerformanceService#report
     */
    // eslint-disable-next-line no-unused-vars
    report(type, payload) {
        log.error('report must be implemented by concrete subclass!');
    }
}
