import MojitoCore from 'mojito/core';
import { handleSubscription } from 'services/performance/utils.js';

const DataProvider = MojitoCore.Services.DataProvider;
const repositoryCollectionFactory = MojitoCore.Base.repositoryCollectionFactory;

const COLLECTION_NAME = 'markets';

/**
 * Class offering the possibility to link to event markets.
 *
 * @class MarketProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MarketProvider extends DataProvider {
    init(languageCode) {
        repositoryCollectionFactory.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(marketId, onUpdate) {
        const subscription = super.subscribeToEntity(marketId, onUpdate, COLLECTION_NAME);
        return handleSubscription(subscription, marketId, COLLECTION_NAME);
    }

    subscribeToEntities(marketIds, onInit, onUpdate) {
        const subscriptionInfo = super.subscribeToEntities(
            marketIds,
            onInit,
            onUpdate,
            COLLECTION_NAME
        );
        return handleSubscription(subscriptionInfo, marketIds.join('-'), COLLECTION_NAME, false);
    }
}

export default new MarketProvider();
