import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {Logger} from '#core/utils/logger.js';
import {ApplicationConfig} from '#core/config/application-config.js';

const {actions: authActions, types: authTypes} = MojitoServices.Authentication;
const {dispatch} = MojitoCore.Services.redux.store;

class DrivenSSOServiceClass extends MojitoServices.Authentication.AbstractSSOService {
    constructor() {
        super();
        this._getCredentialsRetriever = null;
        this.logger = Logger('DrivenSSOService');
    }

    checkIfLoggedInBySSO(cb) {
        performance.mark('dbx:DrivenSSOService:checkIfLoggedInBySSO');
        if (!this._getCredentialsRetriever) {
            this.logger.debug('No credentials retriever is set. Do nothing');
            return;
        }
        const _gotToken = result => {
            if (result === undefined) {
                return; // just do nothing
            }
            if (result === null) {
                this.logger.info('Got null session. Logging out');
                dispatch(authActions.logout(authTypes.LOGOUT_REASON.SESSION_INVALID));
                return;
            }
            this.logger.info('Got session info', result);

            let {userName, token, sessionCreationTime} = result;
            if (!sessionCreationTime) {
                sessionCreationTime = new Date().toISOString();
            }
            cb(userName, token, sessionCreationTime);

            // =========
            const APIDomain = new URL(ApplicationConfig.apiUrl).host;
            const currentDomain = window.location.host.split('.').slice(-2).join('.');
            if (!APIDomain.endsWith('.' + currentDomain)) {
                const message =
                    `The Mojito API is located on a different domain (${APIDomain}).` +
                    `This may cause issues with authentication because most browsers are blocking 3rd-party cookies.`;

                this.logger.error(`%c${message}`, 'font-size: 20px; border-left: 20px solid red; padding: 40px 10px');
            }
        };

        try {
            this._getCredentialsRetriever()
                .then(_gotToken) // 1st attempt
                .catch(error => {
                    this.logger.warn(
                        'Credentials retriever returns rejected promise. This probably means host application failed to provide auth token. Trying again...',
                        error
                    );
                    this._getCredentialsRetriever()
                        .then(_gotToken) // 2nd attempt
                        .catch(error => {
                            this.logger.error(
                                'Credentials retriever returns rejected promise. This probably means host application failed to provide auth token. Logging out.',
                                error
                            );
                            dispatch(authActions.logout());
                        });
                });
        } catch (e) {
            this.logger.error(`Exception in checkIfLoggedInBySSO: ${e}`);
        }
    }

    // eslint-disable-next-line no-unused-vars
    login(credentials, successCallback, errorCallback) {
        successCallback(credentials);
    }

    logout(reason) {
        this.logger.info(`Logging out. Reason: ${reason}`);
    }

    setCredentialsRetriever(handler) {
        this._getCredentialsRetriever = handler;
    }
    removeCredentialsRetriever() {
        this._getCredentialsRetriever = null;
    }
}

export const DrivenSSOService = new DrivenSSOServiceClass();
