import {registerTileSchema} from '#core/application/schema-registry.js';

import {merge} from '#core/utils/config-utils.js';
import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';

import BET_BONUS_LABEL from './bet-bonus-label.palette-schema.yaml';

registerTileSchema(BET_BONUS_LABEL);

export function generateBetBonusLabel(TILE) {
    return {
        container: {
            ...resolveBackground(TILE.BET_BONUS_LABEL_BG),
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: TILE.BET_BONUS_LABEL_BORDER_COLOR,
            borderRadius: TILE.BET_BONUS_LABEL_BORDER_RADIUS,
        },
        text: {
            fontSize: 10,
            ...TILE.BET_BONUS_LABEL_TEXT,
        },
        icon: merge(icon(TILE.BET_BONUS_LABEL_ICON_COLOR), {
            style: {
                minWidth: '12px',
                minHeight: '12px',
                width: '12px',
            },
        }),
    };
}
