import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';

const { SkeletonPart, FlexPane } = MojitoPresentation.Components;

const renderRows = (config, { raceButton }) => {
    const races = range(config.numberOfStartTimes);
    const rows = [];
    while (races.length > 0) {
        const row = races
            .splice(0, config.maxButtonsInRow)
            .map(i => <SkeletonPart key={i} config={raceButton} />);
        while (row.length < config.maxButtonsInRow) {
            // fulfill row to raceButtonsInRow number of fake buttons
            row.push(<div key={row.length} style={raceButton.style} />);
        }

        rows.push(
            <FlexPane key={rows.length} config={config.startTimeContainer}>
                {row}
            </FlexPane>
        );
    }
    return <FlexPane config={config.startTimesContainer}>{rows}</FlexPane>;
};

const RaceCouponsSkeleton = props => {
    const {
        mojitoTools: { config, style },
    } = props;

    return (
        <FlexPane config={config.topLevelContainer}>
            <FlexPane class="ta-RaceCouponSkeleton" config={config.container}>
                <RaceCouponHeader {...props} />
                <FlexPane config={config.startTimeAndMeetingsContainer}>
                    <FlexPane config={config.meetingsContainer}>
                        <SkeletonPart config={config.meetingName} />
                        <SkeletonPart config={config.trackStatus} />
                    </FlexPane>
                    {renderRows(config, style)}
                </FlexPane>
            </FlexPane>
            <FlexPane class="ta-RaceCouponSkeleton" config={config.container}>
                <RaceCouponHeader {...props} />
            </FlexPane>
        </FlexPane>
    );
};

export default RaceCouponsSkeleton;

const RaceCouponHeader = props => {
    const {
        mojitoTools: { config, style },
    } = props;

    return (
        <FlexPane config={config.headerContainer}>
            <FlexPane config={config.iconAndRegionContainer}>
                <SkeletonPart config={config.icon} />
                <SkeletonPart config={config.region} />
            </FlexPane>
            <FlexPane config={config.filterContainer}>
                <SkeletonPart config={config.filter} />
                <SkeletonPart config={config.filter} />
            </FlexPane>
            <FlexPane config={config.meetingsContainer}>
                <SkeletonPart config={config.meetingName} />
                <SkeletonPart config={config.trackStatus} />
            </FlexPane>
            {renderRows(config, style)}
        </FlexPane>
    );
};

RaceCouponsSkeleton.getStyle = function (config, mode, merge) {
    const { height, width } = config.startTime.style;

    return merge(config.startTime, {
        raceButton: {
            style: {
                height: height,
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: width,
            },
        },
    });
};
