const ASPECT_RATIO = 16 / 9;

/**
 * Calculates the maximum width for the video player based on the max fraction of the total screen size and the total screen size.
 *
 * @function getMaxWidth
 *
 * @private
 * @param {number} maxFractionOfScreenSize - Number in the interval [0, 1], representing a fraction of the total screen size.
 * @param {number} screenSize - Screen size.
 * @returns {number} Max width.
 * @memberof Mojito.Presentation.Components.VideoPlayerUtils
 */
const getMaxWidth = (maxFractionOfScreenSize, screenSize) => {
    // Some middle-school maths for y'all!
    // aspectRatio = 16 / 9
    // height = width / aspectRatio
    // size = width * height = width^2 / aspectRatio
    // We want to find a width such that
    // size = maxSize
    // => width^2 / aspectRatio = maxSize
    // => width^2 = aspectRatio * maxSize
    // => width = sqrt(aspectRatio * maxSize)
    const maxVideoPlayerSize = maxFractionOfScreenSize * screenSize;
    return Math.floor(Math.sqrt(ASPECT_RATIO * maxVideoPlayerSize));
};

/**
 * Resolves the maximum width of the stream based on the maximum area that stream can
 * take on the screen (typically limited by betting platform) and static width limitation.
 *
 * @function resolveStreamMaxWidth
 *
 * @param {number} maxSize - Number in the interval [0, 1], representing a fraction of the total screen size.
 * This is maximum area of the screen that the stream can take.
 * @param {number} widthLimit - The width limitation for video stream. Typically comes from component config.
 * @returns {number} Lowest width, either set by config or streaming provider.
 * @memberof Mojito.Presentation.Components.VideoPlayerUtils
 */
const resolveStreamMaxWidth = (maxSize, widthLimit) => {
    if (!maxSize) {
        return widthLimit;
    }
    const screenSize = screen.width * screen.height;
    const maxWidth = getMaxWidth(maxSize, screenSize);
    return Math.min(maxWidth, widthLimit || Number.MAX_SAFE_INTEGER);
};

/**
 * Performs dynamic import of `video.js` lib.
 *
 * @function loadVideoLib
 *
 * @returns {Promise} Promise that resolves on video.js chunk load, carries loaded module as parameter.
 * @memberof Mojito.Presentation.Components.VideoPlayerUtils
 */
const loadVideoLib = () =>
    import(/* webpackChunkName: "videojs" */ 'video.js').then(module => module.default);

/**
 * Utility functions associated with video player component.
 *
 * @class VideoPlayerUtils
 * @memberof Mojito.Presentation.Components
 */
export default {
    getMaxWidth,
    loadVideoLib,
    resolveStreamMaxWidth,
};
