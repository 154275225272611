/**
 * Animated button types.
 *
 * @class AnimatedButtonTypes
 * @name types
 * @memberof Mojito.Presentation.Components.AnimatedButton
 */
export default class AnimatedButtonTypes {}

/**
 * AnimatedButtonTypes STATES enum, used for setting the animated button in different states.
 *
 * @typedef STATES
 * @property {string} ACTIVE - Denotes the active state of the Animated button.
 * @property {string} CONFIRM - Denotes the confirm state of the Animated button.
 * @property {string} ANIMATING - Denotes the animating state of the Animated button.
 * @property {string} SUCCESS - Denotes the success state of the Animated button.
 * @property {string} FAIL - Denotes the failed state of the Animated button.
 * @property {string} DISABLED - Denotes the disabled state of the Animated button.
 * @memberof Mojito.Presentation.Components.AnimatedButton.types
 */
AnimatedButtonTypes.STATES = {
    ACTIVE: 'active',
    CONFIRM: 'confirm',
    ANIMATING: 'animating',
    SUCCESS: 'success',
    FAIL: 'fail',
    DISABLED: 'disabled',
};
