import {registerTileSchema} from '#core/application/schema-registry.js';

import {merge} from '#core/utils/config-utils.js';

import {generateBetBonusLabel} from '#core/application/modules/tiles/bet-bonus-label/index.js';
import {generateSimplePopover} from '#core/application/modules/tiles/popover/index.js';
import {CLOSE_BUTTON_TYPES, generateCloseButton} from '#core/application/modules/tiles/close-button/index.js';
import {generateTertiaryLink} from '#core/application/modules/tiles/link/index.js';

import EARLY_PAYOUT_INDICATOR from './early-payout-indicator.palette-schema.yaml';
import {Intents} from '#core/application/intents/index.js';

registerTileSchema(EARLY_PAYOUT_INDICATOR);

export function generateEarlyPayoutIndicator(TILE) {
    const {FONT} = TILE;

    const EARLY_PAYOUT_INDICATOR_LABEL = generateBetBonusLabel(TILE.EARLY_PAYOUT_INDICATOR_LABEL);

    return {
        popover: {
            button: {
                style: {
                    base: {
                        padding: 0,
                        margin: 0,
                        minHeight: 'initial',
                    },
                },
            },
            textContainer: {
                style: {
                    ...EARLY_PAYOUT_INDICATOR_LABEL.container,
                },
            },
            popup: merge(
                generateSimplePopover(
                    TILE.EARLY_PAYOUT_POPOVER_BG,
                    TILE.EARLY_PAYOUT_POPOVER_BORDER_RADIUS,
                    TILE.EARLY_PAYOUT_POPOVER_SHADOW,
                    TILE.EARLY_PAYOUT_POPOVER_BORDER_COLOR
                ),
                {
                    container: {
                        style: {
                            top: '21px',
                        },
                    },
                }
            ),
            closeButton: merge(generateCloseButton(TILE.EARLY_PAYOUT_POPOVER_CLOSE_BUTTON, CLOSE_BUTTON_TYPES.COMMON), {
                buttonIcon: {
                    style: {
                        width: 8,
                        height: 8,
                        minWidth: 8,
                        minHeight: 8,
                    },
                },
            }),
        },
        text: {
            style: {
                ...EARLY_PAYOUT_INDICATOR_LABEL.text,
            },
        },
        rulesContainer: {
            style: {
                maxWidth: '95%',
            },
        },
        linkButton: generateTertiaryLink(TILE.EARLY_PAYOUT_POPOVER_LINK),
        ruleText: {
            style: {
                fontSize: FONT.SIZE_MEDIUM,
                ...TILE.EARLY_PAYOUT_POPOVER_RULE_TEXT,
            },
        },
        intent: Intents.EARLY_PAYOUT_READ_MORE,
    };
}
