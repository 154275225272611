import { noop } from 'mojito/utils';
import AbstractPerformanceService from './abstract-performance-service';

const NULL_SERVICE = new (class extends AbstractPerformanceService {})();
NULL_SERVICE.report = noop;
NULL_SERVICE.configure = noop;

/**
 * Singleton object that receives performance service config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.Performance
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init factory.
     *
     * @function init
     *
     * @param {Mojito.Services.Performance.types.FactoryConfig} config - Service factory config object.
     *
     * @memberof Mojito.Services.Performance.ServiceFactory
     */
    init({ service, serviceConfig }) {
        this.service = service || NULL_SERVICE;
        this.service.configure(serviceConfig);
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.Performance.AbstractPerformanceService} Performance service instance.
     *
     * @memberof Mojito.Services.Performance.ServiceFactory
     */
    getService() {
        return this.service;
    }

    /**
     * Check whether external service was passed or not.
     *
     * @function isActive
     *
     * @returns {boolean} True if service was provided via init method.
     *
     * @memberof Mojito.Services.Performance.ServiceFactory
     */
    isActive() {
        return this.service !== NULL_SERVICE;
    }
}

export default new ServiceFactory();
