import DataProvider from 'core/services/data-provider/data-provider.js';
import { addSimpleCollection } from 'core/base/repository-collection-factory';
import { handleSubscription } from 'services/performance/utils.js';

const COLLECTION_NAME = 'systeminformation';

/**
 *
 * Class providing access to system information.
 *
 * @class SystemInformationProvider
 *
 * @extends Mojito.Services.DataProvider
 * @memberof Mojito.Services.SystemInformation
 */
class SystemInformationProvider extends DataProvider {
    init() {
        addSimpleCollection(COLLECTION_NAME);
    }

    subscribeToEntity(systemInformationId, setter) {
        const subscription = super.subscribeToEntity(systemInformationId, setter, COLLECTION_NAME);
        return handleSubscription(subscription, systemInformationId, COLLECTION_NAME);
    }
}

export default new SystemInformationProvider();
