import PropTypes from 'prop-types';
import CustomLinks from './custom-links.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * Component that adds custom links with intents and configurable icons.
 *
 * @class CustomLinks
 * @memberof Mojito.Presentation.Components
 */

/**
 * Callback for link click.
 *
 * @param {Event} event - The click event.
 * @param {object} data - Callback data for the clicked link.
 * @param {object} data.intent - Intent to be called when clicking the link.
 * @param {string} data.label - Translation key for the link text.
 * @callback Mojito.Presentation.Components.CustomLinks.onClick
 */

/**
 * CustomLinks prop types.
 *
 * @property {Mojito.Presentation.Components.CustomLinks.onClick} onClick - Callback function called when clicking a link.
 * @property {Function} [setLinkLabelClassName = ()=>{}] - Function to set class name to specific link.
 *
 * @memberof Mojito.Presentation.Components.CustomLinks
 */
const propTypes = {
    onClick: PropTypes.func.isRequired,
    setLinkLabelClassName: PropTypes.func,
};

const defaultProps = {
    setLinkLabelClassName: noop,
};

export default MojitoCore.Presentation.UIView('CustomLinks', CustomLinks, propTypes, defaultProps);
