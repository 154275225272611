import PropTypes from 'prop-types';
import OddsSelectorImpl from './odds-selector.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view for the `OddsSelector` component.
 *
 * @class OddsSelector
 * @memberof Mojito.Presentation.Components
 */

/**
 * `OddsSelector` component prop types.
 *
 * @property {object} oddsFormats - An object of available odds formats.
 * @property {string} selectedOddsFormat - The currently selected odds format value.
 * @property {Function} onOddsFormatChanged - Callback function triggered when the odds format changes. The new odds format is passed as a parameter.
 * @memberof Mojito.Presentation.Components.OddsSelector
 */
const propTypes = {
    oddsFormats: PropTypes.object.isRequired,
    selectedOddsFormat: PropTypes.string.isRequired,
    onOddsFormatChanged: PropTypes.func.isRequired,
};

export default UIView('OddsSelector', OddsSelectorImpl, propTypes);
