import { Children } from 'react';
import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SelectableButtonGroup from 'presentation/components/selectable-button-group/index.jsx';
import SelectableButton from 'presentation/components/selectable-button/index.jsx';
import SwitchPane from 'presentation/components/switch-pane/index.jsx';

const log = MojitoCore.logger.get('TabbedPane');

const TabbedPane = props => {
    const { mojitoTools, children, selectedKey, onSelectionChange } = props;
    const { config } = mojitoTools;
    if (MojitoCore.Utils.isDebugMode()) {
        Children.forEach(children, child => {
            if (child.selectableKey === null) {
                log.warn('TabbedPaneTab lacks key', child);
            }
        });
    }
    return (
        <FlexPane config={config.container}>
            <SelectableButtonGroup
                selectedKey={selectedKey}
                onSelectionChange={onSelectionChange}
                config={config.tabButtons}
            >
                {Children.map(children, child => (
                    <SelectableButton
                        disabled={child.props.disabled}
                        key={child.props.selectableKey}
                        class={child.props.class}
                        selectableKey={child.props.selectableKey}
                    >
                        {child.props.buttonContent}
                    </SelectableButton>
                ))}
            </SelectableButtonGroup>
            <SwitchPane selectedKey={selectedKey} config={config.contentContainer}>
                {children}
            </SwitchPane>
        </FlexPane>
    );
};
export default TabbedPane;
