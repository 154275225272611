import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import { resolveMarketLayout } from 'modules/market/helper/market-helper';
import Market from 'modules/market/index.jsx';
import SelectionButtonTypes from 'modules/selection-button/types/index.js';
import { isEmpty } from 'mojito/utils';

const { FlexPane, Participants, MiniScoreboard } = MojitoPresentation.Components;
const { utils: EventUtils } = MojitoServices.SportsContent.Events;

const GameLineMarketContent = props => {
    const { aggregatedMarket, eventItem, mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;
    const { markets = [], mask = [] } = aggregatedMarket || {};
    const { showMarketHeaders, showScoreboard, showParticipants } = config;
    const { inPlay } = eventItem.details;
    return (
        <FlexPane class="ta-GameLineMarketContent" config={config.container}>
            {showParticipants && (
                <Participants
                    config={config.participants}
                    event={eventItem}
                    drawLabel={resolveDrawLabel(markets, stringResolver)}
                />
            )}
            {showScoreboard && inPlay && (
                <MiniScoreboard config={config.scoreboard} event={eventItem} />
            )}
            <FlexPane class="ta-MarketsContainer" config={config.marketsContainer}>
                {markets.map((market, index) => {
                    const layoutDescriptor = resolveMarketLayout(market, config.layoutDescriptors);
                    let { selectionTypes } = layoutDescriptor;
                    const isUnknownFormat = isEmpty(selectionTypes) && isEmpty(market?.selections);
                    selectionTypes = isUnknownFormat ? ['1', '2'] : selectionTypes;
                    const lineType = mask[index];
                    const headerl10nKey = showMarketHeaders
                        ? getl10nHeaderKey(lineType, eventItem.sportId, stringResolver)
                        : undefined;
                    const nameDisplay =
                        config.selectionNameDisplay[lineType] ||
                        SelectionButtonTypes.NAME_DISPLAY.HIDE;
                    const key = market?.id || lineType;
                    return (
                        <Market
                            key={key}
                            market={market}
                            event={eventItem}
                            config={config.market}
                            hideHeader={false}
                            headerl10nKey={headerl10nKey}
                            nameDisplay={nameDisplay}
                            selectionTypes={selectionTypes}
                        />
                    );
                })}
            </FlexPane>
        </FlexPane>
    );
};

export default GameLineMarketContent;

const resolveDrawLabel = (markets, stringResolver) => {
    const hasThreeWayMarket = markets.some(EventUtils.isThreeWayMarket);
    return hasThreeWayMarket
        ? stringResolver.resolveString('$GAME_LINE_MARKET_CONTENT.DRAW')
        : undefined;
};

const getl10nHeaderKey = (lineType, sportId, stringResolver) => {
    const sportSpecificPath = `$GAME_LINE_MARKET_CONTENT.HEADER.${sportId}.${lineType}`;
    const hasLabelForSport = !!stringResolver.resolveString(sportSpecificPath, true);
    return hasLabelForSport ? sportSpecificPath : `$GAME_LINE_MARKET_CONTENT.HEADER.${lineType}`;
};
