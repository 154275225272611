import {resolveBackground} from '#core/utils/color-utils.js';
import {isMobile} from '#core/utils/context-utils.js';
import {merge} from '#core/utils/config-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {generateInput} from '#core/application/modules/tiles/input/index.js';

export function activeBonusesFactory(PALETTE, BONUS_ITEM) {
    const {FONT} = PALETTE;

    return {
        ActiveBonuses: {
            header: {
                headerContainer: {
                    style: {
                        base: {
                            minHeight: 'initial',
                            padding: 8,
                        },
                    },
                },
                headerTextContainer: {
                    style: {
                        fontSize: FONT.SIZE_MEDIUM,
                        textTransform: 'uppercase',
                        fontWeight: FONT.WEIGHT_NORMAL,
                        ...PALETTE.BONUSES_SECTION_TITLE_TEXT,
                    },
                },
                leftItemsContainer: {
                    style: {
                        display: 'none',
                    },
                },
                contentContainer: {
                    enableTransitions: false,
                    style: {
                        base: {
                            padding: 0,
                        },
                    },
                },
            },
            item: {
                ...BONUS_ITEM.container,
                margin: '0 0 8px 0',
            },
            itemRow: {
                margin: '0px 0px 4px 0px',
            },
            itemName: {
                textStyle: {
                    ...BONUS_ITEM.title,
                },
            },
            itemAmount: {
                textStyle: {
                    ...BONUS_ITEM.amount,
                },
            },
            icon: {
                style: {
                    ...BONUS_ITEM.icon,
                    width: 12,
                    height: 12,
                },
            },
            itemBalanceLabel: {
                textStyle: BONUS_ITEM.balance,
            },
            itemBalanceValue: {
                textStyle: BONUS_ITEM.balance,
            },
            itemExpireLabel: {
                textStyle: BONUS_ITEM.expiryDate,
            },
            itemExpireDate: {
                textStyle: BONUS_ITEM.expiryDate,
            },
            itemInformation: {
                textStyle: {
                    ...BONUS_ITEM.description,
                },
            },
            progressBar: BONUS_ITEM.progressBar,
            bonusItemsNotAvailable: {
                container: {
                    ...BONUS_ITEM.container,
                    padding: '16px 8px',
                    _mobile_padding: '16px 8px', // to override mojito one
                },
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
            bonusItems: {
                compactLayout: true,
                _desktop_compactLayout: true,
                container: {
                    style: {
                        padding: 0,
                        margin: '0 0 8px',
                    },
                },
                compactContainer: {
                    style: {
                        ...BONUS_ITEM.container,
                    },
                },
                compactContainerInner: {
                    style: {
                        padding: 0,
                    },
                },
                title: {
                    style: {
                        ...BONUS_ITEM.title,
                    },
                },
                status: {
                    style: BONUS_ITEM.status,
                },
                expiry: {
                    style: BONUS_ITEM.expiryDate,
                },
                description: {
                    style: BONUS_ITEM.description,
                },
                amountContainer: {
                    ...BONUS_ITEM.betFundsInfo.container,
                },
                onlyAmount: BONUS_ITEM.betFundsInfo.value,
                amountAndRemainingAmount: BONUS_ITEM.betFundsInfo.value,
                remainingAmountContainer: {
                    ...BONUS_ITEM.betFundsInfo.container,
                },
                remainingAmount: BONUS_ITEM.betFundsInfo.value,
                freeBetsIcon: {
                    style: BONUS_ITEM.icon,
                },
                oddsBoostIcon: {
                    style: BONUS_ITEM.icon,
                },
            },
        },
        ...promotionCodeFactory(PALETTE),
    };
}

function promotionCodeFactory(PALETTE) {
    const {FONT} = PALETTE;

    return {
        FreeBetCode: {
            container: {
                style: {
                    ...resolveBackground(PALETTE.PROMOTION_CODE_CONTAINER_BG, 'mobile'),
                    flexGrow: 0,
                    _mobile_padding: '0 8px',
                    ...(isMobile()
                        ? {
                              borderStyle: 'solid',
                              borderWidth: '0 0 1px 0',
                              borderColor: PALETTE.PROMOTION_CODE_SEPARATOR_COLOR,
                          }
                        : undefined),
                },
            },
            contentContainer: {
                style: {
                    flexBasis: 32,
                },
            },
            label: {
                style: {
                    fontSize: FONT.SIZE_SMALL,
                    textTransform: 'capitalize',
                    ...PALETTE.PROMOTION_CODE_INPUT.INPUT_LABEL_TEXT,
                },
            },
            input: merge(generateInput(PALETTE.PROMOTION_CODE_INPUT), {
                style: {
                    base: {
                        padding: 8,
                        flexBasis: 240,
                        _desktop_height: 32,
                        minHeight: 'initial',
                        _mobile_fontSize: FONT.SIZE_MEDIUM,
                        _desktop_flexGrow: 1,
                        flexGrow: 1,
                    },
                },
            }),
            button: {
                style: {
                    base: {
                        ...resolveBackground(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_BG),
                        borderRadius: PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_BORDER_RADIUS,
                        _desktop_flexBasis: 32,
                        _desktop_height: 32,
                        _desktop_padding: 8,
                        _desktop_minWidth: 32,
                    },
                },
            },
            submitIcon: merge(icon(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_ICON_COLOR), {
                style: {
                    minWidth: 16,
                    minHeight: 16,
                    height: 16,
                },
            }),
            spinner: {
                style: {
                    container: {
                        height: '16px',
                    },
                },
                image: icon(PALETTE.PROMOTION_CODE_SUBMIT_BUTTON.PRIMARY_BUTTON_ICON_COLOR),
            },
            successContainer: {
                style: {
                    ...resolveBackground(PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BG),
                    borderRadius: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BORDER_RADIUS,
                    borderColor: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_BORDER_COLOR,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    ...PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_TEXT,
                },
            },
            successMessageIcon: {
                src: 'leg-status-won-icon',
                style: {
                    fill: PALETTE.PROMOTION_CODE_SUCCESS_NOTIFICATION.SUCCESS_ICON_COLOR,
                    minWidth: '16px',
                    minHeight: '16px',
                },
            },
            error: {
                container: {
                    _desktop_flexBasis: 240,
                    _desktop_padding: '0 40px 0 0',
                    _mobile_padding: '4px 0',
                },
                style: {
                    fontSize: FONT.SIZE_SMALL,
                    ...PALETTE.PROMOTION_CODE_ERROR_TEXT,
                },
            },
        },
    };
}
